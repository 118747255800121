import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";
import { date_format } from "../../../../../templates/Patterns";
import ApiHooks from "../../../../../../hooks/ApiHooks";
import { UserJuridicForm } from "./UserJuridicForm";
import { Form, Tabs } from "antd";
import { useState } from "react";
import Trans, { getTitle } from "../../../../../Trans";
import PasswordForm from "./PasswordForm";

const UpdateUserJuridik = ({ destination }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [password] = Form.useForm();
  const hookApi = ApiHooks();
  const [formData, setFormData] = useState();

  const handleSubmitProfile = async (values) => {
    values.birth_date = moment(values.birth_date).format(date_format);
    values.type = "3";

    if (formData) {
      values.id = id;
      values.client_code = `${formData.client_code}`;
    }
    const user = { user: values };
    await hookApi(
      "user/setRegistrator",
      {
        json: user,
      },
      true
    );
  };

  const handleSubmitPassword = async (values) => {
    if (values.new_password !== values.new_password_again) {
      return;
    }
    values.id = String(formData.id);
    await hookApi(
      "/user/changeUserPassword",
      {
        data: values,
      },
      true
    );
  };

  const getUserDetailedJuridical = async () => {
    const user_data = await hookApi("user/getUserDetailedJuridical", {
      user_id: id,
    });

    if (user_data) {
      if (user_data.data.data.birth_date) {
        user_data.data.data.birth_date = moment(user_data.data.data.birth_date, date_format);
      } else user_data.data.data.birth_date = moment();

      const filteredData = {
        passport_s: user_data.data.data.passport_seria,
        passport_n: user_data.data.data.passport_number,
        ...user_data.data.data,
      };
      form.setFieldsValue(filteredData);
      setFormData(filteredData);
    }
  };

  useEffect(() => {
    id && getUserDetailedJuridical();
  }, [id]);

  let tabItems = [
    {
      key: "1",
      label: getTitle("Changing user data"),
      children: (
        <>
          <h1>
            <Trans alias={"Changing user data"} />
          </h1>
          <UserJuridicForm form={form} id={id} onFinish={handleSubmitProfile} name="user-juridic" destination={destination} />
        </>
      ),
      active: true,
    },
    {
      key: "2",
      label: getTitle("Changing the user's password"),
      children: (
        <>
          <h1>
            <Trans alias={"Changing the user's password"} />
          </h1>
          <PasswordForm form={password} name="password" onFinish={handleSubmitPassword} />
        </>
      ),
      active: destination === "add" ? false : true,
    },
  ];

  tabItems = tabItems.filter((el) => el.active);

  return (
    <Tabs
      tabPosition="left"
      defaultActiveKey="1"
      size="large"
      tabBarGutter={25}
      items={tabItems}
      style={{
        background: "#fefefe",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "500px",
      }}
    />
  );
};

export default UpdateUserJuridik;
