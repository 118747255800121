import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { Form } from "antd";
import Trans, { getTitle } from "../../../../Trans";
import { date_format } from "../../../../templates/Patterns";
import ModalComponent from "../../../../templates/ModalComponent";
import FormSuccess from "./components/FormSuccess";
import FormAddBan from './components/FormAddBan';
import moment from "moment";

const NotaryBanForm = () => {

    const [form] = Form.useForm();
    const { id } = useParams();
    const hookApi = ApiHooks();
    const loading = useSelector(loadingState);
    const [modalCancelBan, setModalCancelBan] = useState(false);
    const [sendData, setSendData] = useState([]);
    const [banInfo, setBanInfo] = useState([]);
    const new_car = Form.useWatch('new_car', form);
    const [successForm, setSuccessForm] = useState(false);
    const [responseBan, setResponseBan] = useState();

    useEffect(() => {
        if (id) {
            getRecord(id);
        };
    }, []);

    const getRecord = async (code) => {
        const obj = {};
        const result = await hookApi("record/notaryPrevData", { code: code });
        if (result.data) {
            obj.creditor_name = result.data.creditor_name;
            obj.creditor_inn = result.data.creditor_inn;
            obj.code = result.data.dec_code;
            obj.record_date = result.data.dec_registered;
            obj.debtor_name = result.data.debtor_name;
            obj.debtor_inn_pin = result.data.debtor_inn ? result.data.debtor_inn : result.data.debtor_pin;
            obj.debtor_pass_s = result.data.debtor_pass_s;
            obj.debtor_pass_n = result.data.debtor_pass_n;
            obj.debtor_birth_date = result.data.debtor_birth_date;
            obj.debtor_address = result.data.debtor_address;
            obj.debtor_district = result.data.debtor_district;
            obj.doc_type = result.data.doc_type_name;
            obj.doc_num = result.data.doc_num;
            obj.doc_date = result.data.doc_date ? moment(result.data.doc_date, date_format) : null;
            obj.ban_edate = result.data.ban_edate ? moment(result.data.ban_edate, date_format) : null;
            obj.issue_org = result.data.issue_org;
            obj.issue_date = result.data.issue_date ? moment(result.data.issue_date, date_format) : null;

            obj.description = result.data.description;

            obj.cadastre_num = result.data.cadastre_num;
            obj.district = result.data.district;
            obj.street = result.data.street;
            obj.home = result.data.home;
            obj.flat = result.data.flat;
            obj.block = result.data.block;
            obj.obj_name = result.data.obj_name;

            obj.body_num = result.data.body_num;
            obj.new_car = result.data.new_car;
            obj.state_num = result.data.state_num;
            obj.engine_num = result.data.engine_num;
            obj.chassis_num = result.data.chassis_num;
            obj.mark = result.data.mark;
            obj.year_create = result.data.year_create;
            obj.color = result.data.color;
            obj.tech_serial = result.data.tech_serial;
            obj.tech_num = result.data.tech_num;
            obj.tech_date = result.data.tech_date ? moment(result.data.tech_date, date_format) : null;
            obj.tech_issue = result.data.tech_issue;
            obj.vehicle_id = result.data.vehicle_id;
            // setResend(result.data.resend);
            setBanInfo(result.data);
            form.setFieldsValue(obj);
        }
    };

    const notaryModal = (values) => {
        setModalCancelBan(true);
        setSendData(values)
    }

    const setBan = async () => {
        let obj = {};
        obj.code = sendData.code;
        obj.doc_num = sendData.doc_num;
        obj.doc_date = moment(sendData.doc_date).format(date_format);
        obj.ban_edate = moment(sendData.ban_edate).format(date_format);
        obj.issue_org = sendData.issue_org;
        obj.issue_date = moment(sendData.issue_date).format(date_format);
        obj.description = sendData.description ? sendData.description : null;
        obj.debtor_district = sendData.debtor_district ? sendData.debtor_district : null;

        obj.cadastre_num = sendData.cadastre_num ? sendData.cadastre_num : null;
        obj.district = sendData.district ? sendData.district : null;
        obj.street = sendData.street ? sendData.street : null;
        obj.home = sendData.home ? sendData.home : null;
        obj.flat = sendData.flat ? sendData.flat : null;
        obj.block = sendData.block ? sendData.block : null;
        obj.obj_name = sendData.obj_name ? sendData.obj_name : null;

        obj.new_car = new_car ? new_car : null;
        obj.state_num = sendData.state_num ? sendData.state_num : null;
        obj.engine_num = sendData.engine_num ? sendData.engine_num : null;
        obj.body_num = sendData.body_num ? sendData.body_num : null;
        obj.chassis_num = sendData.chassis_num ? sendData.chassis_num : null;
        obj.mark = sendData.mark ? sendData.mark : null;
        obj.year_create = sendData.year_create ? sendData.year_create : null;
        obj.color = sendData.color ? sendData.color : null;
        obj.tech_serial = sendData.tech_serial ? sendData.tech_serial : null;
        obj.tech_num = sendData.tech_num ? sendData.tech_num : null;
        obj.tech_date = sendData.tech_date ? moment(sendData.tech_date).format(date_format) : null;
        obj.tech_issue = sendData.tech_issue ? sendData.tech_issue : null;
        obj.vehicle_id = sendData.vehicle_id ? sendData.vehicle_id : null;

        const result = await hookApi("record/notaryBan", { data: obj });
        if (result) {
            setModalCancelBan(false);
            if (result.data.err_code === '0') {
                setResponseBan(result.data);
                setSuccessForm(true);
            }
        }
    };
    return (
        <>
            {successForm && <FormSuccess responseBan={responseBan} />}
            {!successForm && <FormAddBan banInfo={banInfo} notaryModal={notaryModal} form={form} new_car={new_car} />}
            <ModalComponent
                title={<Trans alias={"Setting a ban in Notary"} />}
                open={modalCancelBan}
                data={getTitle("Do you really want to set a recording ban in the Notary") + '?'}
                onOk={() => setBan()}
                onCancel={() => setModalCancelBan(false)}
                loading={loading}
                cancelTitle={<Trans alias={"No"} />}
                okTitle={<Trans alias={"Yes"} />}
            />
        </>
    )
};

export default NotaryBanForm;
