import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { TransFunc } from '../Trans';
import { postApi } from '../../web/api';

const GetSelect = (props) => {
  const [refers, setRefers] = useState([]);

  useEffect(() => {
    if (props.refer) getRefers(props.refer, props.referGroup);
  }, [props.refer, props.referGroup]);

  const getRefers = async (refer, referGroup) => {
    let data = await postApi('refers/getRefers', { refer, referGroup });
    let arr = [];
    if (props.defaultOption) arr.push(props.defaultOption);
    if (data) {
      data = JSON.parse(data);
      if (
        refer === '014' ||
        refer === '633' ||
        refer === '999' ||
        refer === '013'
      ) {
        data.map((refers) =>
          arr.push({
            value: refers.code,
            label: refers.code + '-' + refers.name,
            key: refers.code,
          }),
        );
      } else if (refer === '615') {
        data = data?.filter(
          (value) =>
            value.code === '1' ||
            value.code === '2' ||
            value.code === '31' ||
            value.code === '39',
        );
        data.map((refers) =>
          arr.push({
            value: refers.code,
            label: refers.name,
            key: refers.code,
          }),
        );
      } else {
        data.map((refers) =>
          arr.push({
            value: refers.code,
            label: refers.name,
            key: refers.code,
          }),
        );
      }
    }
    setRefers(arr);
  };

  const obj = TransFunc(props.text);
  const required_msg = TransFunc('Props is a must');
  if (obj) {
    return (
      <Form.Item
        name={props.name}
        label={props.label ? props.label : obj.NAME}
        rules={[{ required: props.required, message: required_msg.NAME }]}
        key={obj.ID}
      >
        {props.func ? (
          <Select
            placeholder={obj.NAME}
            options={refers}
            value={props.value}
            defaultValue={props.defaultValue}
            allowClear
            onChange={props.func}
            disabled={props.disabled}
            showSearch={props.search}
          />
        ) : (
          <Select
            placeholder={obj.NAME}
            options={refers}
            disabled={props.disabled}
            showSearch={props.search}
            defaultValue={props.defaultValue}
          />
        )}
      </Form.Item>
    );
  }
};
export default GetSelect;
