import React, { useState } from "react";
import { Row, Col, Form, Select, Button, Upload } from "antd";
import GetInput from "../../../../../templates/FormInput";
import Confirm from "../../../../../templates/Confirm";
import { getTitle } from "../../../../../Trans";

const FileFormItem = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    name: "fileform",
    disabled: props.loading,
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
    initialValues: {
      doc_type: 0,
    },
    // layout: "vertical",
  };
  const [docType, setDocType] = useState(0);

  const dummyRequest = (file) => {};
  const changeDocType = (param) => {
    props.form.setFieldValue("doc_url", null);
    props.form.setFieldValue("upload_file", null);
    setDocType(param);
  };
  return (
    <Form {...formConfig}>
      <Row justify="end">
        <Col>
          <Form.Item>
            <Confirm
              form={props.form}
              title={"Do you really want to save?"}
              btnText={"Save"}
              btnDisabled={props.loading}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={getTitle("File type")} name={"doc_type"}>
        <Select
          onChange={changeDocType}
          options={[
            {
              value: 0,
              label: getTitle("Downloadable file"),
            },
            {
              value: 1,
              label: getTitle("Link to external file"),
            },
          ]}
        />
      </Form.Item>
      <GetInput name={"doc_name_ru"} required={true} text={"Name in Russian"} />
      <GetInput name={"doc_name_uz"} required={true} text={"Name in Uzbek"} />
      <GetInput
        name={"doc_name_oz"}
        required={true}
        text={"Name in Uzbek (Latin)"}
      />
      <GetInput name={"doc_name_en"} required={true} text={"Name in English"} />

      <GetInput
        name={"doc_url"}
        required={docType}
        text={"Link to external file"}
        disabled={!docType}
      />
      <Form.Item
        label={getTitle("Downloadable file")}
        name="upload_file"
        valuePropName="filenames"
        rules={[{ required: !docType, message: getTitle("Props is a must") }]}
      >
        <Upload customRequest={dummyRequest} showUploadList={false}>
          <Button disabled={docType}>{getTitle("Click to upload file")}</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

export default FileFormItem;
