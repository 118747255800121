export const getObject = async(obj)=>{
    let js={};
    js.data={}
    js.data.client = {};
    js.data.user = {};
    js.data.client.code= obj.code?obj.code:null;
    js.data.client.resident= '1' //obj.resident; //реквизита нет в интерфейсе;
    js.data.client.subject_type=obj.subject_type?obj.subject_type:null;
    js.data.client.org_form=obj.org_form?obj.org_form:null;
    js.data.client.oked=obj.oked?obj.oked:null;
    js.data.client.okpo=obj.okpo?obj.okpo:null;
    js.data.client.inn=obj.inn?obj.inn:null;
    js.data.client.name=obj.name?obj.name:null;
    js.data.client.first_name=obj.first_name?obj.first_name:null;
    js.data.client.last_name=obj.last_name?obj.last_name:null;
    js.data.client.patronymic=obj.patronymic?obj.patronymic:null;
    js.data.client.phone=obj.phone?obj.phone:null;
    js.data.client.email=obj.email?obj.email:null;
    js.data.client.address=obj.address?obj.address:null;
    js.data.client.branch=obj.branch?obj.branch:null;
    js.data.client.account=obj.account?obj.account:null;
    js.data.client.ownership=obj.ownership?obj.ownership:null;
    js.data.client.passport_s=obj.passport_s?obj.passport_s:null;
    js.data.client.passport_n=obj.passport_n?obj.passport_n:null;
    js.data.client.passport_date_issue=obj.passport_date_issue?obj.passport_date_issue:null;
    js.data.client.country=obj.country?obj.country:null;
    js.data.client.small_business=obj.small_business?obj.small_business:null;
    js.data.client.pin=obj.pin?obj.pin:null;
    js.data.client.birth_date=obj.birth_date?obj.birth_date:null;
    //user info
    js.data.user.id=obj.id?obj.id:null;
    js.data.user.username=obj.admin_username?obj.admin_username:null;
    js.data.user.first_name=obj.admin_first_name?obj.admin_first_name:null;
    js.data.user.last_name=obj.admin_last_name?obj.admin_last_name:null;
    js.data.user.patronymic=obj.admin_patronymic?obj.admin_patronymic:null;
    js.data.user.phone=obj.admin_phone?obj.admin_phone:null;
    js.data.user.email=obj.admin_email?obj.admin_email:null;
    js.data.user.pin=obj.admin_pin?obj.admin_pin:null;
    js.data.user.birth_date=obj.admin_birth_date?obj.admin_birth_date:null;
    js.data.user.address=obj.admin_address?obj.admin_address:null;
    js.data.user.passport_s=obj.admin_passport_s?obj.admin_passport_s:null;
    js.data.user.passport_n=obj.admin_passport_n?obj.admin_passport_n:null;
    return js;
};

