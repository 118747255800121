import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Card, Pagination, Form, DatePicker, Button, Space, Divider, Typography } from 'antd';
import { postApi } from '../../../web/api';
import { conf } from '../../../config';

const { Meta } = Card;
const { Text } = Typography;
function MenuNewsComponent({ menuId }) {
  const [content, setContent] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  useEffect(() => {
    getContent();
  }, [location]);

  const getContent = async () => {
    let res = await postApi('main/content_dynamic_list', {
      menu_id: menuId,
      bdate: '',
      edate: '',
      lang: 'uz',
      page: 1,
    });
    setContent(res ? res : []);
    setTotal(res.length ? res.length : 0);
  };

  const handleChange = (value) => {
    setMinValue((value - 1) * 10);
    setMaxValue(value * 10);
  };

  return (
    <>
      {/* <Row justify="end" className="left-content-container">
        <Col>
          <Space size="middle">
            <Text strong>Yangilangan sana:</Text>
            <Text>24 Iyun 2022, 11:24</Text>
          </Space>
        </Col>
      </Row>
      <Form>
        <Row
          style={{
            margin: "15px 0 15px 0",
            backgroundColor: "white",
            fontSize: "20px",
            boxShadow: "0 5px 30px rgb(0 0 0 / 10%)",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Col xxl={22} xl={22} lg={20} md={20} sm={24} xs={24}>
            <Space size="middle">
              <Text strong>Дата:</Text>
              <Text>с</Text>
              <DatePicker />
              <Text>по</Text>
              <DatePicker />
            </Space>
          </Col>
          <Col xxl={2} xl={2} lg={4} md={4} sm={6} xs={8}>
            <Button className="login-form-button">Поиск</Button>
          </Col>
        </Row>
      </Form>
      <Divider /> */}
      <Row>
        {content.slice(minValue, maxValue).map((obj) => (
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} key={obj.ID} style={{ padding: '0 5px 10px 5px' }}>
            <Link to={`${obj.ID}`} title={obj.TITLE}>
              <Card
                hoverable
                cover={
                  obj.HAVEIMG && (
                    <img
                      style={{ maxWidth: '100%' }}
                      alt="example"
                      src={`${conf.server}main/content_dynamic/thumb_file/${obj.ID}`}
                    />
                  )
                }
              >
                <Meta title={<div className="font-content-dynamic">{obj.TITLE}</div>} description={obj.CREATED_DATE} />
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <Row style={{ margin: '20px 0' }}>
        <Pagination defaultCurrent={1} defaultPageSize={10} onChange={handleChange} total={total} />
      </Row>
    </>
  );
}

export default MenuNewsComponent;
