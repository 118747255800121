export const usersPriveleges = {
  record_create: "record.create",
  record_delete: "record.delete",
  record_update: "record.update",
  record_order_certified: "record.order.certified",
  record_order_super: "record.order.super",
  record_editable_client: "record.editable.client",
  notary_search: "notary.search",
  notary_ban: "notary.ban",
  notary_ban_cancel: "notary.ban.cancel",
  notary_super: "notary.super",
  police_search: "police.search",
  manage_admin_super: "manage.user.list.super",
  manage_admin: "manage.user.list.administrator",
  record_list_admin: "record.list.admin",
  record_list_super: "record.list.super",
  profile: "profile",
};

export const clientRoles = {
  super: "super",
  bank: "bank",
  tax: "tax",
  customs: "customs",
  investigation_body: "investigation_body",
  body_of_inquiry: "body_of_inquiry",
  physical: "physical",
  juridical: "juridical",
  mko: "mko",
  businessman: "businessman",
  mygov: "mygov",
  factoring: "factoring"
};
