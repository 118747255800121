import React from 'react';
import { Table } from 'antd';
const MonitoringTable = (props) => {
  const { loading, pagination, dataSource, columns, bordered, rowKey, size, scroll, ...rest } = props;
  return (
    <Table
      loading={loading}
      pagination={pagination}
      dataSource={dataSource}
      columns={columns}
      bordered={bordered}
      rowKey={rowKey}
      size={size || 'default'}
      className="tab-monitoring"
      scroll={{ x: 'max-content' }}
      {...rest}
    />
  );
};

export default MonitoringTable;
