import { Tabs, Button } from "antd";
import { getTitle } from "../../../../Trans";
import TextForm from "./forms/TextForm";
import FileForm from "./forms/FileForm";

const DynamicForm = (props) => {
  const tabItems = [
    {
      key: "1",
      label: getTitle("Content texts"),
      children: (
        <TextForm
          form={props.textform}
          onFinish={props.handleTextForm}
          data={props.textFormData}
          loading={props.loading}
        />
      ),
      disabled: props.loading,
    },
    {
      key: "2",
      label: getTitle("Attached content files"),
      children: (
        <FileForm
          form={props.fileform}
          onFinish={props.handleFileForm}
          loading={props.loading}
          data={props.fileFormData}
          deleteFile={props.handleFileDelete}
        />
      ),
      disabled: props.isEmpty || props.loading,
    },
  ];
  return (
    <Tabs
      tabBarExtraContent={
        <Button disabled={props.loading} type="link" onClick={props.closeForms}>
          {getTitle("Close")}
        </Button>
      }
      size="large"
      style={{
        background: "#fefefe",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "500px",
      }}
      items={tabItems}
    />
  );
};

export default DynamicForm;
