import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import parseHtml from "html-react-parser";
import DOMPurify from "dompurify";
import { Divider } from "antd";

function TestTinyMci() {
  const [value, setValue] = useState("");
  return (
    <>
      <Divider />
      <Editor
        menubar={true}
        statusbar={true}
        inline={true}
        //   readonly:1,
        height={450}
        onEditorChange={(value) => setValue(value)}
        initialValue="<p>To insert a emoji, either:</p><ul><li>Type a colon followed by a keyword, e.g., <code>:smile</code>, then press <em>Enter</em> or&nbsp;<em>Return</em> to add the highlighted emoji, or click the desired emoji from those displayed, or</li><li>Select the emoticons toolbar button to open the picker, and use the tabs or search bar to browse, then click the desired emoji</li></ul><p>Try it here!&nbsp;</p>"
      />
      <Divider />
      {parseHtml(DOMPurify.sanitize(value))}
      <Divider />
      {value}
      <Divider />
    </>
  );
}
export default TestTinyMci;
