import { Form, Row, Col, DatePicker, Button, Divider } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import { date_format, pattern } from "../../../../../templates/Patterns";
import GetInput from "../../../../../templates/FormInput";
import GetOthers from "../../../../../templates/FormOther";
import GetSelect from "../../../../../templates/FormSelect";
import { REFERS } from "../../../../../constants/refers";

const FormInfoCancelBan = (props) => {

    const formConfig = {
        onFinish: props.notaryModal,
        name: "cancelban",
        form: props.form,
        layout: "vertical",
        className: "form-back"
    };

    return (
        <>
            <Form {...formConfig}>
                <Row gutter={10}>
                    <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                        <GetInput name={"code"} text={"Code"} disabled={true} />
                    </Col>
                    <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                        <GetInput name={"ban_num"} text={"Ban number"} disabled={true} />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                        <GetInput name={"ban_date"} text={"Ban date"} disabled={true} />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                        <GetInput name={"ban_edate"} text={"Issue Ban"} disabled={true} />
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={12} sm={24} xs={24}>
                        <GetInput name={"creditor_name"} text={"name"} disabled={true} />
                    </Col>
                    {/* <Col xxl={7} xl={7} lg={7} md={12} sm={24} xs={24}>
                        <GetInput name={"debtor_name"} text={"S.F.P"} disabled={true} />
                    </Col> */}
                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <GetSelect name={'doc_type'} required={true} refer={REFERS.conREF_NOTARY_DOC_TYPES} text={'type'} disabled={props.banInfo.resend} />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetInput name={"doc_num"} text={"Number of the basis document for lifting the ban"} required={true} disabled={props.banInfo.resend} />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetOthers name={'doc_date'} pattern={null} required={true} text={'Date of the basis document for lifting the ban'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.banInfo.resend} />} />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Divider orientation="left"><h2>{getTitle('Information about the pledgor')}</h2></Divider>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <GetInput name={"debtor_name"} text={"Name pledgor"} disabled={true} />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                        <GetInput name={"debtor_inn_pin"} text={"Inn/Pin debtor"} disabled={true} />
                    </Col>
                    {props.banInfo.debtor_pin &&
                        <>
                            <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                                <GetInput name={'debtor_pass_s'} disabled={true} text={'passport_s'} />
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                                <GetInput name={'debtor_pass_n'} disabled={true} text={'passport_n'} />
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                                <GetInput name={'debtor_birth_date'} disabled={true} text={'birth_date'} />
                            </Col>
                        </>
                    }
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetInput name={'debtor_district'} text={'loc_region'} disabled={true} />
                    </Col>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <GetInput name={'debtor_address'} text={'address'} disabled={true} />
                    </Col>
                </Row>
                <Row justify="center">
                    {props.banInfo.resend &&
                        <Col>
                            <Button type="danger" htmlType="submit">
                                <Trans alias={"Resend"} />
                            </Button>
                        </Col>
                    }
                    {!props.banInfo.resend &&
                        <Col>
                            <Button type="primary" htmlType="submit">
                                <Trans alias={"Send"} />
                            </Button>
                            <Button style={{ margin: "0 8px" }} onClick={() => { props.form.resetFields() }}>
                                <Trans alias={"Reset"} />
                            </Button>
                        </Col>
                    }
                </Row>
            </Form>
        </>
    )
};

export default FormInfoCancelBan;
