import React, { useState } from "react";
import { useSelector } from "react-redux";

import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { getUserId } from "../../../../../redux/slices/AuthSlice";
import { Form, Tabs } from "antd";
import PasswordForm from "../../users/form/components/PasswordForm";
import { ClientForm } from "../../users/form/components/ClientForm";
import { useParams } from "react-router-dom";
import Trans, { getTitle } from "../../../../Trans";
import { useEffect } from "react";
import { date_format } from "../../../../templates/Patterns";
import moment from "moment";

export const ClientUpdateForm = () => {
  const { id } = useParams();
  const [client] = Form.useForm();
  const [password] = Form.useForm();
  const user_id = useSelector(getUserId);
  const loading = useSelector(loadingState);
  const [clientData, setClientData] = useState();
  const [userData, setUserData] = useState();

  const hookApi = ApiHooks();

  const handleSubmitClient = async (values) => {
    values.code = id;
    if (clientData?.subject_type !== 1) {
      values.birth_date = moment(values.birth_date).format(date_format);
    }
    values.resident = `${clientData.resident}`;
    values.subject_type = `${clientData.subject_type}`;
    await hookApi(
      "/user/updateClientProfile",
      {
        initiator: user_id,
        json: { client: values },
      },
      true
    );
  };
  const handleSubmitPassword = async (values) => {
    if (values.new_password !== values.new_password_again) {
      return;
    }
    values.client_code = clientData.client_code;
    values.id = `${userData.id}`
    await hookApi(
      "/user/changeUserPassword",
      {
        data: values,
      },
      true
    );
  };

  const getClientDetailed = async () => {
    const client_data = await hookApi("user/getClientDetailed", {
      client_id: id,
    });

    if (client_data) {
      if (client_data.data.client.birth_date)
        client_data.data.client.birth_date = moment(
          client_data.data.client.birth_date,
          date_format
        );
      else client_data.data.client.birth_date = moment();
      client.setFieldsValue(client_data.data.client);
    }
    setClientData(client_data.data.client);
    setUserData(client_data.data.user);
  };

  useEffect(() => {
    user_id && getClientDetailed();
  }, [user_id]);

  const tabItems = [
    {
      key: "1",
      label: getTitle("Changing user data"),
      children: (
        <>
          <h1>
            <Trans alias={"Changing user data"} />
          </h1>
          <ClientForm
            form={client}
            name="client"
            onFinish={handleSubmitClient}
            clientData={clientData}
            disabled={loading}
          />
        </>
      ),
    },
    {
      key: "2",
      label: getTitle("Changing the user's password"),
      children: (
        <>
          <h1>
            <Trans alias={"Changing the user's password"} />
          </h1>
          <PasswordForm
            form={password}
            name="password"
            onFinish={handleSubmitPassword}
            disabled={loading}
          />
        </>
      ),
      hidden: clientData?.subject_type === 1,
    },
  ].filter((item) => !item.hidden);

  return (
    <Tabs
      tabPosition="left"
      defaultActiveKey="1"
      size="large"
      tabBarGutter={25}
      items={tabItems}
      style={{
        background: "#fefefe",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "500px",
      }}
    />
  );
};
