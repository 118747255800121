import React from "react";
import { useSelector } from "react-redux";
import { selectMenu } from "../../../redux/slices/SiteSlice";
import { userEntitlement } from "../../../redux/slices/AuthSlice";
import { Col, Menu, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { getLogo } from "../../../config";
import _ from "underscore";
import { Link } from "react-router-dom";

export const NewFulllHeaderNav = ({ visible, onClose }) => {
  const menuList = useSelector(selectMenu);
  const entitlement = useSelector(userEntitlement);

  const filtered = menuList.map((menu) => {
    if (_.isEmpty(menu.privileges) || !_.isEmpty(_.intersection(entitlement, menu.privileges))) {
      const children = menu.children.map((child) => {
        if (
          _.isEmpty(child.privileges) ||
          !_.isEmpty(_.intersection(entitlement, child.privileges))
        ) {
          return {
            label: (
              <Link className="header-full-nav-link2" onClick={onClose} to={child.url}>
                {child.title}
              </Link>
            ),
            key: child.url + child.key,
            children: (child.children.length && child.children) || null,
          };
        }
      });

      return {
        label: (
          <Link className="header-full-nav-link1" onClick={onClose} to={menu.url}>
            {menu.title}
          </Link>
        ),
        key: menu.url + menu.key,
        children,
        type: "group",
      };
    }
  });

  const finalFilteredMenues = _.chunk(filtered, 4);

  return (
    <div className={visible ? "newHeader-wrapper active" : "newHeader-wrapper"}>
      <Row justify="center" align="middle" className="header-full-nav-logo">
        <Col>
          <Link to="/" onClick={onClose}>
            <img src={getLogo()} alt="logo" />
          </Link>
        </Col>
      </Row>
      <Row justify="center" gutter={[10, 10]} style={{ width: "100%" }}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Link to="#" onClick={onClose}>
            <CloseOutlined className="header-full-nav-close" />
          </Link>
        </Col>
        {finalFilteredMenues.map((menu, index) => (
          <Col xxl={7} xl={7} lg={7} md={24} sm={24} xs={24} key={index}>
            <Menu className="myMenu" items={menu} style={{ background: "transparent" }} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
