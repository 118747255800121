import { useEffect, useState } from "react";

export const useOverflowHidden = () => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (isHidden) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [isHidden]);

  return { isHidden, setIsHidden };
};
