import React from "react";
import { Popconfirm, Button } from "antd";
import Trans, { getTitle } from "../Trans";

const Confirm = (props) => {
  return (
    <Popconfirm
      placement="top"
      title={getTitle(props.title)}
      onConfirm={() => {
        props.form ? props.form.submit() : props.func();
      }}
      okText={getTitle("Yes")}
      cancelText={getTitle("No")}
    >
      <Button
        type={props.btnType}
        htmlType="submit"
        size={props.size}
        loading={props.btnDisabled}
        icon={props.btnIcon}
        title={getTitle(props.btnTitle)}
        shape={props.shape}
      >
        <Trans alias={props.btnText} />
      </Button>
    </Popconfirm>
  );
};

export default Confirm;
