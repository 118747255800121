export const JsonViewer = (props) => {
  const data = props.json ? JSON.parse(props.json) : {};
  return (
    <pre
      style={{
        border: "1px solid #dee2e6",
        borderRadius: "5px",
        margin: "2px",
        padding: "5px",
        backgroundColor: "#f0f0f0",
        fontSize:'14px',
        // maxHeight: "750px",
      }}
    >
      {JSON.stringify(data, null, 2)}
    </pre>
  );
};
