
import { ErrorsApi } from "../web/errors";
import { useDispatch } from "react-redux";
import { api } from "../web/api";
import { setLoading } from "../redux/slices/LoadingSlice";
const lang = localStorage.getItem("lang");

const ApiHooksGet = () => {
    const dispatch = useDispatch();
    const baseQuery = async (url,params) => {
        try {
            dispatch(setLoading(true));
            const result = await api.get(url+`&lang=${lang}`);
            dispatch(setLoading(false));
            if (result.data.code === 0) {
                // if (params.type === 1)ErrorsApi(result.data.msg, "success");
                // else 
                return result.data;
            } else {
                ErrorsApi(result.data.msg, "alert");
                return result.data;
            }
        } catch (err) {
            dispatch(setLoading(false));
            ErrorsApi(err);
        } finally {
            dispatch(setLoading(false)); // надо ли закрывать axios?
        }
    };
    return baseQuery;
};

export default ApiHooksGet;
