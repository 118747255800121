import HeaderLink from "./components/HeaderLink";
import HeaderMobile from "./components/HeaderMobile";
import HeaderBase from "./components/HeaderBase";
import HeaderNav from "./components/HeaderNav";

import { Row, Col } from "antd";

function SiteHeader({ forModules }) {
  return (
    <Row>
      <Col span={24}>
        <HeaderLink />
        <HeaderMobile />
        {!forModules && <HeaderBase />}
        <HeaderNav forModules={forModules} />
        {/* <div id="forHeaderFullNav"></div> */}
      </Col>
    </Row>
  );
}
export default SiteHeader;
