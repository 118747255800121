import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form } from "antd";
import { getTitle } from "../../../Trans";
import GetOthers from "../../../templates/FormOther";
import FormSteps from "./NewFormComponents/FormSteps";
import moment from "moment";
import { date_format, year_format } from "../../../templates/Patterns";
import { REFERS } from "../../../constants/refers";
import ApiHooks from "../../../../hooks/ApiHooks";
import { useSelector } from "react-redux";
import { client_role } from "../../../../redux/slices/AuthSlice";

const RecordFormEdit = () => {
  const [value, setValue] = useState(false);
  const [valueName, setValueName] = useState('');
  const [edit, setEdit] = useState({});
  const [loc_region, setloc_region] = useState('');
  const [type] = Form.useForm();
  const [creditor] = Form.useForm();
  const [debtor] = Form.useForm();
  const [property] = Form.useForm();
  const [pledge] = Form.useForm();
  const { id } = useParams();
  const hookApi = ApiHooks();

  useEffect(() => {
    if (id) { getRecord(id) }
  }, []);

  useEffect(() => {
    if (value && !id) { getCreditor() }
  }, [value]);


  const getCreditor = async () => {
    let data = await hookApi("record/getCreditor", {});
    if (data.data) {
      let obj = data.data;
      if (value === '1' || value === '3') {
        if (obj.birth_date) {
          obj.birth_date = moment(obj.birth_date, date_format);
        };
        creditor.setFieldsValue(obj);
      }
      if (value === '2') {
        if (obj.birth_date) {
          obj.birth_date = moment(obj.birth_date, date_format);
        };
        if (obj.region) {
          setloc_region({ region: obj.region, loc_region: obj.loc_region });
        }
        debtor.setFieldsValue(obj);
      }
    }
  };

  const getRecord = async (id) => {
    let data = await hookApi("record/getDeclarationEdit", { code: id });
    if (data.data) {
      let obj = data.data
      setValue(obj.client_type);
      let pledge_obj = obj;
      let debtor_obj = obj.debtor;
      let creditor_obj = obj.creditor;
      let property_obj = obj.object[0];
      delete pledge_obj.object;
      delete pledge_obj.debtor;
      delete pledge_obj.creditor;
      pledge_obj.expired = pledge_obj.expired ? moment(pledge_obj.expired, date_format) : null;
      pledge_obj.right_date = pledge_obj.right_date ? moment(pledge_obj.right_date, date_format) : null;
      pledge_obj.doc_d = pledge_obj.doc_d ? moment(pledge_obj.doc_d, date_format) : null;
      if (property_obj.element_value) {
        if (property_obj.element_value.length === 19) {
          property_obj.element_value = property_obj.element_value + '_________'
        }
        if (property_obj.element_value.length === 22) {
          property_obj.element_value = property_obj.element_value + '______'
        }
      }
      if (creditor_obj.birth_date) {
        creditor_obj.birth_date = moment(creditor_obj.birth_date, date_format);
      };
      if (debtor_obj.birth_date) {
        debtor_obj.birth_date = moment(debtor_obj.birth_date, date_format);
      };
      if (property_obj.element_year) {
        property_obj.element_year = property_obj.element_year ? moment(property_obj.element_year, year_format) : null;
      }
      if (debtor_obj.region) {
        setloc_region({ region: debtor_obj.region, loc_region: debtor_obj.loc_region });
      }
      pledge.setFieldsValue(pledge_obj);
      debtor.setFieldsValue(debtor_obj);
      creditor.setFieldsValue(creditor_obj);
      property.setFieldsValue(property_obj);
      let result = {};
      result.debtor = {};
      result.creditor = {};
      result.object = {};
      result.property = {};
      result.property = pledge_obj;
      result.debtor = debtor_obj;
      result.creditor = creditor_obj;
      result.object = property_obj;
      setEdit(result);
    }
  };

  const onChange = (e) => {
    setValue(e.target.value.code);
    setValueName(e.target.value.name);
  };

  const client = useSelector(client_role);

  return (
    <Row className='backgroundFormRegistr'>
      <Col span={24}>
        <Row>
          {!id &&
            <Col span={24} style={{ display: value ? 'none' : '' }}>
              <Form form={type} name='type'>
                <GetOthers name='client_type' pattern={null} required={true} text={'Record sub title'} refer={REFERS.conREF_DEC_CLIENT_TYPES} onChange={onChange} value={value} component={'Radio'} client={client} />
              </Form>
            </Col>
          }
        </Row>
        {value && <FormSteps client_type={value} client_type_name={valueName} creditor={creditor} debtor={debtor} property={property} pledge={pledge} dataEdit={edit} loc_region={loc_region} />}
      </Col>
    </Row>
  );
};

export default RecordFormEdit;