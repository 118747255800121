import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import SiteHeader from "./header/SiteHeader";
import SiteFooter from "./footer/SiteFooter";
import OrgLinks from "./footer/OrgLinks";

const { Header, Content, Footer } = Layout;

const Site = () => {
  return (
    <Layout className="header-background">
      <Header className="header">
        <SiteHeader />
      </Header>
      <Content>
        <Outlet />
      </Content>
      <Footer>
        <OrgLinks />
        <SiteFooter />
      </Footer>
    </Layout>
  );
};

export default Site;
