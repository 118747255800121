import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import moment from "moment";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import ApiHooks from "../../../../hooks/ApiHooks";
import DynamicList from "./components/DynamicList";
import DynamicForm from "./components/DynamicForm";
import FilterForm from "./components/forms/FilterForm";
import { date_format } from "../../../templates/Patterns";

const ContentDynamic = (props) => {
  const [filterform] = Form.useForm();
  const [textform] = Form.useForm();
  const [fileform] = Form.useForm();
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();
  const [dataSource, setDataSource] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [contentId, setContentId] = useState(0);
  const defaultFormsValue = {
    text: {
      short_text_ru: "",
      short_text_uz: "",
      short_text_oz: "",
      short_text_en: "",
      text_ru: "",
      text_uz: "",
      text_oz: "",
      text_en: "",
    },
    docs: [],
  };
  const [formsValue, setFormsValue] = useState(defaultFormsValue);
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
  };

  const handleFilterForm = async (values) => {
    const data = {
      bdate: moment(values.bdate).format(date_format),
      edate: moment(values.edate).format(date_format),
    };
    const result = await hookApi("content/getDynamicList", {
      type: props.type * 1,
      data: data,
    });
    if (result?.data) {
      setDataSource(result.data.content);
    }
  };

  const loadNewContent = () => {
    setShowForm(true);
    setFormsValue(defaultFormsValue);
    textform.resetFields();
    setContentId(0);
  };

  const loadContent = async () => {
    const result = await hookApi("content/getDynamicData", {
      content_id: contentId,
    });
    if (result?.data) {
      setFormsValue(result.data);
      textform.setFieldsValue(result.data.text);
      setShowForm(true);
    } else {
      setContentId(0);
      setShowForm(false);
    }
  };

  const changeStateDynamicData = async (id, state) => {
    const result = await hookApi("content/changeStateDynamicData", {
      content_id: id,
      new_state: state,
    });
    if (result?.code === 0) {
      // const newData = dataSource.filter((item) => item.key !== key);
      // setDataSource(newData);
      filterform.submit();
    }
  };

  const closeForms = () => {
    setShowForm(false);
    setFormsValue(defaultFormsValue);
    setContentId(0);
    textform.resetFields();
    //filterform.submit()
  };

  const handleTextForm = async (values) => {
    await hookApi(
      "content/saveDynamicData",
      { type: props.type * 1, content_id: contentId, data: values },
      true
    );
  };

  const handleFileForm = async (values) => {
    const file = values.upload_file?.file;
    let obj = {};
    obj.content_id = contentId;
    //obj.doc_id = values.doc_id || null;
    obj.mime_type = file?.type || null;
    obj.doc_type = values.doc_type;
    obj.doc_url = values.doc_url || null;
    obj.doc_name_ru = values.doc_name_ru;
    obj.doc_name_uz = values.doc_name_uz;
    obj.doc_name_oz = values.doc_name_en;
    obj.doc_name_en = values.doc_name_en;
    const data = new FormData();
    data.append("upload_file", file?.originFileObj);
    data.append("data", JSON.stringify(obj));
    const result = await hookApi("content/saveDynamicDataFile", data, true, {
      "Content-Type": "multipart/form-data",
    });
    if (result?.code === 0) {
      fileform.resetFields();
      await loadContent();
    }
  };

  const handleFileDelete = async (doc_id) => {
    const result = await hookApi(
      "content/deleteDynamicDataFile",
      {
        content_id: contentId,
        doc_id: doc_id,
      },
      true
    );
    if (result?.code === 0) {
      await loadContent();
    }
  };

  // useEffect(() => {
  //   filterform.setFieldsValue(defaultFilterForm);
  //   filterform.submit();
  // }, [props.type]);

  useEffect(() => {
    if (contentId) loadContent();
  }, [contentId]);
  useEffect(() => {
    if (!showForm) filterform.submit();
  }, [showForm]);

  return (
    <>
      {showForm ? (
        <>
          <DynamicForm
            textform={textform}
            fileform={fileform}
            loading={loading}
            textFormData={formsValue.text}
            fileFormData={formsValue.docs}
            closeForms={closeForms}
            handleTextForm={handleTextForm}
            handleFileForm={handleFileForm}
            handleFileDelete={handleFileDelete}
            isEmpty={contentId === 0}
          />
          {contentId}
        </>
      ) : (
        <>
          <FilterForm
            form={filterform}
            onFinish={handleFilterForm}
            loading={loading}
            initialValues={defaultFilterForm}
            openEmpty={loadNewContent}
          />
          <br />
          <DynamicList
            dataSource={dataSource}
            open={setContentId}
            loading={loading}
            changeState={changeStateDynamicData}
          />
        </>
      )}
    </>
  );
};

export default ContentDynamic;
