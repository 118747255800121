import React from "react";
import { Col, DatePicker, Form, Row } from "antd";

import GetInput from "../../../../../templates/FormInput";
import { date_format, pattern } from "../../../../../templates/Patterns";
import Confirm from "../../../../../templates/Confirm";
import { getTitle } from "../../../../../Trans";
import GetSelect from "../../../../../templates/FormSelect";
import {REFERS} from "../../../../../constants/refers";
export const ClientForm = (props) => {
  return (
    <Form
      form={props.form}
      name={props.name}
      layout="vertical"
      onFinish={props.onFinish}
      disabled={props.disabled}
    >
      <Row gutter={5}>
        {(props?.clientData?.subject_type === 1 && (
          <Col span={8}>
            <GetInput
              name={"inn"}
              pattern={pattern.inn}
              required={true}
              text={"Inn"}
            />
          </Col>
        )) || (
          <Col span={8}>
            <GetInput
              name={"pin"}
              pattern={pattern.pin}
              required={true}
              text={"Pin"}
            />
          </Col>
        )}
      </Row>
      <Row gutter={5}>
        {props?.clientData?.subject_type !== 2 && (
          <Col span={8}>
            <GetInput
              name={"name"}
              // pattern={pattern.name}
              required={true}
              text={"name"}
            />
          </Col>
        )}
        {props?.clientData?.subject_type !== 1 && (
          <Col span={8}>
            <GetInput
              name={"first_name"}
              // pattern={pattern.name}
              required={true}
              text={"First name"}
            />
          </Col>
        )}
        {props?.clientData?.subject_type !== 1 && (
          <Col span={8}>
            <GetInput
              name={"last_name"}
              // pattern={pattern.name}
              required={true}
              text={"Last name"}
            />
          </Col>
        )}

        {props?.clientData?.subject_type !== 1 && (
          <Col span={8}>
            <GetInput
              name={"patronymic"}
              // pattern={pattern.name}
              required={true}
              text={"Patronymic"}
            />
          </Col>
        )}
        {props?.clientData?.subject_type !== 2 && (
          <Col span={8}>
            <GetSelect name={"org_form"} text={"org_form"} refer={REFERS.conREF_ORGANIZATION_FORMS} />
          </Col>
        )}

        {props?.clientData?.subject_type === 1 && (
          <Col span={8}>
            <GetInput
              name={"okpo"}
              required={true}
              text={"okpo"}
              pattern={pattern.okpo}
            />
          </Col>
        )}
        {props?.clientData?.subject_type === 1 && (
          <Col span={8}>
            <GetSelect name={"oked"} text={"oked"} refer={REFERS.conREF_OKED} search={true}/>
          </Col>
        )}

        {props?.clientData?.subject_type !== 1 && (
          <Col span={2}>
            <GetInput
              name={"passport_s"}
              pattern={pattern.passportS}
              required={true}
              text={"passport_s"}
            />
          </Col>
        )}
        {props?.clientData?.subject_type !== 1 && (
          <Col span={6}>
            <GetInput
              name={"passport_n"}
              pattern={pattern.passportN}
              required={true}
              text={"passport_n"}
            />
          </Col>
        )}

        <Col span={8}>
          <GetInput
            name={"email"}
            pattern={pattern.email}
            required={true}
            text={"Email"}
          />
        </Col>

        {props?.clientData?.subject_type !== 2 && (
          <>
            <Col span={8}>
              <GetSelect name={"branch"} text={"branch"} refer={"014"} search={true}/>
            </Col>
            <Col span={8}>
              <GetInput
                name={"account"}
                pattern={pattern.account}
                required={true}
                text={"account"}
              />
            </Col>
          </>
        )}

        <Col span={8}>
          <GetInput
            name={"phone"}
            pattern={pattern.phone}
            required={true}
            text={"Phone"}
          />
        </Col>
        {props?.clientData?.subject_type !== 1 && (
          <Col span={8}>
            <Form.Item
              label={getTitle("Birth date")}
              name="birth_date"
              rules={[{ required: true, message: "Error" }]}
            >
              <DatePicker format={date_format} />
            </Form.Item>
          </Col>
        )}

        <Col span={10}>
          <GetInput name={"address"} required={true} text={"Address"} />
        </Col>
        <Col span={24}>
          <Confirm  
            form={props.form}
            title={"Do you really want to save?"}
            btnText={"Save"}
            btnDisabled={props.disabled}
          />
        </Col>
      </Row>
    </Form>
  );
};
