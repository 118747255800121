import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Form, Pagination, Divider, Button } from "antd";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../../templates/MonitoringTable";
import moment from "moment";
import { date_format } from "../../../../templates/Patterns";
import { access_ } from "../../../../../redux/slices/AuthSlice";
import { getTitle } from "../../../../Trans";

const RecordOrderListForm = () => {
  const pageSize = 20;
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
    action_id: "-1",
    error: "0"
  };
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    code: null,
    client_code: null,
    page: 1,
    page_size: pageSize,
  });
  const loading = useSelector(loadingState);
  const access = useSelector(access_)
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    const result = await hookApi("record/getDeclarationOrderList", {
      data: sendObj,
    });
    if (result.data) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    const data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.code = values.code;
    data.client_code =  values.client_code ? values.client_code * 1 : null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const columns = [
    {
      title: "Код",
      dataIndex: "code",
      align: 'center',
      fixed: 'left',
      key: "code",
    },
    {
      title: getTitle('Code'),
      dataIndex: 'declaration_code',
      align: 'center',
      key: "declaration_code",
    },
    {
      title: getTitle('User'),
      dataIndex: "client_name",
      key: "client_name",
      hidden: (!access.record_list_super)
    },
    {
      title: getTitle('Administrator/Register'),
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: getTitle("Date and time of registration"),
      dataIndex: "registered",
      align: 'center',
      key: "registered",
    },
    {
      title: "",
      key: "buttons",
      align: 'center',
      fixed: 'right',
      render: (_, record) => (

        <Button
          disabled={loading}
          type="success"
          shape="circle"
          icon={<VerticalAlignBottomOutlined />}
          target="blank_"
          href={`/api/order/${record.code}`}
        />
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        initialValues={defaultFilterForm}
        role={access.record_list_super}
      />
      <Divider />
      <MonitoringTable
        size='small'
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey={(record) => record.code}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
          showTotal={(total) => `Всего ${total}`}
        />
      )}
    </>
  );
};

export default RecordOrderListForm;
