import { Row, Col, Form } from 'antd';
import { REFERS } from '../../../../../../constants/refers';
import GetInput from '../../../../../../templates/FormInput';
import GetSelect from '../../../../../../templates/FormSelect';
import { pattern } from '../../../../../../templates/Patterns';

const Juridical = (props) => {
    const resident = Form.useWatch('resident', props.form);
    return (<>
        <Row gutter={5}>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <GetInput name={'name'} required={true} pattern={pattern.name_opt} text={'name'} disabled={props.disabled} />
            </Col>
            <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                <GetSelect name={'resident'} required={true} refer={REFERS.conREF_RESIDENT} text={'resident'} func={(value, options) => { props.selectLabel({ resident: options.label }) }} disabled={props.disabled} />
            </Col>
            {resident === '2' &&
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'country'} required={true} refer={REFERS.conREF_COUNTRY} text={'country'} func={(value, options) => { props.selectLabel({ country: options.label }) }} disabled={props.disabled} />
                </Col>
            }
        </Row>
        {resident === '1' &&
            <Row gutter={5}>
                <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'org_form'} required={true} refer={REFERS.conREF_ORGANIZATION_FORMS} text={'Opf'} func={(value, options) => { props.selectLabel({ org_form: options.label }) }} disabled={props.disabled} />
                </Col>
                <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'inn'} required={true} pattern={pattern.inn} text={'inn'} disabled={props.disabled} />
                </Col>
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'branch'} required={true} func={(value, options) => { props.selectLabel({ branch: options.label }) }} refer={REFERS.conREF_BRANCH} search={true} text={'branch'} disabled={props.disabled} />
                </Col>
                <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'account'} required={true} pattern={pattern.account} text={'account'} disabled={props.disabled} />
                </Col>
                <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'okpo'} required={true} pattern={pattern.okpo} text={'okpo'} disabled={props.disabled} />
                </Col>
            </Row>
        }
        <Row gutter={5}>
            <Col xxl={5} xl={4} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'phone'} required={true} pattern={pattern.phone} text={'phone'} addonBefore={'+'} disabled={props.disabled} />
            </Col>
            <Col xxl={5} xl={4} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'email'} required={true} pattern={pattern.email} text={'email'} disabled={props.disabled} />
            </Col>
            <Col xxl={14} xl={12} lg={24} md={24} sm={24} xs={24}>
                <GetInput name={'address'} required={true} text={'address'} disabled={props.disabled} />
            </Col>
        </Row>

    </>
    )
}
export default Juridical;