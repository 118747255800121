import { ErrorsApi } from "./web/errors";
export const conf = {
  // server: "http://localhost:9000/",
  server: "/",
  img: {}
};

export const getLogo = () => {
  try {
    const lang = localStorage.getItem("lang");
    return Object.keys(conf.img.logo)
      .filter((key) => key.includes(lang))
      .reduce((cur, key) => conf.img.logo[key], "");
  } catch (error) {
    ErrorsApi(error);
  }
};

conf.img = {
  logo: {
    uz: conf.server + "static/logo/uz.svg",
    ru: conf.server + "static/logo/ru.svg",
    oz: conf.server + "static/logo/oz.svg",
    en: conf.server + "static/logo/en.svg",
  },
  social: {
    facebook: conf.server + "static/social/icon__brand_facebook.svg",
    instagram: conf.server + "static/social/icon__brand_instagram.svg",
    youtube: conf.server + "static/social/icon__brand_youtube.svg",
    telegram: conf.server + "static/social/icon__brand_telegram.svg",
    vk: conf.server + "static/social/icon__brand_vk.svg",
  },
  analytics: {
    mygovuz: conf.server + "static/analytics/mygovuz.jpg",
    data_gov: conf.server + "static/analytics/data_gov.png",
    pkm_oz: conf.server + "static/analytics/pkm-oz.png",
  },
};
