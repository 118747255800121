import React from "react";
import { Form, Row, Col, Button, Input } from "antd";
import Trans from "../../../../Trans";
import GetInput from "../../../../templates/FormInput";
import { pattern } from "../../../../templates/Patterns";
import GetOthers from "../../../../templates/FormOther";
import Confirm from "../../../../templates/Confirm";
const { TextArea } = Input;

const FilterFormModal = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <GetInput name={"code"} text={"code"} required={true} pattern={pattern.declaration_code} />
        </Col>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <GetInput name={'theme'} text={"Theme"} required={true} />
        </Col>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <GetOthers name={'message'} required={true} text={'Description'} component={<TextArea rows={4} showCount minLength={2} maxLength={1000} />} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Confirm form={props.form} title={'Are you sure you want send protest'} btnText='Send' btnType="primary" />
          <Button style={{ margin: "0 8px" }} onClick={() => { props.setModalProtest(false) }}>
            <Trans alias={"Close"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterFormModal;
