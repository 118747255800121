import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Form, Divider } from 'antd';
import ApiHooks from '../../../../hooks/ApiHooks';
import { loadingState } from '../../../../redux/slices/LoadingSlice';
import StatisticsFilterForm from './components/statistics-filter-form';
import { date_format } from '../../../templates/Patterns';
import StatisticsObligationYear from './components/table/statistics-obligation-year';
import StatisticsObligationSize from './components/table/statistics-obligation-size';
import StatisticsRecordClientYear from './components/table/statistics-record-client-year';
import StatisticsTotalClientAdmin from './components/table/statistics-total-client-admin';
import StatisticsCountByType from './components/table/statistics-count-by-type';
import StatisticsClientType from './components/table/statistics-client-type';

const GeneralStatForm = () => {
  const [filter] = Form.useForm();
  // Данные и пагинация
  const [statistics, setStatistics] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [excel, setExcel] = useState(false);

  const [selectLabel, setSelectLabel] = useState();
  const [fileName, setFileName] = useState('');
  const [columns, setColumns] = useState([]);
  const [type, setType] = useState();
  const [summaryTitleSize, setSummaryTitleSize] = useState(0);

  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();
  const pageSize = 20;

  const defaultFilterForm = {
    bdate: moment().startOf('month'),
    edate: moment(),
  };

  const [sendObj, setSendObj] = useState({
    bdate: moment().startOf('month'),
    edate: moment(),
    client_code: null,
    client_role: null,
    user_name: null,
    client_name: null,
    type: null,
    page: 1,
    page_size: pageSize,
    excel: 0,
  });

  const type_ = Form.useWatch('type', filter);

  useEffect(() => {
    getStatistic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendObj]);

  useEffect(() => {
    setCurrentPage(1);
    setDataCount(0);
  }, [type_]);

  const getStatistic = async () => {
    if (!sendObj.type) return null; // Ранний выход, если нет типа

    sendObj.excel = 0;
    try {
      const result = await hookApi('statistic/getStatisticByParam', {
        data: sendObj,
      });
      if (result.code === 0) {
        // Обработка для специальных типов
        if (['14', '15', '16', '17'].includes(sendObj.type)) {
          setDataCount(result.data.count);
        }
        setExcel(true);
        // Установка данных и имени файла
        setStatistics(result.data);
        setFileName(selectLabel);
      }
    } catch (error) {
      // Можно добавить обработку ошибок, если нужно
    }
  };

  const handleChangePage = async (value) => {
    setStatistics([]);
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    let data = {};
    setCurrentPage(1);
    data.page = 1;
    switch (values.type) {
      case '3':
        data.client_role = values.client_role ? values.client_role : null;
        break;
      case '14':
      case '15':
      case '16':
      case '17':
        data.bdate = values.bdate
          ? moment(values.bdate).format(date_format)
          : null;
        data.edate = values.edate
          ? moment(values.edate).format(date_format)
          : null;
        data.client_code = values.client_code ? values.client_code : null;
        data.client_name = values.client_name ? values.client_name : null;
        data.user_name = values.user_name ? values.user_name : null;
        data.client_role = values.client_role ? values.client_role : null;
        break;
      default:
        break;
    }
    data.type = values.type;
    setSendObj({ ...sendObj, ...data });
  };

  const resetForm = (saveData) => {
    if (!saveData) {
      filter.resetFields();
      filter.submit();
    }
    setSummaryTitleSize(0);
    setStatistics([]);
    setColumns([]);
    setExcel(false);
  };

  const renderContent = () => {
    if (!statistics?.data?.length) return '';
    switch (type) {
      case '1':
        return (
          <StatisticsObligationYear
            statistics={statistics}
            setSummaryTitleSize={setSummaryTitleSize}
            setColumns={setColumns}
          />
        );
      case '2':
        return (
          <StatisticsClientType
            statistics={statistics}
            setSummaryTitleSize={setSummaryTitleSize}
            setColumns={setColumns}
          />
        );
      case '3':
        return (
          <StatisticsObligationSize
            statistics={statistics}
            setSummaryTitleSize={setSummaryTitleSize}
            setColumns={setColumns}
            type={type}
          />
        );
      case '11':
        return (
          <StatisticsRecordClientYear
            statistics={statistics}
            setSummaryTitleSize={setSummaryTitleSize}
            setColumns={setColumns}
            title={fileName}
          />
        );
      case '12':
      case '13':
        return (
          <StatisticsCountByType
            statistics={statistics}
            setColumns={setColumns}
            setSummaryTitleSize={setSummaryTitleSize}
            title={fileName}
            type={type}
          />
        );
      case '14':
      case '15':
      case '16':
      case '17':
        return (
          <StatisticsTotalClientAdmin
            statistics={statistics}
            setColumns={setColumns}
            setSummaryTitleSize={setSummaryTitleSize}
            title={fileName}
            handleChangePage={handleChangePage}
            dataCount={dataCount}
            currentPage={currentPage}
            pageSize={pageSize}
            type={type}
          />
        );
      default:
        return '';
    }
  };

  return (
    <>
      <StatisticsFilterForm
        sendObj={sendObj}
        form={filter}
        onFinish={handleFilterForm}
        resetForm={resetForm}
        initialValues={defaultFilterForm}
        disabled={loading}
        excel={excel}
        selectLabel={setSelectLabel}
        columns={columns}
        fileName={fileName}
        dataSource={statistics}
        setType={setType}
        summaryTitleSize={summaryTitleSize}
      />
      <Divider />
      <div style={{ paddingBottom: 24 }}>{renderContent()}</div>
    </>
  );
};

export default GeneralStatForm;
