import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Table, Space, Divider, Badge } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ApiHooks from "../../../../../../hooks/ApiHooks";
import { loadingState } from "../../../../../../redux/slices/LoadingSlice";
import Trans, { getTitle } from "../../../../../Trans";
import FilterForm from "./components/FilterForm";
import ModalComponent from "../../../../../templates/ModalComponent";

const ServiceInternalForm = () => {

    const hookApi = ApiHooks();
    const [form] = Form.useForm();
    const [filter] = Form.useForm();
    const [data, setData] = useState([]);
    const [refer, setRefer] = useState();
    const [modal, setModal] = useState(false);
    const [reqData, setReqData] = useState();
    const [modalTitle, setModalTitle] = useState();
    const loading = useSelector(loadingState);

    const modalOnOff = (record) => {
        setModal(true);
        setReqData(record);
        setModalTitle(record.access_state === 1 ? 'Are you sure you want to disable this method?' : record.access_state === 0 ? "Are you sure you want to enable this method?" : null)
    };

    const setOnOffMethod = async () => {
        let obj = {};
        obj.code = refer;
        obj.id = reqData.id;
        obj.access_state = reqData.access_state === 1 ? 0 : reqData.access_state === 0 ? 1 : null;
        const result = await hookApi("settings/setInternalServiceAccess", { data: obj })
        if (result) {
            const result = await hookApi("settings/getInternalServiceList", { code: refer });
            if (result.data) {
                setData(result.data.data);
            }
            setModal(false);
        }
    };

    const getInternalServiceList = async (values) => {
        const result = await hookApi("settings/getInternalServiceList", { code: values.internal_service });
        if (result.data) {
            setRefer(values.internal_service);
            setData(result.data.data);
        }
    };

    const columns = [
        {
            title: '№',
            dataIndex: "",
            key: '',
            align: "center",
            render: (text, record, index) => (index + 1),
            fixed: true,
        },
        {
            title: 'ID',
            dataIndex: "id",
            key: 'id',
            align: "center",
            fixed: true,
        },
        {
            title: getTitle('name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: getTitle('State'),
            dataIndex: 'access_state',
            align: 'center',
            render: (text, record) => (
                <Badge
                    count={record.access_state_name}
                    style={{ backgroundColor: `${record.access_state === 1 ? "#52c41a" : "#f5222d"}` }}
                />
            ),
            key: 'access_state'
        },
        {
            dataIndex: 'edit',
            align: "center",
            key: 'edit',
            fixed: 'right',
            render: (_, record) => (
                <Space>
                    {record.access_state === 0 &&
                        <Button
                            title="Включить"
                            disabled={loading}
                            type="success"
                            shape="circle"
                            icon={<CheckOutlined />}
                            onClick={() => modalOnOff(record)}
                        />}
                    {record.access_state === 1 &&
                        <Button
                            title="Отключить"
                            disabled={loading}
                            type="danger"
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => modalOnOff(record)}
                        />}
                </Space>
            ),
        },
    ];


    return (
        <>
            <FilterForm form={filter} onFinish={getInternalServiceList} disabled={loading} resetTable={setData} />
            <Divider />
            <Form form={form} component={false}>
                <Table
                    loading={loading}
                    size="small"
                    bordered
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                />
            </Form>
            <ModalComponent
                title={<Trans alias={"Disabling and enabling the web service method"} />}
                open={modal}
                data={getTitle(modalTitle)}
                onOk={() => setOnOffMethod()}
                onCancel={() => setModal(false)}
                loading={loading}
                cancelTitle={<Trans alias={"No"} />}
                okTitle={<Trans alias={"Yes"} />}
            />
        </>
    );
};

export default ServiceInternalForm;
