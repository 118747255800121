import { Input, Form, Space } from "antd";
import React from "react";
import { TransFunc } from "../Trans";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
const GetInput = (props) => {
  const trans = TransFunc(props.text);
  const required_msg = TransFunc("Props is a must");
  const pattern_msg = TransFunc("Does not match with pattern");
  if (trans) {
    return (
      <Form.Item
        name={props.name}
        label={props.withOutlabel ? "" : (trans.NAME && trans.NAME) || trans}
        tooltip={
          props.tooltip
            ? {
                title: props.tooltip,
                icon: <QuestionCircleOutlined style={{ color: "red" }} />,
                color: "red",
              }
            : null
        }
        rules={[
          { required: props.required, message: required_msg.NAME },
          { pattern: props.pattern, message: pattern_msg.NAME },
        ]}
        key={trans.ID}
      >
        {!props.mask ? (
          <Input
            type={props.type}
            onPaste={props.onPaste}
            onChange={props.onChange}
            addonBefore={props.addonBefore}
            placeholder={(trans.NAME && trans.NAME) || trans}
            addonAfter={props.addonAfter}
            disabled={props.disabled}
            maxLength={props.maxLength}
            value={props.value}
            prefix={props.prefix}
          />
        ) : (
          <MaskedInput
            value={props.value}
            onChange={props.onChange}
            onPaste={props.onPaste}
            disabled={props.disabled}
            maskOptions={{ mask: "00:00:00:00:00:0000#0000&000", definitions: { "#": /^[:\/]?$/, "&": /^[:]?$/ } }}
          />
        )}
      </Form.Item>
    );
  }
};

export default GetInput;
