import { Row, Col } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getLogo } from "../../../config";

function HeaderBase() {
  return (
    <Row className="header-base">
      <Col span={12} className="header-base-logo">
        <Link to="/">
          <img src={getLogo()} alt="" />
        </Link>
      </Col>
      <Col span={12} className='header-base-col'>
        <div className="header-base-div">
          <PhoneOutlined className="header-base-icon" rotate={180} />
          <a href="tel:+998712126003">
            <small>(+998 71)</small> 212-60-03
          </a>
          ,
          <a href="tel:+998712562532">
            <small>(+998 71)</small> 256-25-32
          </a>
        </div>
        <div className="header-base-div">
          <MailOutlined className="header-base-icon" />
          <a href="mailto:reestr@garov.uz">reestr@garov.uz</a>
        </div>
        <div className="header-base-div">
          <EnvironmentOutlined className="header-base-icon" />
          <span>г.Ташкент, ул. Ислама Каримова, 6</span>
        </div>
      </Col>
    </Row>
  );
}

export default HeaderBase;
