import { Button, Result } from 'antd';
import { Navigate, Link } from 'react-router-dom';

function HttpError(status, msg, btn) {
    return (
        <Result
            status={500}
            title={msg}
            subTitle={status}
            //   extra = {<Button type="primary" /*onClick={() => reloadPage()}*/>Обновить</Button>}
            extra={<Button type="primary" onClick={() => window.location.href='/'}>{btn}</Button>}
        />
    );
};
export default HttpError;