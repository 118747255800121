import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Space, Table, Input } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import Trans, { getTitle } from "../../../Trans";
import { useSelector } from "react-redux";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import ApiHooks from "../../../../hooks/ApiHooks";
import Confirm from "../../../templates/Confirm";
import GetTextArea from "../../../templates/FormTextArea";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <GetTextArea name={dataIndex} required={true} />
      ) : (
        children
      )}
    </td>
  );
};
const ContentLang = (props) => {

  const hookApi = ApiHooks();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const loading = useSelector(loadingState);
  const isEditing = (record) => record.key === editingKey;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          size='small'
          placeholder={getTitle('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            <Trans alias={'Search'} />
          </Button>
          <Button
            type="dark"
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            <Trans alias={'Reset'} />
          </Button>
          <Button
            type="danger"
            size="small"
            onClick={() => { close() }}
          >
            <Trans alias={'Close'} />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : 'red' }} title={getTitle('Search')} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  useEffect(() => { getData() }, [])

  const getData = async () => {
    const result = await hookApi("content/getLangList", {});
    if (result.data.lang) setData(result.data.lang);
  };

  const edit = (record) => {
    form.setFieldsValue({
      id: '',
      name_ru: '',
      name_uz: '',
      name_oz: '',
      name_en: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        let saveChanged = await hookApi("content/saveLang", { langId: id, js: row }, true);
        if (saveChanged) {
          setData(newData);
          setEditingKey('');
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      align: "center",
      fixed: true,
      width: '3%',
    },
    {
      title: getTitle('Text in Russian'),
      dataIndex: 'name_ru',
      ...getColumnSearchProps('name_ru'),
      editable: true,
      width: '24%',
    },
    {
      title: getTitle('Text in Uzbek'),
      dataIndex: 'name_uz',
      ...getColumnSearchProps('name_uz'),
      editable: true,
      width: '24%',
    },
    {
      title: getTitle('Text in Uzbek (latin)'),
      dataIndex: 'name_oz',
      ...getColumnSearchProps('name_oz'),
      editable: true,
      width: '24%',
    },
    {
      title: getTitle('Text in English'),
      dataIndex: 'name_en',
      ...getColumnSearchProps('name_en'),
      editable: true,
      width: '24%',
    },
    {
      dataIndex: 'edit',
      align: "center",
      fixed: 'right',
      width: '1%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Confirm
              shape="circle"
              func={() => save(record.key, record.id)}
              title={"Do you really want to save?"}
              btnDisabled={loading}
              btnIcon={<CheckOutlined />}
              btnType={'success'}
              btnTitle={"Save"}
            />
            <Confirm
              shape="circle"
              func={cancel}
              title={"Cancel changed"}
              btnDisabled={loading}
              btnIcon={<CloseOutlined />}
              btnType={'danger'}
              btnTitle={"Cancel"}
            />
          </Space>
        ) : (<>
          <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)} shape="circle" icon={<EditOutlined />} />
        </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        components={{body: {cell: EditableCell} }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
          showSizeChanger: false,
          defaultPageSize: 15
        }}
      />
    </Form>
  );
};

export default ContentLang;
