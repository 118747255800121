import { createSlice } from "@reduxjs/toolkit";

const LoadingSlice = createSlice({
    name: 'loading',
    initialState: {
        state:false
    },
    reducers: {
        setLoading: (state, action) => {
            state.state = action.payload;
        }
    }
});

export const loadingState = (state) => state.loading.state;
export const { setLoading } = LoadingSlice.actions;
export default LoadingSlice.reducer;
