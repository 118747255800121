import React, { useState } from "react";
import { useSelector } from "react-redux";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Row, Col, Badge } from "antd";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../../templates/MonitoringTable";
import Trans, { getTitle } from "../../../../Trans";
import GetOrder from "../../../../templates/GetOrder";
import { access_ } from "../../../../../redux/slices/AuthSlice";
import { client_role } from "../../../../../redux/slices/AuthSlice";
import FormPaymentPay from "../components/FormPaymentPay";

const RecordOrderSearchForm = () => {
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const loading = useSelector(loadingState);
  const access = useSelector(access_);
  const [modalOrder, setModalOrder] = useState(false);
  const [payment, setPayment] = useState();
  const [record_code, setRecord_code] = useState("");
  const client = useSelector(client_role);
  const hookApi = ApiHooks();

  const handleFilterForm = async (values) => {
    setPayment();
    const data = {};
    data.code = values.code ? values.code : null;
    data.inn = values.inn ? values.inn : null;
    data.pin = values.pin ? values.pin : null;
    data.body_num = values.body_num ? values.body_num : null;
    data.cadastre = values.cadastre
      ? values.cadastre.replaceAll("_", "")
      : null;
    data.tech_num = values.tech_num ? values.tech_num : null;
    const result = await hookApi("record/getDeclarationOrderSearch", {
      data: data,
    });
    if (result.data) {
      if (result.data.data[0].not_found_code) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      setDataSource(result.data.data);
    }
  };

  const getConfirm = (code) => {
    setRecord_code(code);
    setModalOrder(true);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      render: (value, row, index) => index + 1,
      align: "center",
      width: "5%",
      key: "index",
    },
    {
      title: getTitle("Code"),
      dataIndex: "code",
      align: "center",
      width: "15%",
      key: "code",
    },
    {
      title: getTitle("State"),
      dataIndex: "state_name",
      align: "center",
      width: "15%",
      key: "state_name",
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{
            backgroundColor: `${record.state === "1" ? "#52c41a" : "#f5222d"}`,
          }}
        />
      ),
    },
    {
      title:
        getTitle("debtor") +
        " ( " +
        getTitle("name") +
        " / " +
        getTitle("S.F.P") +
        " )",
      dataIndex: "debtor_name",
      key: "debtor_name",
    },
    {
      title: "",
      key: "buttons",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <>
          {((record.printable === 1 && access.record_order_certified) ||
            access.record_order_super) && (
              <Button
                disabled={loading}
                type="success"
                shape="circle"
                icon={<VerticalAlignBottomOutlined />}
                target="blank_"
                onClick={() => getConfirm(record.code)}
              />
            )}
        </>
      ),
    },
  ];

  const onPayment = async (res) => {
    setModalOrder(false);
    let result = await hookApi(
      "record/getOrder",
      { code: record_code },
      "order"
    );
    if (result.payment_id) {
      setPayment(result);
    }
  };

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        role={access.record_list_super}
        resetData={() => {
          setDataSource([]);
          setNotFound(false);
        }}
      />
      <Divider />
      {notFound ? (
        <Row className="form-back" justify="center">
          <Col>
            <Trans alias='Search found nothing. To obtain a certified statements click on the button' />
            {(access.record_order_certified || access.record_order_super) && (
              <Button
                disabled={loading}
                type="success"
                shape="circle"
                icon={<VerticalAlignBottomOutlined />}
                target="blank_"
                onClick={() => getConfirm(dataSource[0].not_found_code)}
              ></Button>
            )}
          </Col>
        </Row>
      ) : (
        Array.isArray(dataSource) &&
        dataSource.length > 0 && (
          <MonitoringTable
            size="small"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={true}
            bordered={true}
            rowKey={(record) => record.code}
          />
        )
      )}
      {payment && (
        <FormPaymentPay payment_id={payment.payment_id} loading={loading} />
      )}
      <GetOrder
        modal={modalOrder}
        onCancel={() => setModalOrder()}
        record_code={record_code}
        onPayment={() => onPayment()}
        client={client}
      />
    </>
  );
};

export default RecordOrderSearchForm;
