import React from "react";
import { useSelector } from "react-redux";
import { Switch, Table, Modal } from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
const { confirm } = Modal;

export const ViewPrivilages = (props) => {
  const { data, handleChangePrivilage, user_id, access, status } = props;
  const loading = useSelector(loadingState);

  const confirmModal = (json, title = "Confirm") => {
    confirm({
      title: "Вы действительно хотите изменить?",
      icon: <ExclamationCircleOutlined />,
      okText: "Сохранить",
      cancelText: "Отмена",
      async onOk() {
        handleChangePrivilage(json);
      },
    });
  };

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Доступ",
      dataIndex: "exist",
      key: "value",
      width: 200,
      render: (text, record) => (
        <Switch
          checkedChildren="Да"
          unCheckedChildren="Нет"
          checked={text}
          disabled={
            !access.manage_admin ||
            loading ||
            status === 2
          }
          onClick={() => {
            const json = { user_id, code: record.code, exist: !record.exist };
            confirmModal(json);
          }}
        />
      ),
    },
  ];
  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      showHeader={false}
    />
  );
};
