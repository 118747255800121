import { Form, Row, Col, Input, Divider, DatePicker } from 'antd';
import { getTitle } from '../../../../Trans';
import GetSelect from '../../../../templates/FormSelect';
import GetOthers from '../../../../templates/FormOther';
import GetInput from '../../../../templates/FormInput';
import { pattern } from '../../../../templates/Patterns';
import CadastreInput from '../../../../templates/CadastreInput';
import { REFERS } from '../../../../constants/refers';
const { TextArea } = Input;

const FormProperty = (props) => {
    const provision_group = Form.useWatch('provision_group', props.form);
    const provision_type = Form.useWatch('provision_type', props.form);
    const cadastreText = getTitle('Cadastral number formats');
    return (
        <Form form={props.form} onFinish={props.onFinish} name={props.name} style={props.style} layout="vertical">
            <Col span={24}>
                <Row gutter={5}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <GetSelect name={'provision_group'} required={true} refer={REFERS.conREF_PROVISION_TYPES_GROUPS} func={(value, options) => { props.selectLabel({ provision_group: options.label }); props.form.setFieldValue("provision_type", []) }} text={'provision_group'} />
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        {provision_group && <GetSelect name={'provision_type'} required={true} func={(value, options) => { props.selectLabel({ provision_type: options.label }) }} refer={REFERS.conREF_PROVISION_TYPES} text={'provision_type'} referGroup={provision_group} />}
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={5}>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <GetOthers name={'description'} pattern={''} required={true} text={'description'} component={<TextArea rows={4} showCount minLength={2} />} />
                    </Col>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <i>{getTitle('Description of the property for which the entry is being made.')}</i>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={5}>
                    {(provision_group === '100' || (provision_group === '700' && provision_type === '706')) &&
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <CadastreInput name={'element_value'} text={'element_cadastre'} />
                            <div dangerouslySetInnerHTML={{ __html: cadastreText }} />
                        </Col>
                    }
                    {(provision_group === '200') &&
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <GetInput name={'element_value'} required={true} text={'element_car'} />
                        </Col>
                    }
                    {(provision_type === '701' || provision_type === '702') &&
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <GetInput name={'element_value'} required={true} text={'tech_num'} />
                        </Col>
                    }
                    {(provision_group === '200' || provision_type === '701' || provision_type === '702') &&
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <GetOthers name={'element_year'} pattern={null} required={true} text={'Year'} component={<DatePicker picker="year" style={{ width: '100%' }} />} />
                            {/* <GetInput name={'element_year'} pattern={pattern.year} required={true} text={'Year'} /> */}
                        </Col>
                    }
                    {props.client_type !== '3' &&
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <GetInput name={'element_cost'} required={true} pattern={pattern.sum} text={'element_cost'} />
                        </Col>}
                </Row>
            </Col>
        </Form>
    )
};
export default FormProperty;