import { Form, Row, Col, Button, Divider } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import GetInput from "../../../../../templates/FormInput";
import GetSelect from "../../../../../templates/FormSelect";
import { pattern } from "../../../../../templates/Patterns";
import Confirm from "../../../../../templates/Confirm";
import { REFERS } from '../../../../../constants/refers'
import CadastreInput from "../../../../../templates/CadastreInput";
const FilterFormModal = (props) => {

  const type = Form.useWatch('type', props.form);
  const object_type = Form.useWatch('object_type', props.form);

  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "modalForm",
    layout: "horizontal",
    className: "form-back",
    labelCol: { xxl: 6, xl: 6, lg: 24, md: 24, sm: 24, xs: 24 },
    wrapperCol: { xxl: 16, xl: 16, lg: 24, md: 24, sm: 24, xs: 24 },
    initialValues: { type: '1' }
  };
  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Divider>{getTitle('Pledgor details')}</Divider>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <GetSelect name={'type'} required={true} refer={REFERS.conREF_NOTARY_SUBJECT_TYPES} text={'Subject_type'} />
        </Col>
        {type === '1' &&
          <>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"pin"} text={"Pin"} required={true} pattern={pattern.pin} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={'pass_serial'} pattern={pattern.passportS} required={true} text={'passport_s'} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={'pass_num'} pattern={pattern.passportN} required={true} text={'passport_n'} />
            </Col>
          </>
        }
        {type === '2' &&
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <GetInput name={"inn"} required={true} text={"Inn"} pattern={pattern.inn} />
          </Col>
        }
        <Divider>{getTitle('Information about the pledge')}</Divider>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <GetSelect name={'object_type'} required={true} refer={REFERS.conREF_NOTARY_OBJECT_TYPES} text={'Type of property'} />
        </Col>
        {object_type === '2' &&
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <CadastreInput name={'cadastre_num'} text={'element_cadastre'} disabled={props.disabled} />
          </Col>
        }
        {object_type === '1' &&
          <>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"state_num"} required={true} text={"State license plate"} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"engine_num"} required={true} text={"Engine number"} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"body_num"} required={true} text={"Body Car Number"} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"chassis_num"} required={true} text={"Chassis number"} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"vehicle_id"} required={true} text={"Unique vehicle number in police"} pattern={pattern.number} />
            </Col>
          </>
        }
      </Row>
      <Row justify="end">
        <Col xxl={14} xl={14} lg={14} md={14} sm={24} xs={24}>
          <Confirm form={props.form} title={'Do you really want to generate the file?'} btnText='Search' btnType="primary" />
          <Button style={{ margin: "0 8px" }} onClick={() => { props.form.resetFields(); props.form.submit(); }}>
            <Trans alias={"Reset"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterFormModal;
