import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, DatePicker, Alert, Input } from 'antd';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { loadingState } from '../../redux/slices/LoadingSlice';
import { getTitle } from '../Trans';
import Trans from '../Trans';
import GetInput from '../templates/FormInput';
import GetOthers from '../templates/FormOther';
import ApiHooksGet from '../../hooks/ApiHooksGet';
import ApiHooks from '../../hooks/ApiHooks';
import { date_format, pattern } from '../templates/Patterns';
import moment from 'moment';

const Activation = () => {
    const [form] = Form.useForm();
    const [content, setContent] = useState(false);
    const [success, setSuccess] = useState(false);
    const loading = useSelector(loadingState);
    const hookApiGet = ApiHooksGet();
    const hookApiPost = ApiHooks();
    const { id } = useParams();

    const activateLink = async () => {
        let activeLink = await hookApiGet(`user/activateLink/?link=${id}`);
        if (activeLink.code === 0) {
            setContent(true);
        }
    };

    const activateUser = async (values) => {
        values.birth_date = moment(values.birth_date).format("DD.MM.YYYY");
        values.link = id
        let activeUser = await hookApiPost(`user/activateUser/`, values);
        if (activeUser.code === 0) {
            setSuccess(true);
        }
    }

    useEffect(() => {
        activateLink();
    }, []);

    const notMatch = getTitle("The two passwords that you entered do not match");

    return (
        <Row className='backgroundFormRegistr'>
            <Col span={24} style={{ display: content && !success ? '' : 'none' }}>
                <Form form={form} layout="vertical" onFinish={activateUser}>
                    <Row gutter={5} justify="center">
                        <Col span={24}><h1 className='regist-title-grey'>{getTitle('Confirm User')}</h1></Col>
                        <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                            <GetInput name={'pin'} pattern={pattern.pin} required={true} text={'Pin'} />
                        </Col>
                        <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                            <GetOthers name={'birth_date'} pattern={null} required={true} text={'Birth date'} component={<DatePicker format={date_format} style={{ width: '100%' }} />} />
                        </Col>
                    </Row>
                    <Row gutter={5} justify="center">
                        <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                            <GetInput name={'username'} pattern={pattern.login} required={true} text={'Username'} />
                        </Col>
                        <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                name={"new_password"}
                                label={getTitle("New password")}
                                rules={[{ required: true, message: getTitle("Props is a must") }]}
                                hasFeedback
                            >
                                <Input.Password type="password" placeholder={getTitle("New password")} />
                            </Form.Item>
                        </Col>
                        <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                name={"new_password_again"}
                                label={getTitle("New password (again)")}
                                hasFeedback
                                dependencies={["new_password"]}
                                rules={[
                                    { required: true, message: getTitle("Props is a must") },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue("new_password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(notMatch);
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    type="password"
                                    placeholder={getTitle("New password (again)")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={5} justify="end">
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Button htmlType="submit" className='registr-form-button' loading={loading}>
                                <Trans alias={"Save"} />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col span={24} style={{ display: !content && !success ? '' : 'none' }}> <Alert message={getTitle('Activate link')} type="error" /></Col>
            <Col span={24} style={{ display: content && success ? '' : 'none' }}> <Alert message={getTitle('Activate User')} type="success" /></Col>
        </Row >
    );
};

export default Activation