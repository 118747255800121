import { Row, Col, DatePicker, Form } from 'antd';
import GetInput from '../../../../../../templates/FormInput';
import GetSelect from '../../../../../../templates/FormSelect';
import GetOthers from '../../../../../../templates/FormOther';
import { pattern, date_format } from '../../../../../../templates/Patterns';
import { REFERS } from '../../../../../../constants/refers';

const Individual = (props) => {
    return (<>
        <Row gutter={5}>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <GetInput name={'name'} required={true} pattern={pattern.name_opt} text={'name'} disabled={props.disabled}/>
            </Col>
        </Row>
        <Row gutter={5}>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'last_name'} pattern={pattern.fio} required={true} text={'last_name'} disabled={props.disabled}/>
            </Col>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'first_name'} pattern={pattern.fio} required={true} text={'first_name'} disabled={props.disabled}/>
            </Col>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'patronymic'} pattern={pattern.fio} required={false} text={'patronymic'} disabled={props.disabled}/>
            </Col>
        </Row>
        <Row gutter={5}>
            <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'pin'} pattern={pattern.pin} required={true} text={'pin'} disabled={props.disabled}/>
            </Col>
            <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                <GetOthers name={'birth_date'} pattern={null} required={true} text={'birth_date'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.disabled}/>} />
            </Col>
            <Col xxl={2} xl={2} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'passport_s'} pattern={pattern.passportS} required={true} text={'passport_s'} disabled={props.disabled}/>
            </Col>
            <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'passport_n'} pattern={pattern.passportN} required={true} text={'passport_n'} disabled={props.disabled}/>
            </Col>
            <Col xxl={9} xl={9} lg={12} md={12} sm={24} xs={24}>
                <GetSelect name={'branch'} required={true} func={(value, options) => { props.selectLabel({ branch: options.label }) }} refer={REFERS.conREF_BRANCH} search={true} text={'branch'} disabled={props.disabled}/>
            </Col>
            <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'account'} pattern={pattern.account} required={true} text={'account'} disabled={props.disabled}/>
            </Col>
        </Row>
        <Row gutter={5}>
            <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'phone'} pattern={pattern.phone} required={true} text={'phone'} addonBefore={'+'} disabled={props.disabled}/>
            </Col>
            <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'email'} pattern={pattern.email} required={true} text={'email'} disabled={props.disabled}/>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                <GetInput name={'address'} text={'address'} required={true} disabled={props.disabled}/>
            </Col>
        </Row>
    </>
    )
}
export default Individual;