import React from "react";
import { Form, Row, Col, DatePicker, Button } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import { date_format } from "../../../../../templates/Patterns";

const FilterForm = (props) => {
    const formConfig = {
        form: props.form,
        onFinish: props.onFinish,
        disabled: props.disabled,
        name: "filterForm",
        layout: "vertical",
        className: "form-back",
        initialValues: props.initialValues,
    };

    return (
        <Form {...formConfig}>
            <Row gutter={5}>
                <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
                    <Form.Item label={getTitle("Begin date")} name="bdate">
                        <DatePicker format={date_format} />
                    </Form.Item>
                </Col>
                <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
                    <Form.Item label={getTitle("End date")} name="edate">
                        <DatePicker format={date_format} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button type="primary" htmlType="submit">
                        <Trans alias={"Search"} />
                    </Button>
                    <Button style={{ margin: "0 8px" }} onClick={() => { props.form.resetFields(); props.form.submit(); }}>
                        <Trans alias={"Reset"} />
                    </Button>{
                        props.role.search &&
                        <Button type={'success'} onClick={() => { props.modal() }}>
                            <Trans alias={"Getting information"} />
                        </Button>
                    }
                </Col>
            </Row>
        </Form>
    );
};

export default FilterForm;
