import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Space, Typography, Image } from "antd";
import { useParams } from "react-router-dom";
import { getApi } from "../../../web/api";
import { conf } from "../../../config";
import TextTinyMce from "../../templates/TextTinyMce";
import { DownloadOutlined } from "@ant-design/icons";
import Trans from "../../Trans";
import DownloadContent from "../../templates/DownloadContent";
const { Text } = Typography;

const ContentComponent = () => {
  const obj = {
    title: "",
    text: "",
    charged: "",
    title_file: false,
    docs: [],
    images: [],
  };
  const [content, setContent] = useState(obj);
  const { id } = useParams();
  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    let res = await getApi(`main/content_dynamic/${id}`);
    setContent(res ? res : obj);
  };
  return (
    <>
      <h3>{content.title}</h3>
      <Row justify="end">
        <Col>
          <Space size="middle">
            <Text>
              <Trans alias={"Update date"} />
            </Text>
            <Text>{content.charged}</Text>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        {content.title_file && (
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "3px",
              maxHeight: "600px",
              maxWidth: "800px",
            }}
            alt="example"
            src={`${conf.server}main/content_dynamic/title_file/${id}`}
          />
        )}
      </Row>
      <TextTinyMce text={content.text} />
      {content?.docs?.map((doc) => (
        <React.Fragment key={doc.doc_id}>
          {doc.doc_type === 0 ? (
            <DownloadContent
              href={`${conf.server}main/content_dynamic/doc_file?id=${content.id}&doc_id=${doc.doc_id}&mime_type=${doc.mime_type}`}
              text={doc.doc_name}
            />
          ) : (
            <DownloadContent
              key={doc.doc_id}
              href={doc.doc_url}
              text={doc.doc_name}
              link={true}
            />
          )}
        </React.Fragment>
      ))}
      <ul className="ulImg">
        {content.images.map((obj) => (
          <li>
            <Image
              // width={300}
              // height={300}
              src={`${conf.server}main/content_dynamic/image_file/${content.id}/${obj}`}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
export default ContentComponent;
