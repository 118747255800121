import { Statistic } from 'antd';
import { getTitle } from '../../../../../Trans';

function TableSummaryComponent({ summaryData = [], titleColSpan }) {
  const titleAmountSum = getTitle('Total');
  return (
    <tr style={{ textAlign: 'center', fontWeight: 'bold' }}>
      <td colSpan={titleColSpan}>{titleAmountSum}</td>
      {summaryData.map((elem, index) => (
        <td key={index}>
          <Statistic
            value={elem.value}
            precision={elem?.precision || 0}
            groupSeparator={elem?.seperator || ' '}
            valueStyle={{ fontSize: '14px' }}
          />
        </td>
      ))}
    </tr>
  );
}

export default TableSummaryComponent;
