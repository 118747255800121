import React from "react";
import { Row, Col, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMenu } from "../../redux/slices/SiteSlice";
import { conf, getLogo } from "../../config";
import Trans from "../Trans";
const { Text } = Typography;
function SiteFooter() {
  const menuList = useSelector(selectMenu);
  return (
    <>
      <Row>
        <Col
          xxl={8}
          xl={8}
          lg={8}
          md={12}
          sm={24}
          xs={24}
          className="footer-menu-col"
        >
          <Row className="footer-menu-logo">
            <Col className="footer-menu-col-container">
              <Link to="/">
				<img src={getLogo()} alt="" />
              </Link>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col className="footer-menu-col-container">
              <Text className="footer-menu-text">
                © 2014–2022 <Trans alias={"Site title"} />
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="footer-menu-col-container">
              <Text strong>Манзил:</Text>
              <div>100001, Тошкент ш., Ислом Каримов кўчаси, 6</div>
            </Col>
            <Col span={12} className="footer-menu-col-container">
              <Text strong>Алоқа телефон рақами:</Text>
              <div>
                <a href="tel:+998712126003">(+998 71) 212-60-03</a>
              </div>
              <div>
                <a href="tel:+998712562532">(+998 71) 256-25-32</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12} offset={12} className="footer-menu-col-container">
              <Text strong>E-mail:</Text>
              <div>
                <a href="mailto:reestr@garov.uz">reestr@garov.uz</a>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xxl={10}
          xl={10}
          lg={10}
          md={12}
          sm={24}
          xs={24}
          className="footer-menu-col"
        >
          <Divider />
          <Row>
            {menuList.map((obj) => (
              <Col
                key={obj.key + "FullMenu"}
                xxl={8}
                xl={8}
                lg={16}
                md={24}
                sm={24}
                xs={24}
              >
                <Link className="footer-menu-link" to={obj.url}>
                  {obj.title}
                </Link>
              </Col>
            ))}
          </Row>
        </Col>

        <Col
          xxl={6}
          xl={6}
          lg={6}
          md={24}
          sm={24}
          xs={24}
          className="footer-menu-col"
        >
          <Divider />
          <Row justify="center">
            <Col>
              <a href="https://my.gov.uz" target="_blank" rel="noreferrer">
                <img src={conf.img.analytics.mygovuz} alt="" />
              </a>
              <a href="https://data.gov.uz/rus/organizations/649" target="_blank" rel="noreferrer">
                <img src={conf.img.analytics.data_gov} alt="" />
              </a>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <a href="#">
                <img src={conf.img.analytics.pkm_oz} alt="" />
              </a>
              <a
                href="http://www.uz/ru/res/visitor/index?id=42013"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://cnt0.www.uz/counter/collect?id=42013&pg=http%3A//uzinfocom.uz&&col=0063AF&amp;t=ffffff&amp;p=E6850F"
                  alt=""
                  width="88"
                  height="31"
                  border="0"
                />
              </a>
            </Col>
          </Row>
          <Divider />
          <Row justify="center">
            <Col>
              <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                <img src={conf.img.social.facebook} alt="" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                <img src={conf.img.social.instagram} alt="" />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
                <img src={conf.img.social.youtube} alt="" />
              </a>
              <a href="https://t.me/garovuz" target="_blank" rel="noreferrer">
                <img src={conf.img.social.telegram} alt="" />
              </a>
              <a href="https://vk.com" target="_blank" rel="noreferrer">
                <img src={conf.img.social.vk} alt="" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default SiteFooter;
