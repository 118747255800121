import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Divider, Space, Typography, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { conf } from "../../../config";
import { postApi } from "../../../web/api";
import TextTinyMce from "../../templates/TextTinyMce";
import Trans from "../../Trans";
import DownloadContent from "../../templates/DownloadContent";
const { Text } = Typography;

const MenuStaticComponent = () => {
  const obj = { title: "", text: "", charged: "", docs: [], images: [] };
  const [content, setContent] = useState(obj);
  const [text, setText] = useState();
  let location = useLocation();
  useEffect(() => {
    getContent();
  }, [location]);

  const getContent = async () => {
    let res = await postApi(`main/content_static`, {
      menu: location.pathname,
    });
    if (res) {
      setContent(res ? res : obj);
      let get_text = await postApi(`main/content_static_text`, { id: res.id });
      setText(get_text)
    }
  };
  return (
    <>
      <h3>{content.title}</h3>
      <Row justify="end">
        <Col>
          <Space size="middle">
            <Trans alias={"Update date"} />
            <Text>{content.charged}</Text>
          </Space>
        </Col>
      </Row>
      <Divider />
      <TextTinyMce text={text} />
      {content?.docs?.map((doc) => (
        <React.Fragment key={doc.doc_id}>
          {doc.doc_type === 0 ? (
            <DownloadContent
              href={`${conf.server}main/content_static/doc_file?id=${doc.doc_id}&mime_type=${doc.mime_type}`}
              text={doc.doc_name}
            />
          ) : (
            <DownloadContent
              key={doc.doc_id}
              href={doc.doc_url}
              text={doc.doc_name}
              link={true}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default MenuStaticComponent;
