
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button } from "antd";
import { UserOutlined, UnorderedListOutlined, LogoutOutlined, } from "@ant-design/icons";
import { logOut, selectLogOut, access_, getUserId, } from "../../../redux/slices/AuthSlice";
import { getTitle } from "../../Trans";


const ClientForm = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLogOut);
  const access = useSelector(access_);
  const userId = useSelector(getUserId);
  const deleteSession = () => {
    dispatch(logOut());
  };
  return (
    <div onMouseLeave={props.showLogin}>
    <Form name="normal_login" className="login-form" lg={6} md={12} sm={24} xs={24}>
      {access.profile &&
        <Form.Item>
          <Link to={`/main/profile/user/${userId}`}>
            <Button type="ghost" block loading={loading} icon={<UserOutlined />}>
              {getTitle("Profile")}
            </Button>
          </Link>
        </Form.Item>
      }
      <Form.Item>
        <Link to="/main">
          <Button type="ghost" block loading={loading} icon={<UnorderedListOutlined />}>
            {getTitle("Personal cabinet")}
          </Button>
        </Link>
      </Form.Item>
      <Form.Item>
        <Button type="ghost" block loading={loading} onClick={() => deleteSession()} icon={<LogoutOutlined />}>
          {getTitle("Logout")}
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
};

export default ClientForm;
