import ModalComponent from './ModalComponent';
import Trans, { getTitle } from '../Trans';
import { Buffer } from 'buffer';
import FileSaver from 'file-saver';
import ApiHooks from '../../hooks/ApiHooks';


const GetBanPdf = (props) => {
    const hookApi = ApiHooks();

    const pdf = async () => {
        props.onCancel(false)
        let result = await hookApi(props.url, {id:props.id}, 'order');
        if (result) {
            let buff = Buffer.from(result, 'base64');
            let blob = new Blob([buff], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            FileSaver.saveAs(blob, `${props.titleId}.pdf`);
            const newWindow = window.open();
            newWindow.location.href = url;
        }
    };
    
    return (
        <ModalComponent
            title={<Trans alias={props.title} />}
            open={props.modal}
            data={getTitle(props.text) + ' ' + props.titleId + '?'}
            onOk={() => pdf()}
            loading={props.loading}
            onCancel={() => props.onCancel(false)}
            cancelTitle={<Trans alias={"No"} />}
            okTitle={<Trans alias={"Yes"} />}
        />
    )
};
export default GetBanPdf;






