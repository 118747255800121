import { Row, Col, Steps, Divider } from 'antd';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FormDebitor from './FormCreditorDebitor';
import FormProperty from './FormProperty';
import FormPledge from './FormPledge';
import FormSave from './FormSave';
import FormSuccess from './FormSuccess';
import moment from 'moment';
import { date_format, year_format } from '../../../../templates/Patterns';
import { getJsonResult } from './components/JsonResult';
import ApiHooks from '../../../../../hooks/ApiHooks';
import { loadingState } from '../../../../../redux/slices/LoadingSlice';
import { REFERS } from '../../../../constants/refers';
import { postApi } from '../../../../../web/api';
import Trans, { getTitle } from '../../../../Trans';
const { Step } = Steps;

const FormSteps = (props) => {

    const [current, setCurrent] = useState(0);
    const hookApi = ApiHooks();
    const loading = (useSelector(loadingState));
    const [data, setData] = useState({ pledge: {}, creditor: {}, debtor: {}, object: {} });
    const [selectLabel, setSelectLabel] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [successForm, setSuccessForm] = useState(false);
    const [stateDebtor, setStateDebtor] = useState('wait');
    const [stateCreditor, setStateCreditor] = useState('wait');
    const [stateProperty, setStateProperty] = useState('wait');
    const [statePledge, setStatePledge] = useState('wait');
    const [paymentId, setPaymentId] = useState();
    const [dec_code, setDec_code] = useState();

    const { id } = useParams();

    useEffect(() => {
        if (id)
            getNameRefers();
    }, [id]);

    const getNameRefers = async () => {
        let obj_ = props.dataEdit;
        Object.keys(obj_).forEach(async(key) => {
            Object.entries(obj_[key]).forEach(async([key, val], index) => {
                await getRefersSelect(key, val);
            });
        });
    };

    const getRef = async (refer, pRefGroup) => {
        let data = await postApi('refers/getRefers', { refer, pRefGroup });
        data = JSON.parse(data);
        return data;
    };

    const getRefersSelect = async(name, val) => {
        let refer = null;
        let pRefGroup = null;
        if (name === 'subject_type') {
            refer = REFERS.conREF_SUBJECTS
        }
        else if (name === 'resident') {
            refer = REFERS.conREF_RESIDENT
        }
        else if (name === 'client_type') {
            refer = REFERS.conREF_DEC_CLIENT_TYPES
        }
        else if (name === 'right_type') {
            refer = REFERS.conREF_RIGHT_TYPES
        }
        else if (name === 'doc_type') {
            if (props.client_type === '3') {
                refer = REFERS.conREF_DEC_TYPES_4ORGANS
            }
            else {
                refer = REFERS.conREF_DEC_TYPES
            }
        }
        else if (name === 'org_form') {
            refer = REFERS.conREF_ORGANIZATION_FORMS
        }
        else if (name === 'country') {
            refer = REFERS.conREF_COUNTRY
        }
        else if (name === 'education') {
            refer = REFERS.conREF_EDUCATIONS
        }
        else if (name === 'region') {
            refer = REFERS.conREF_REGIONS
        }
        else if (name === 'loc_region') {
            refer = REFERS.conREF_LOCAL_REGIONS
        }
        else if (name === 'branch') {
            refer = REFERS.conREF_BRANCH
        }
        else if (name === 'oked') {
            refer = REFERS.conREF_OKED
        }
        else if (name === 'sex') {
            refer = REFERS.conREF_SEX
        }
        else if (name === 'age') {
            refer = REFERS.conREF_AGES
        }
        else if (name === 'small_business') {
            refer = REFERS.conREF_SMALL_BUSINESS
        }
        else if (name === 'provision_group') {
            refer = REFERS.conREF_PROVISION_TYPES_GROUPS
        }
        else if (name === 'provision_type') {
            refer = REFERS.conREF_PROVISION_TYPES
        }
        else if (name === 'accepted') {
            let obj = {}
            obj.accepted = <Trans alias={val === '1' ? 'Yes' : 'No'} />;
            await handleSelectLabel(obj)
        }
        if (refer) {
            /*getRef(refer, pRefGroup).then(
                result => {
                    result.forEach(async (element) => {
                        if (element.code === val) {
                            let obj = {}
                            obj[name] = element.name;
                            await handleSelectLabel(obj)
                        }
                    })
                })*/
                const result = await getRef(refer, pRefGroup);
                if (result)
                result.forEach(async (element) => {
                    if (element.code === val) {
                        let obj = {}
                        obj[name] = element.name;
                        await handleSelectLabel(obj)
                    }
                })
        }
    };

    const getFormAttr = async (value) => {
        let obj = {};
        if (value === 0) {
            obj.form = props.pledge
            obj.set = setStatePledge
        }
        else if (value === 1) {
            obj.form = props.debtor
            obj.set = setStateDebtor
        }
        else if (value === 2) {
            obj.form = props.creditor
            obj.set = setStateCreditor
        }
        else if (value === 3) {
            obj.form = props.property
            obj.set = setStateProperty
        }
        return obj;
    };

    const checkValidation = async (value) => {
        let attr = await getFormAttr(value);
        try {
            await attr.form.validateFields();
            attr.set('finish')
            return true

        } catch (errors) {
            attr.set('error')
            return false
        }
    };

    const onChange = async (value) => {
        if (value === 0) {
            setCurrent(value);
        }
        else if (value === 1) {
            props.pledge.submit();
            if (await checkValidation(0)) {
                setCurrent(value);
                props.pledge.submit();
            }
        }
        else if (value === 2) {
            props.pledge.submit();
            if (await checkValidation(0)) {
                props.debtor.submit();
                if (await checkValidation(1)) {
                    setCurrent(value);
                }
            }
        }
        else if (value === 3) {
            props.pledge.submit();
            if (await checkValidation(0)) {
                props.debtor.submit();
                if (await checkValidation(1)) {
                    props.creditor.submit();
                    if (await checkValidation(2)) {
                        setCurrent(value);
                    }
                }
            }
        }
        else if (value === 4) {
            props.pledge.submit();
            if (await checkValidation(0)) {
                props.debtor.submit();
                if (await checkValidation(1)) {
                    props.creditor.submit();
                    if (await checkValidation(2)) {
                        props.property.submit();
                        if (await checkValidation(3)) {
                            props.pledge.submit();
                            props.debtor.submit();
                            props.creditor.submit();
                            props.property.submit();
                            setCurrent(value);
                        }
                    }
                }
            }
        }
    };

    const handlePledge = async (values) => {
        values.client_type = props.client_type
        if (!id) {
            handleSelectLabel({ client_type: props.client_type_name })
        }
        if (values.right_date)
            values.right_date = moment(values.right_date).format(date_format);
        if (values.expired)
            values.expired = moment(values.expired).format(date_format);
        if (values.doc_d)
            values.doc_d = moment(values.doc_d).format(date_format);
        setData((prevState) => ({
            ...prevState,
            pledge: values
        }));
    };

    const handleDebitor = async (values) => {
        if (values.birth_date)
            values.birth_date = moment(values.birth_date).format(date_format);
        setData((prevState) => ({
            ...prevState,
            debtor: values
        }));
    };

    const handleCreditor = async (values) => {
        if (values.birth_date)
            values.birth_date = moment(values.birth_date).format(date_format);
        setData((prevState) => ({
            ...prevState,
            creditor: values
        }));
    };

    const handleProperty = async (values) => {
        if (values.element_value) { values.element_value = values.element_value.replaceAll("_", "") }
        if (values.element_year) { values.element_year = moment(values.element_year).format(year_format); }
        setData((prevState) => ({
            ...prevState,
            object: values
        }));
    };

    const handleSelectLabel = async (values) => {
        setSelectLabel((prevState) => ({
            ...prevState,
            ...values
        }))
    };

    const handleSave = async (values) => {
        setDisabled(true)
        values.code = id;
        if (values.client_type === '1' || values.client_type === '2') {
            values.type = values.client_type;
        }
        let jsonObj = await getJsonResult(values);
        let result = await hookApi('record/setDeclaration', { jsonObj }, true);
        if (result?.code === 0) {
            if (result.data.payment_id) {
                setPaymentId(result.data.payment_id);
            }
            if (result.data.code) {
                setDec_code(result.data.code);
            }
            setSuccessForm(true);
        }
        setDisabled(false);
    };

    const steps = [
        {
            description: getTitle('Collateral Information'),
            status: statePledge,
            disabled: disabled
        },
        {
            description: getTitle('Information about the debtor (about the owner of the property)'),
            status: stateDebtor,
            disabled: disabled
        },
        {
            description: getTitle('Information about the lender'),
            status: stateCreditor,
            disabled: disabled
        },
        {
            description: getTitle('Description of the property'),
            status: stateProperty,
            disabled: disabled
        },
        {
            description: getTitle('Preview and Save'),
            disabled: disabled,
        }
    ];

    return (
        <>{!successForm &&
            <Row>
                <Col span={24}>
                    <Steps current={current} type="navigation" onChange={onChange} className="site-navigation-steps" size="small">
                        {steps.map(item => (
                            <Step status={item.status} key={item.title + 1} title={item.title} description={item.description} disabled={disabled} />
                        ))}
                    </Steps>
                </Col>
                <Col span={24}>
                    <Divider />
                    <FormPledge style={{ display: current === 0 ? 'block' : 'none' }} form={props.pledge} onFinish={handlePledge} name='pledge' client_type={props.client_type} selectLabel={handleSelectLabel} />
                    <FormDebitor style={{ display: current === 1 ? 'block' : 'none' }} form={props.debtor} onFinish={handleDebitor} name='debitor' client_type={props.client_type} debtor={true} selectLabel={handleSelectLabel} loc_region={props.loc_region} dataEdit={props.dataEdit} />
                    <FormDebitor style={{ display: current === 2 ? 'block' : 'none' }} form={props.creditor} onFinish={handleCreditor} name='creditor' client_type={props.client_type} debtor={false} selectLabel={handleSelectLabel} />
                    <FormProperty style={{ display: current === 3 ? 'block' : 'none' }} form={props.property} onFinish={handleProperty} name='property' client_type={props.client_type} selectLabel={handleSelectLabel} />
                    {current === 4 && <FormSave data={data} selectLabel={selectLabel} handleSave={handleSave} loading={loading} />}
                </Col>
            </Row>}
            {successForm && <FormSuccess paymentId={paymentId} dec_code={dec_code} />}
        </>
    );
}
export default FormSteps;