import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { getTitle } from '../../../../../Trans';
import MonitoringTable from '../../../../../templates/MonitoringTable';
import HighChartComponent from '../../../../../templates/HightChartComponent';
import TableSummaryComponent from './table-summary-component';

const StatisticsCountByType = (props) => {
  const titleCount = getTitle('Count');
  const titleName = getTitle('name');
  const { statistics, setColumns, loading, type, setSummaryTitleSize } = props;
  const pageSize = 15;
  let currentPage = 1;
  const titleSize = 2;

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      width: 35,
      render: (text, record, index) =>
        (currentPage - 1) * pageSize + (index + 1),
      align: 'center',
    },
    {
      title: titleName,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: titleCount,
      dataIndex: 'count',
      key: 'count',
      align: 'center',
    },
  ];

  useEffect(() => {
    setSummaryTitleSize(titleSize);
    setColumns(columns);
  }, []);

  const summaryData = [
    { value: statistics?.total?.dec_count || statistics?.total?.count },
  ];
  const pieData = statistics?.data?.map((value) => ({
    name: value.name,
    y: value.count,
  }));

  return (
    <Row gutter={[10, 10]}>
      <Col lg={10} xs={24}>
        <MonitoringTable
          size={type === '12' || type === '13' ? 'small' : 'middle'}
          columns={columns}
          dataSource={statistics?.data}
          loading={loading}
          pagination={{
            defaultPageSize: pageSize,
            onChange: (page) => {
              currentPage = page;
            },
          }}
          summary={() => (
            <TableSummaryComponent
              titleColSpan={titleSize}
              summaryData={summaryData}
            />
          )}
          bordered={true}
          rowKey={(record) => record.order}
        />
      </Col>
      <Col lg={14} xs={24}>
        <HighChartComponent
          type="pie"
          title={titleCount}
          series={[{ name: '', data: pieData }]}
        />
      </Col>
    </Row>
  );
};
export default StatisticsCountByType;
