import ModalComponent from './ModalComponent';
import { useState } from 'react';
import Trans, { getTitle } from '../Trans';
import { Buffer } from 'buffer';
import FileSaver from 'file-saver';
import ApiHooks from '../../hooks/ApiHooks';
import { clientRoles } from '../constants/priveleges';

const GetOrder = (props) => {
    const hookApi = ApiHooks();

    const order = async () => {
        props.onCancel(false)
        let result = await hookApi("record/getOrder", { code: props.record_code }, 'order');
        if (result) {
            let buff = Buffer.from(result, 'base64');
            let blob = new Blob([buff], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            const file_name =(Math.random() + 1).toString(36).substring(2);
            FileSaver.saveAs(blob, `${file_name}.pdf`);
            const newWindow = window.open();
            newWindow.location.href = url;
        }
    };

    return (
        <ModalComponent
            title={<Trans alias={"Getting an extract"} />}
            open={props.modal}
            data={getTitle("Do you want to generate a statement") + '?'}
            onOk={props.client !== clientRoles.physical ? () => order() : ()=>props.onPayment()}
            loading={props.loading}
            onCancel={() => props.onCancel(false)}
            cancelTitle={<Trans alias={"No"} />}
            okTitle={<Trans alias={"Yes"} />}
        />
    )
};
export default GetOrder;






