import { useState } from 'react';
import GetInput from './FormInput';
import { pattern } from './Patterns';


const CadastreInput = (props) => {

    const [value, setValue] = useState('');
    const [type, setType] = useState(true);
    const [event, setEvent] = useState(false);
    const [cadastrePattern, setCadastrePattern] = useState('');

    const onChange = (e) => {
        if (e.type === 'change' && event !== 'paste') {
            setCadastrePattern(pattern.cadastre_mask);
            setEvent('change');
            setType(true);
            setValue(e.target.value);
        }
        if (e.type === 'change' && event === 'paste') {
            setCadastrePattern(pattern.cadastre);
            setEvent('change');
            setType(false);
            setValue(e.target.value);
        }
        if (e.type === 'paste') {
            setCadastrePattern(pattern.cadastre_mask);
            setEvent('paste');
            setType(false);
            setValue(e.target.value);
        }
    };

    return (<>
        <GetInput disabled={props.disabled} value={value} name={props.name} text={props.text} required={true} />
        {/* {type ? <GetInput disabled={props.disabled} value={value} name={props.name} text={props.text} required={true} pattern={pattern.cadastre_mask} mask={true} onChange={(e) => onChange(e)} />
            : <GetInput disabled={props.disabled} value={value} name={props.name} text={props.text} required={true} pattern={cadastrePattern} mask={false} onChange={(e) => onChange(e)} />} */}
    </>
    )
}
export default CadastreInput;