import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs, Form } from "antd";
import moment from "moment";

import { date_format } from "../../../../../templates/Patterns";
import Trans, { getTitle } from "../../../../../Trans";
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";
import { loadingState } from "../../../../../../redux/slices/LoadingSlice";
import ApiHooks from "../../../../../../hooks/ApiHooks";

export const UserProfileForm = ({ id, handleSubmitProfile, handleSubmitPassword }) => {
  const [profile] = Form.useForm();
  const [password] = Form.useForm();
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  const getUserProfile = async () => {
    const user = await hookApi("user/getUserProfile", { data: { user: id } });
    if (user) {
      if (user.data.birth_date)
        user.data.birth_date = moment(user.data.birth_date, date_format);
      else user.data.birth_date = moment();
      profile.setFieldsValue(user.data);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);



  const tabItems = [
    {
      key: "1",
      label: getTitle("Changing user data"),
      children: (
        <>
          <h1>
            <Trans alias={"Changing user data"} />
          </h1>
          <ProfileForm
            form={profile}
            name="profile"
            onFinish={handleSubmitProfile}
            disabled={loading}
          />
        </>
      ),
    },
    {
      key: "2",
      label: getTitle("Changing the user's password"),
      children: (
        <>
          <h1>
            <Trans alias={"Changing the user's password"} />
          </h1>
          <PasswordForm
            form={password}
            name="password"
            onFinish={handleSubmitPassword}
            disabled={loading}
          />
        </>
      ),
    },
  ];

  return (
    <Tabs
      tabPosition="left"
      defaultActiveKey="1"
      size="large"
      tabBarGutter={25}
      items={tabItems}
      style={{
        background: "#fefefe",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "500px",
      }}
    />
  );
};
