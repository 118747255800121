import { Row, Col, Table, Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import Trans, { getTitle } from '../../../../Trans';
import Confirm from '../../../../templates/Confirm';
import './style/style.css'
import { postApi } from '../../../../../web/api';
import { REFERS } from '../../../../constants/refers'
const FormSave = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [props.data]);

    const getDataSelect = (name) => {
        let result = null;
        Object.entries(props.selectLabel).forEach(([key, val]) => {
            if (name === key) {
                result = val
            };
        });
        return result;
    };

    const getRefValue = async (refer, val) => {
        let data = await postApi('refers/getRefers', { refer });
        data = JSON.parse(data);
        data = data?.filter((value) => (value.code === val));
        return data[0].name;
    }

    const getData = async () => {
        let obj_ = props.data;
        delete obj_.code;
        obj_.creditor_name = {};
        obj_.debtor_name = {};
        let arr = [];
        if (obj_.creditor) {
            if (obj_.creditor.subject_type === '1' || obj_.creditor.subject_type === '2' || obj_.creditor.subject_type === '3') {
                if (obj_.creditor.subject_type) {
                    obj_.creditor_name.subject_type_name = await getRefValue(REFERS.conREF_SUBJECTS, obj_.creditor.subject_type);
                }
                if (obj_.creditor.resident) {
                    obj_.creditor_name.resident_name = await getRefValue(REFERS.conREF_RESIDENT, obj_.creditor.resident);
                }
                if (obj_.creditor.org_form) {
                    obj_.creditor_name.org_form_name = await getRefValue(REFERS.conREF_ORGANIZATION_FORMS, obj_.creditor.org_form);
                }
                if (obj_.creditor.country) {
                    obj_.creditor_name.country_name = await getRefValue(REFERS.conREF_COUNTRY, obj_.creditor.country);
                }
            }
        }
        if (obj_.debtor) {
            if (obj_.debtor.subject_type === '1' || obj_.debtor.subject_type === '2' || obj_.debtor.subject_type === '3') {
                if (obj_.debtor.subject_type) {
                    obj_.debtor_name.subject_type_name = await getRefValue(REFERS.conREF_SUBJECTS, obj_.debtor.subject_type);
                }
                if (obj_.debtor.resident) {
                    obj_.debtor_name.resident_name = await getRefValue(REFERS.conREF_RESIDENT, obj_.debtor.resident);
                }
                if (obj_.debtor.org_form) {
                    obj_.debtor_name.org_form_name = await getRefValue(REFERS.conREF_ORGANIZATION_FORMS, obj_.debtor.org_form);
                }
                if (obj_.debtor.country) {
                    obj_.debtor_name.country_name = await getRefValue(REFERS.conREF_COUNTRY, obj_.debtor.country);
                }
            }
        }
        Object.keys(obj_).forEach(async (key) => {
            let obj = {};
            if (key === 'creditor' || key === 'debtor' || key === 'pledge' || key === 'object') {
                obj.key = key;
                obj.name = <Trans alias={key} />
                arr.push(obj);
            }
            if (key === 'creditor') {
                Object.entries(obj_[key]).forEach(async ([key, val], index) => {
                    let objChild = {};
                    if (key === 'subject_type') {
                        val = obj_.creditor_name.subject_type_name;
                    }
                    if (key === 'resident') {
                        val = obj_.creditor_name.resident_name;
                    }
                    if (key === 'org_form') {
                        val = obj_.creditor_name.org_form_name;
                    }
                    if (key === 'country') {
                        val = obj_.creditor_name.country_name;
                    }
                    objChild.key = key + index + Math.random();
                    objChild.name = <Trans alias={key} />
                    objChild.value = val;
                    arr.push(objChild);
                });
            }
            if (key === 'debtor') {
                Object.entries(obj_[key]).forEach(async ([key, val], index) => {
                    let objChild = {};
                    let selectVal = null;
                    if (key === 'subject_type') {
                        selectVal = obj_.debtor_name.subject_type_name;
                    }
                    else if (key === 'resident') {
                        selectVal = obj_.debtor_name.resident_name;
                    }
                    else if (key === 'org_form') {
                        selectVal = obj_.debtor_name.org_form_name;
                    }
                    else if (key === 'country') {
                        selectVal = obj_.debtor_name.country_name;
                    }
                    else {
                        selectVal = getDataSelect(key);
                    }
                    objChild.key = key + index + Math.random();
                    objChild.name = <Trans alias={key} />
                    objChild.value = selectVal ? selectVal : val;
                    arr.push(objChild);
                });
            }
            if (key === 'pledge' || key === 'object') {
                Object.entries(obj_[key]).forEach(async ([key, val], index) => {
                    let valSelect = getDataSelect(key);
                    let objChild = {};
                    objChild.key = key + index + Math.random();
                    if (key === 'element_value') {
                        if (obj_.object.provision_type === '101' || obj_.object.provision_type === '102' || obj_.object.provision_type === '706') {
                            key = 'element_cadastre'
                        }
                        if (obj_.object.provision_type === '201' || obj_.object.provision_type === '202' || obj_.object.provision_type === '203' || obj_.object.provision_type === '204' || obj_.object.provision_type === '205' || obj_.object.provision_type === '206') {
                            key = 'element_car'
                        }
                        if (obj_.object.provision_type === '701' || obj_.object.provision_type === '702') {
                            key = 'tech_num'
                        }
                    }
                    objChild.name = <Trans alias={key} />
                    objChild.value = valSelect ? valSelect : val;
                    arr.push(objChild);
                });
            }
        });
        delete obj_.creditor_name;
        delete obj_.debtor_name;
        setData(arr)
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            onCell: (_, index) => {
                if (_.key === 'creditor' || _.key === 'debtor' || _.key === 'object' || _.key === 'pledge') {
                    return {
                        ['style']: { background: '#fafafa', fontWeight: 'bold' },
                        colSpan: 2
                    }
                }
            },
        },
        {
            dataIndex: 'value',
            key: 'value',
            onCell: (_, index) => {
                if (_.key === 'creditor' || _.key === 'debtor' || _.key === 'object' || _.key === 'pledge') {
                    return { colSpan: 0 }
                }
            },
        }
    ];

    return (
        <>
            <Row justify='center' gutter={[5, 5]}>
                <Col xxl={18} xl={18} lg={18} md={24} sm={24} xs={24}>
                    <Row justify='end'>
                        <Col>
                            <Space>
                                <Confirm func={() => props.handleSave(props.data)} title={'Do you really want to save?'} btnText='Save' btnType="primary" btnDisabled={props.loading} />
                                <Button onClick={() => window.location.reload()} loading={props.loading}>{getTitle('Cancel')}</Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={18} xl={18} lg={18} md={24} sm={24} xs={24}>
                    <Table size='small' columns={columns} dataSource={data} pagination={false} bordered showHeader={false} />
                </Col>
            </Row>
        </>
    )
};
export default FormSave;