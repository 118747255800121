import axios from "axios";
import { conf } from "../config";
import { removeUserSession } from "../web/cookies";
import { ErrorsApi } from "../web/errors";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/LoadingSlice";
import { refresh } from "../redux/slices/AuthSlice";
import Cookies from "js-cookie";
const lang = localStorage.getItem("lang");

const defaultHeader = { "Content-Type": "application/json" };

const tokenConfig = () => {
  const token = Cookies.get("token");
  const config = {
    headers: {
      token: token,
    },
  };
  return config;
};

const ApiHooks = () => {
  const dispatch = useDispatch();

  const baseQuery = async (url, obj, modal, header) => {
    const api = axios.create({
      withCredentials: true,
      baseURL: conf.server,
      timeout: 3000 * 1000,
      headers: header || defaultHeader,
    });
    try {
      dispatch(setLoading(true));
      if (obj instanceof FormData) obj.append("lang", lang);
      else obj.lang = lang;
      const result = await api.post(url, obj, tokenConfig());
      dispatch(setLoading(false));
      if (result.data.code === 0) {
        if (modal) ErrorsApi(result.data.msg, "success");
        return result.data;
      } else {
        if (result.data && modal === "order") {
          if (result.data.code) ErrorsApi(result.data.msg, "alert");
          else return result.data;
        } else {
          ErrorsApi(result.data.msg, "alert");
          return result.data;
        }
      }
    } catch (err) {
      if (err.response.status === 401) {
        removeUserSession();
        ErrorsApi(err.code ? err.code : err.data.msg, "alert");
        window.location.reload();
      } else {
        if (err.response.status === 403 && err.response.data.msg === "jwt expired") {
          let refreshToken = await dispatch(refresh());
          if (refreshToken.payload.code === 0) {
            try {
              let result = await api.post(url, obj, tokenConfig());
              dispatch(setLoading(false));
              if (result.data.code === 0) {
                if (modal) ErrorsApi(result.data.msg, "success");
                return result.data;
              } else {
                ErrorsApi(err.code ? err.code : err.data.msg, "alert");
                return result.data;
              }
            } catch (err) {
              ErrorsApi(err.code ? err.code : err.data.msg, "alert");
            }
          } else {
            window.location.reload();
          }
        }
      }
      dispatch(setLoading(false));
      ErrorsApi(err);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return baseQuery;
};

export default ApiHooks;
