import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider, Form, Table, Button, Badge, Space ,Pagination} from "antd";
import { SearchOutlined, FileImageOutlined } from "@ant-design/icons";
import moment from "moment";
import { Buffer } from 'buffer';
import FileSaver from 'file-saver';
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import FilterFormModal from "./components/FilterFormModal";
import { access_ } from "../../../../redux/slices/AuthSlice";
import DrawerComponent from "../../../templates/DrawerComponent";
import Trans, { getTitle } from "../../../Trans";
import { date_format } from "../../../templates/Patterns";
import ViewData from "./components/ViewData";

const PoliceForm = () => {
  const pageSize = 20;
  const defaultFilterForm = { bdate: moment(), edate: moment() };
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    page: 1,
    page_size: pageSize,
  });
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [modalJson, setModalJson] = useState(false);
  const [data, setData] = useState([]);
  const [dataDetails, setDataDetails] = useState([]);
  const loading = useSelector(loadingState);
  const access = useSelector(access_);
  const hookApi = ApiHooks();

  useEffect(() => {
    getData();
  }, [sendObj]);

  const base64toPDF = async (value, name) => {
    let buff = Buffer.from(value, 'base64');
    let blob = new Blob([buff], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    FileSaver.saveAs(blob, `${name}.pdf`);
    const newWindow = window.open();
    newWindow.location.href = url;
  }

  const loadContent = async (values) => {
    let data = {};
    data.tech_s = values.tech_s ? values.tech_s : null;
    data.tech_n = values.tech_n ? values.tech_n : null;
    data.state_num = values.state_num ? values.state_num : null;
    data.pin = values.pin ? values.pin : null;
    data.vin_num = values.vin_num ? values.vin_num : null;
    let result = await hookApi("record/getPolice", { data }, 'order');
    if (result) {
      let name = values.vin_num ? values.vin_num : values.tech_s + '_' + values.tech_n;
      await base64toPDF(result, name);
      setModal(false);
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    let data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.dec_code = values.dec_code;
    data.ban_number = values.ban_number;
    data.ban_state = values.ban_state;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };


  const getData = async () => {
    let result = await hookApi("record/getPoliceList", { data: sendObj });
    if (result.data) {
      setData(result.data.data);
    };
  };

  const getPdf = async (log_id) => {
    const result = await hookApi("record/getPolicePdf", { log_id }, 'order');
    if (result) {
      await base64toPDF(result, log_id)
    }
  }

  const getJson = async (log_id) => {
    const result = await hookApi("record/getPoliceJson", { log_id });
    if (result) {
      const dataTable = <ViewData request={JSON.stringify(result.data.request)} response={JSON.stringify(result.data.response)} />;
      setDataDetails(dataTable);
      setModalJson(true);
    }
  }

  const columns = [
    {
      title: getTitle('Log ID'),
      dataIndex: "wslog_id",
      key: "wslog_id",
      align: 'center',
      width: '5%',
    },
    {
      title: getTitle('name'),
      dataIndex: "client_name",
      key: "client_name",
      width: '10%',
      hidden: (!access.record_list_super)
    },
    {
      title: getTitle('S.F.P'),
      dataIndex: "user_fio",
      key: "user_fio",
      width: '15%',
    },
    {
      title: getTitle('Username'),
      dataIndex: "username",
      key: "username",
      width: '15%',
    },
    {
      title: getTitle('Begin date'),
      dataIndex: "init_time",
      key: "init_time",
      align: 'center',
      width: '10%',
    },
    {
      title: getTitle('Error text'),
      dataIndex: "err_msg",
      key: "err_msg",
      align: 'center',
      width: '10%',
      hidden: (!access.record_list_super)
    },
    {
      title: getTitle('Error text'),
      dataIndex: "res_note",
      key: "res_note",
      align: 'center',
      width: '10%',
    },
    {
      title: getTitle('Request source'),
      dataIndex: "source_name",
      key: "source_name",
      align: 'center',
      width: '10%',
    },
    {
      title: getTitle('State'),
      dataIndex: "state_name",
      key: "state_name",
      align: 'center',
      width: '10%',
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{
            backgroundColor: `${record.state === 1 ? "#52c41a" : "#f5222d"}`,
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "pdf",
      key: "pdf",
      width: '1%',
      fixed: 'right',
      render: (_, record) => (
        <>
          <Space>
            <Button
              disabled={false}
              title="Детали запроса"
              type="primary"
              shape="circle"
              icon={<SearchOutlined />}
              onClick={() => getJson(record.wslog_id)}
            />
            {record.get_pdf &&
              <Button
                title="PDF"
                disabled={false}
                type="danger"
                shape="circle"
                icon={<FileImageOutlined />}
                onClick={() => getPdf(record.wslog_id)}
              />}
          </Space>
        </>
      )
    },
  ].filter(item => !item.hidden);;

  return (
    <>
      <FilterForm
        form={form}
        disabled={loading}
        role={{list:access.record_list_super,search:access.police_search}}
        initialValues={defaultFilterForm}
        modal={() => setModal(true)}
        onFinish={handleFilterForm}
      />
      <Divider />
      <Table size="small" dataSource={data} columns={columns} loading={loading} rowKey={(record) => record.wslog_id} />
      <DrawerComponent
        open={modal}
        title={<Trans alias={'Receiving information from the system MVD'} />}
        onCancel={!loading ? () => setModal(false) : null}
        width={'40%'}
        data={<FilterFormModal form={modalForm} onFinish={loadContent} disabled={loading} />}
      />
      <DrawerComponent
        open={modalJson}
        onCancel={() => setModalJson(false)}
        title={<Trans alias={'Receiving information from the system MVD'} />}
        width={'60%'}
        data={dataDetails}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default PoliceForm;
