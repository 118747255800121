import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMceEditor = (props) => {
  
  return (
    <Editor
      menubar={true}
      statusbar={true}
      inline={false}
      //   readonly:1,
      height={450}
      onEditorChange={props.onEditorChange}
      initialValue={props.initialValue}
      plugins={["wordcount"]}
    />
  );
};

export default TinyMceEditor;
