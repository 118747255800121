import React from "react";
import { Tooltip, Button } from "antd";
import {
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
  StopOutlined,
} from "@ant-design/icons";
import MonitoringTable from "../../../../templates/MonitoringTable";
import { getTitle } from "../../../../Trans";

const DynamicList = (props) => {
  const contentStateDraft = 0;
  const contentStatePublished = 1;
  const contentStateRemoved = 2;
  const Edit = getTitle("Edit");
  const Draft = getTitle("Draft");
  const Publish = getTitle("Publish");
  const Delete = getTitle("Delete");

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 10,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      width: 100,
    },
    {
      title: "Created_user_fio",
      dataIndex: "created_user_fio",
      key: "created_user_fio",
      width: 150,
    },
    // {
    //   title: "Changed",
    //   dataIndex: "changed",
    //   key: "changed",
    //   width: 100,
    // },
    // {
    //   title: "Changed_user_fio",
    //   dataIndex: "changed_user_fio",
    //   key: "changed_user_fio",
    //   width: 150,
    // },
    {
      title: getTitle("Text in Russian"),
      dataIndex: "title_ru",
      key: "title_ru",
      width: 300,
    },
    {
      title: getTitle("Text in Uzbek"),
      dataIndex: "title_uz",
      key: "title_uz",
      width: 300,
    },
    {
      title: getTitle("Text in Uzbek (latin)"),
      dataIndex: "title_oz",
      key: "title_oz",
      width: 300,
    },
    {
      title: getTitle("Text in English"),
      dataIndex: "title_en",
      key: "title_en",
      width: 300,
    },
    {
      title: "State_name",
      dataIndex: "state_name",
      key: "state_name",
      width: 100,
    },
    {
      title: "",
      key: "buttons",
      align: "center",
      render: (_, content) => (
        <>
          <Tooltip title={Edit}>
            <Button
              disabled={props.loading}
              type="link"
              icon={<EditOutlined />}
              onClick={() => props.open(content.id)}
            />
          </Tooltip>
          {content.state === contentStateDraft && (
            <Tooltip title={Publish}>
              <Button
                disabled={props.loading}
                type="link"
                danger
                icon={<CheckOutlined />}
                onClick={() =>
                  props.changeState(content.key, contentStatePublished)
                }
              />
            </Tooltip>
          )}
          {content.state === contentStatePublished && (
            <Tooltip title={Delete}>
              <Button
                disabled={props.loading}
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() =>
                  props.changeState(content.key, contentStateRemoved)
                }
              />
            </Tooltip>
          )}
          {content.state === contentStateRemoved && (
            <Tooltip title={Draft}>
              <Button
                disabled={props.loading}
                type="link"
                danger
                icon={<StopOutlined />}
                onClick={() =>
                  props.changeState(content.key, contentStateDraft)
                }
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <MonitoringTable
      columns={columns}
      dataSource={props.dataSource}
      loading={props.loading}
      pagination={true}
      bordered={true}
      size={"small"}
    />
  );
};

export default DynamicList;
