import React from 'react';
import { Form, Row, Col, DatePicker, Button } from 'antd';
import Trans, { getTitle } from '../../../../Trans';
import GetInput from '../../../../templates/FormInput';
import { date_format, pattern } from '../../../../templates/Patterns';
import GetSelectSource from '../../../../templates/FormSelectSource';
import { REFERS } from '../../../../constants/refers';

const FilterForm = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: 'filterForm',
    layout: 'vertical',
    className: 'form-back',
    initialValues: props.initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
          <Form.Item label={getTitle('Begin date')} name="bdate">
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>
        <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
          <Form.Item label={getTitle('End date')} name="edate">
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput pattern={pattern.client_code} name={'code'} text={'Code'} />
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetSelectSource
            name={'source'}
            refer={REFERS.conREF_SOURCES}
            text={'Recording source'}
          />
        </Col>
        {props.role.record_list_super && (
          <>
            <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
              <GetInput name={'client_name'} text={'User'} />
            </Col>
          </>
        )}
        {(props.role.record_list_super || props.role.record_list_admin) && (
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput
              name={'user_login'}
              pattern={pattern.login}
              text={'Username'}
            />
          </Col>
        )}
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput
            name={'debtor_inn_pin'}
            text={'Inn/Pin debtor'}
            pattern={pattern.number}
            maxLength={14}
          />
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput name={'debtor_name'} text={'Debtor name'} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={'Search'} />
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              props.form.resetFields();
              props.form.submit();
              props.setDisExcel();
            }}
          >
            <Trans alias={'Reset'} />
          </Button>
          {/* <ExportExcel sendObj={props.sendObj} disabled={props.disExcel} url={'export/getDeclaration'} fileName = {`${props.sendObj.bdate}-${props.sendObj.edate}`}/> */}
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
