import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Pagination, Divider, Button } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import ViewData from "./components/ViewData";
import Trans, { getTitle } from "../../../Trans";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../templates/MonitoringTable";
import moment from "moment";
import { date_format } from "../../../templates/Patterns";
import DrawerComponent from "../../../templates/DrawerComponent";

const PaymentsLog = () => {
  const pageSize = 10;
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
  };
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataDetail, setDataDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    log_id: null,
    page: 1,
    page_size: pageSize,
  });
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    const result = await hookApi("site/payments/getLogs", {
      data: sendObj,
    });
    if (result.data) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    const data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.log_id = values.log_id ? values.log_id * 1 : null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const info = async (id) => {
    const result = await hookApi("site/payments/getLog", {
      log_id: id,
    });
    const dataTable = (
      <ViewData request={result.data.request} response={result.data.response} />
    );
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const columns = [
    {
      title: getTitle("Log ID"),
      dataIndex: "key",
      key: "key",
      align:'center',
      width: '5%',
    },
    {
      title: getTitle("Payment system"),
      dataIndex: "center_name",
      key: "center_name",
      width: '20%',
    },
    {
      title: "Дата и время начала",
      dataIndex: "b_time",
      key: "b_time",
      width: '10%',
      align:'center',
    },
    {
      title: "Дата и время конца",
      dataIndex: "e_time",
      key: "e_time",
      width: '10%',
      align:'center',
    },
    {
      title: getTitle("IP"),
      dataIndex: "ip",
      key: "ip",
      width: '10%',
      align:'center',
    },
    {
      title: "Метод",
      dataIndex: "method",
      key: "method",
      width: '10%',
      align:'center',
    },
    {
      title: "Код ошибки",
      dataIndex: "err_code",
      key: "err_code",
      width: '10%',
      align:'center',
    },
    {
      title: "Текст ошибки",
      dataIndex: "err_msg",
      key: "err_msg",
    },
    {
      title: "",
      key: "buttons",
      width: '1%',
      fixed:'right',
      render: (_, record) => (
        <Button
          disabled={loading}
          type="primary"
          shape="circle"
          icon={<SearchOutlined />}
          title="Просмотр"
          onClick={() => info(record.key)}
        />
      ),
    },
  ];

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        initialValues={defaultFilterForm}
      />
      <Divider />
      <DrawerComponent
        title={<Trans alias={"Show data"} />}
        open={modalOpen}
        data={dataDetail}
        onCancel={() => setModalOpen(false)}
        width={'60%'}
      />
      <MonitoringTable
        columns={columns}
        size ='small'
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default PaymentsLog;
