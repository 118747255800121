import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMenu } from "../../../redux/slices/SiteSlice";
import { FolderFilled } from "@ant-design/icons";

function MenuMainComponent() {
  const [subMenu, setSubMenu] = useState([]);
  const menuList = useSelector(selectMenu);
  let location = useLocation();
  useEffect(() => {
    menuList.map((element) => {
      let url = location.pathname.split("/");
      if (element.url === "/" + url[1]) {
        setSubMenu(element.children);
      }
      return null;
    });
  }, [location, menuList]);

  return (
    <Row>
      <Col span={24}>
        {subMenu.map((obj) => (
          <Row key={obj.key + "LinkRightMenu"} className="content-menu-row">
            <Col span={24}>
              <Link to={obj.url}>
                <Row>
                  <Col
                    xxl={1}
                    xl={1}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={3}
                    className="content-menu-icon"
                  >
                    <FolderFilled />
                  </Col>
                  <Col xxl={23} xl={23} lg={22} md={22} sm={22} xs={21}>
                    {obj.title}
                  </Col>
                </Row>
              </Link>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
}

export default MenuMainComponent;
