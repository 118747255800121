import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Space, Typography, Button } from "antd";
import { getApi } from "../../../web/api";
import TextTinyMce from "../../templates/TextTinyMce";
import { conf } from "../../../config";
import Trans from "../../Trans";

const { Title, Text } = Typography;

const ManagementComponent = () => {
  const obj = { list: [] };
  const [content, setContent] = useState(obj);
  const [currentTab, setCurrentTab] = useState(1);
  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    let res = await getApi(`/main/content_managemant`);
    setContent(res ? res : obj);
  };
  
  return (
    <>
      {content.list.map((elm) => (
        <React.Fragment key={elm.id}>
          <Row justify="end">
            <Col>
              <Space size="middle">
                <Text>
                  <Trans alias="Update date" />
                </Text>
                <Text>{elm.changed}</Text>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              <img
                src={`${conf.server}main/content_managemant/photo/${elm.id}`}
                alt={elm.name}
                width="100%"
              />
            </Col>
            <Col
              xxl={18}
              xl={18}
              lg={18}
              md={18}
              sm={18}
              xs={18}
              style={{ padding: "10px" }}
            >
              <Row justify="bottom">
                <Col>
                  <Title level={2}>{elm.name}</Title>
                  <Text italic>{elm.post}</Text>
                  <Divider />
                </Col>
                <Col>
                  <TextTinyMce text={elm.text} />
                </Col>
                {/* <Col>
                  <Button
                    type="link"
                    size="large"
                    className={`management-tabs ${
                      currentTab === 1 && `management-tabs-active`
                    }`}
                    onClick={() => setCurrentTab(1)}
                  >
                    <Trans alias="Biography" />
                  </Button>
                  <Button
                    type="link"
                    size="large"
                    className={`management-tabs ${
                      currentTab === 2 && `management-tabs-active`
                    }`}
                    onClick={() => setCurrentTab(2)}
                  >
                    <Trans alias="Job Responsibilities" />
                  </Button>
                  <Button
                    type="link"
                    size="large"
                    className={`management-tabs ${
                      currentTab === 3 && `management-tabs-active`
                    }`}
                    onClick={() => setCurrentTab(3)}
                  >
                    <Trans alias="Reception schedule" />
                  </Button>
                </Col> */}
              </Row>
            </Col>
            <Divider />
            <Col span={24}>
              <Button
                type="link"
                size="large"
                className={`management-tabs ${
                  currentTab === 1 && `management-tabs-active`
                }`}
                onClick={() => setCurrentTab(1)}
              >
                <Trans alias="Biography" />
              </Button>
              <Button
                type="link"
                size="large"
                className={`management-tabs ${
                  currentTab === 2 && `management-tabs-active`
                }`}
                onClick={() => setCurrentTab(2)}
              >
                <Trans alias="Job Responsibilities" />
              </Button>
              <Button
                type="link"
                size="large"
                className={`management-tabs ${
                  currentTab === 3 && `management-tabs-active`
                }`}
                onClick={() => setCurrentTab(3)}
              >
                <Trans alias="Reception schedule" />
              </Button>
            </Col>
            <Col
              span={24}
              style={{
                border: "1px solid #dee2e6",
                padding: "20px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              {
                {
                  1: <TextTinyMce text={elm.biography} />,
                  2: <TextTinyMce text={elm.responsibility} />,
                  3: <TextTinyMce text={elm.reception} />,
                }[currentTab]
              }
            </Col>
          </Row>
          <Divider />
        </React.Fragment>
      ))}
    </>
  );
};

export default ManagementComponent;
