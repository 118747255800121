import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';
import { selectMenu } from '../../redux/slices/SiteSlice';
import { userEntitlement } from '../../redux/slices/AuthSlice';
import IconComponent from '../templates/IconComponent';
const _ = require('underscore');
const { Text } = Typography;

const ModulesComponent = () => {
  const menuList = useSelector(selectMenu);
  const location = useLocation();
  const [comps, setComps] = useState([]);
  const entitlement = useSelector(userEntitlement);
  const randomColor = () => {
    const colors = ['volcano', 'orange', 'green', 'geekblue', '#f50', '#2db7f5', '#87d068', '#108ee9'];
    const color = colors[Math.floor(Math.random() * colors.length)];
    return color;
  };
  useEffect(() => {
    setComps([]);
    let arr = [];
    menuList?.forEach((element) => {
      if (element.url === location.pathname) {
        if (_.isEmpty(element.privileges) || !_.isEmpty(_.intersection(entitlement, element.privileges))) {
          element.children?.forEach((child) => {
            if (_.isEmpty(child.privileges) || !_.isEmpty(_.intersection(entitlement, child.privileges))) {
              arr.push(child);
            }
          });
        }
      }
    });
    setComps(arr);
  }, [location, menuList]);

  return (
    <Row>
      {comps?.map((elm) => (
        <Col
          key={elm.key}
          style={{
            padding: '10px',
          }}
          xxl={6}
          xl={6}
          lg={8}
          md={12}
          sm={12}
          xs={24}
        >
          <Link to={elm.url}>
            <Row
              justify="space-around"
              align="middle"
              style={{
                minHeight: '128px',
                padding: '0 15px',
                borderRadius: '5px',
                backgroundColor: '#fff',
                color: 'rgba(0, 0, 0, 0.54)',
                fontFamily: 'sans-serif',
              }}
            >
              <Col span={4}>
                <IconComponent param={elm.icon} props={{ style: { color: randomColor(), fontSize: '48px' } }} />
              </Col>
              <Col span={20}>
                <Text>{elm.title}</Text>
              </Col>
            </Row>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default ModulesComponent;
