import React from "react";
import { Row, Col } from "antd";
import { DownloadOutlined, LinkOutlined } from "@ant-design/icons";

const DownloadContent = (props) => {
  return (
    <Row className="content-download-row">
      <Col span={24}>
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          <Row>
            <Col
              xxl={1}
              xl={1}
              lg={2}
              md={2}
              sm={2}
              xs={3}
              className="content-download-icon"
            >
              {props.link ? <LinkOutlined /> : <DownloadOutlined />}
            </Col>
            <Col xxl={23} xl={23} lg={22} md={22} sm={22} xs={21}>
              {props.text}
            </Col>
          </Row>
        </a>
      </Col>
    </Row>
  );
};

export default DownloadContent;
