import { Form, Row, Col, Input } from "antd";

import { getTitle } from "../../../../../Trans";
import Confirm from "../../../../../templates/Confirm";

const PasswordForm = (props) => {
  const notMatch = getTitle("The two passwords that you entered do not match");
  return (
    <Form
      form={props.form}
      name={props.name}
      layout="vertical"
      onFinish={props.onFinish}
      disabled={props.disabled}
    >
      <Row>
        <Col span={8}>
          <Form.Item
            name={"new_password"}
            label={getTitle("New password")}
            rules={[{ required: true, message: getTitle("Props is a must") }]}
            hasFeedback
          >
            <Input type="password" placeholder={getTitle("New password")} />
          </Form.Item>
          <Form.Item
            name={"new_password_again"}
            label={getTitle("New password (again)")}
            hasFeedback
            dependencies={["new_password"]}
            rules={[
              { required: true, message: getTitle("Props is a must") },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(notMatch);
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={getTitle("New password (again)")}
            />
          </Form.Item>
          <Confirm
            form={props.form}
            title={"Do you really want to save?"}
            btnText={"Save"}
            btnDisabled={props.disabled}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default PasswordForm;
