import React from "react";
import { Table } from "antd";

import { getTitle } from "../../../../Trans";

export const ViewDataClient = ({ data }) => {
  const dataSource = [
    {
      key: 1,
      name: getTitle("Inn"),
      value: data.inn,
    },
    {
      key: 2,
      name: "Название",
      value: data.name,
    },
    {
      key: 3,
      name: getTitle("Phone"),
      value: data.phone,  
    },
    {
      key: 4,
      name: "Организационная правовая форма",
      value: data.org_form_name,
    },
    {
      key: 5,
      name: "Вид деятельности",
      value: data.oked_name,
    },
    {
      key: 7,
      name: getTitle("Okpo"),
      value: data.okpo,
    },

    {
      key: 8,
      name: "Филиал",
      value: data.branch,
    },

    {
      key: 9,
      name: getTitle("Account"),
      value: data.account,
    },
    {
      key: 10,
      name: getTitle("Email"),
      value: data.email,
    },
    {
      key: 11,
      name: getTitle("Account"),
      value: data.account,
    },
    {
      key: 12,
      name: "Резидент",
      value: data.resident_name,
    },

    {
      key: 13,
      name: getTitle("Date and time of registration"),
      value: data.opened,
    },
    {
      key: 14,
      name: "Country",
      value: data.country_name,
    },
    {
      key: 15,
      name: getTitle("Address"),
      value: data.address,
    },
    {
      key: 16,
      name: "Доступ для ввода",
      value: data.enterable_name,
    },
    {
      key: 17,
      name: "Доступ для изменения",
      value: data.printable_name,
    },
    {
      key: 18,
      name: getTitle("Address"),
      value: data.address,
    },
    {
      key: 19,
      name: "Статус",
      value: data.state_name,
    },
  ];

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      width: '40%',
      onCell: (_, index) => {
        return {
          ['style']: { background: '#fafafa', fontWeight: 'bold' },
        }
      },
    },
    {
      dataIndex: "value",
      key: "value",
      width: '60%'
    },
  ];
  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
      />
    </>
  );
};
