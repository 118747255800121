import React from "react";
import { useSelector } from "react-redux";
// import { selectLang } from "../redux/SiteSlice";

export const TransFunc = (alias) => {
  const allLang = useSelector((state) =>
    state.site.lang.data.filter((elm) => elm.ALIAS === alias)
  );

  return !allLang.length ? alias : allLang[0]
}

export const getTitle = (name) => {
  let trans = TransFunc(name);
  if (trans) {
    return trans.NAME;
  }
}

const Trans = ({ alias }) => {
  const allLang = useSelector((state) =>
    state.site.lang.data.filter((elm) => elm.ALIAS === alias)
  );
  //   const allLang = useSelector(selectLang);
  return (
    <>
      {allLang.length > 0 ? allLang.map((elm) => (
        <React.Fragment key={elm.ID}>{elm.NAME}</React.Fragment>
      )) : <React.Fragment key={alias}>{alias}</React.Fragment>}
    </>
  );
};
export default Trans;
