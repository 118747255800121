export const pattern = {
  fio: "^[a-zA-Zа-яА-ЯЁЎҚҒҲёўқғҳ'` ]+$",
  name: "^[a-zA-Zа-яА-ЯЁЎҚҒҲёўқғҳ'`]+$",
  name_opt: '.*', //"^[a-zA-Zа-яА-ЯЁЎҚҒҲёўқғҳ'\"`]*$",
  phone: '^([0-9]{12})$',
  phoneOther: '^([0-9]{5,12})$',
  inn: '^([0-9]{9})$',
  okpo: '^([0-9]{8})$',
  branch: '^([0-9]{5})$',
  okonx: '^([0-9]{5})$',
  oked: '^([0-9]{5})$',
  account: '^([0-9]{20})$',
  passport: '^(([A-Z]{2}|[А-ЯЁ]{2})\\s[0-9]{7})$',
  passportS: '^([A-Z]{2}|[А-ЯЁ]{2})$',
  passportN: '^([0-9]{7})$',
  passportForeignS: '^([A-Z,0-9]{1,5})$',
  passportForeignN: '^([0-9]{3,10})$',
  login: '^([a-zA-Z0-9-_]{2,50})$',
  // login_full: '^([a-zA-Z0-9-_]{2,20})$',
  amount: '^(([0-9]{1,16})(\\.[0-9]{1,2})?)$',
  password: '^([a-zA-Z0-9+-=_.,!@#$%^&*<>/|:;~]{5,20})$',
  password_tip: 'a-z A-Z 0-9 + - = _ . , ! @ # $ % ^ & * < > / | : ; ~',
  declaration_code: '^([0-9]{12})$',
  inn_declaration: '^([0-9]{9}|[0-9]{12})$',
  ip: '^([0-9]{1,3}\.){3}([0-9]{1,3})$',
  ip_list: '^(([0-9]{1,3}\.){3}([0-9]{1,3});)+$',
  userid: '^([0-9]{10})$',
  client_code: '^([0-9]{12})$',
  cadastr_placeholder: 'XX:XX:XX:XX:XX:XXXX:XXXX:XXX',
  cadastre: '^([0-9]{2}:){5}([0-9]{4})((\/[0-9]{2})|(:[0-9]{4}:[0-9]{3}))?$',
  cadastre_mask:
    '^((([0-9]{2}:){5}[0-9]{4}_{9})|(([0-9]{2}:){5}[0-9]{4}\/[0-9]{2}_{6})|(([0-9]{2}:){5}[0-9]{4}:[0-9]{4}:[0-9]{3}))$',
  xcode: '^([0-9]{15,15})$',
  number: '^[0-9]*$',
  en_number: '^[a-zA-Z0-9 ]*$',
  pin: '^([0-9]{14})$',
  en: '^[a-zA-Z]*$',
  email: '^[^ ]+@[^ ]+\.[a-z]{2,6}$',
  // sum:`^(([0-9]{1,16})(\.[0-9]{1,2})?)$`,
  sum: `^[0-9]{1,16}(.[0-9]{2})?$`,
  year: `^([0-9]{4})$`,
};
export const date_format = 'DD.MM.YYYY';
export const month_format = 'MM.YYYY';
export const year_format = 'YYYY';
export const date_time_format = 'DD.MM.YYYY HH.MM.SS';
