import Cookies from 'js-cookie';

export const saveUserSession = auth => {
    Cookies.set("token", auth);
};

export const removeUserSession = () => {
    Cookies.remove("token");
};

export const getCookies =()=>{
    return Cookies.get("token");
};