import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Divider } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { selectMenu, selectPage, initPage } from "../../../redux/slices/SiteSlice";
const { Title } = Typography;

function MainRightMenu() {
  const [subMenu, setSubMenu] = useState([]);
  const [parentTitle, setParentTitle] = useState("");
  const menuList = useSelector(selectMenu);
  const pageSelector = useSelector(selectPage);
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    getSubMenu();
  }, [location, menuList]);

  const getSubMenu = () => {
    let url = location.pathname.split("/");
    menuList.forEach((element) => {
      if (element.url === "/" + url[1]) {
        setParentTitle(element.title);
        setSubMenu(element.children);
      }
      if (element.url === location.pathname.replace(/\/$/, "")) {
        dispatch(initPage(element));
      } else {
        if (element.children) {
          element.children.forEach((child) => {
            if (child.url === location.pathname.replace(/\/?[0-9]*\/?$/, "")) {
              dispatch(initPage(child));
            }
          });
        }
      }
    });
  };
  return (
    <Row className="right-menu-container">
      <Col span={24}>
        <Title level={3}>{parentTitle}</Title>
        <Divider />
        {subMenu.map((obj) => (
          <React.Fragment key={obj.key + "LinkRightMenu"}>
            <Link
              className={
                obj.key === pageSelector.key
                  ? "right-menu-link right-menu-link-selected"
                  : "right-menu-link"
              }
              to={obj.url}
            >
              <span>{obj.title}</span>
            </Link>
          </React.Fragment>
        ))}
      </Col>
    </Row>
  );
}

export default MainRightMenu;
