import React from "react";
import { Row, Col } from "antd";
import Trans from "../../../../Trans";
import { JsonViewer } from "../../../../templates/JsonViewer";

const ViewData = (props) => {
  return (
    <Row>
      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
        <h2>
          <Trans alias={"Request"} />
        </h2>
        <JsonViewer json={props.request} />
      </Col>
      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
        <h2>
          <Trans alias={"Response"} />
        </h2>
        <JsonViewer json={props.response} />
      </Col>
    </Row>
  );
};

export default ViewData;
