import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "../../web/api";
import { ErrorsApi } from "../../web/errors";
const initialState = {
  menu: {
    data: [],
    status: "idle",
    error: null,
  },
  error: null,
  lang: {
    data: [],
    status: "idle",
    error: null,
  },
  page: {},
};

export const getMenu = createAsyncThunk("/getMenu", async () => {
  try {
    return await getApi("main/menu");
  }
  catch (err) {
    ErrorsApi(err,'global');
  }
});
export const getLang = createAsyncThunk("/getLang", async () => {
  try {
    return await getApi("main/lang");
  }
  catch (err) {
    ErrorsApi(err,'global');
  }
});

const SiteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    initLang: (state, action) => {
      state.lang.data = action.payload;
      state.lang.status = "succeeded";
    },
    initPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMenu.pending, (state, action) => {
        state.menu.status = "loading";
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.menu.status = "succeeded";
        state.menu.data = action.payload;
      })
      .addCase(getMenu.rejected, (state, action) => {
        state.menu.status = "failed";
        state.menu.error = action.error.message;
      })
      .addCase(getLang.pending, (state, action) => {
        state.lang.status = "loading";
      })
      .addCase(getLang.fulfilled, (state, action) => {
        state.lang.status = "succeeded";
        state.lang.data = action.payload;
      })
      .addCase(getLang.rejected, (state, action) => {
        state.lang.status = "failed";
        state.lang.error = action.error.message;
      });
  },
});

export const selectMenu = (state) => state.site.menu.data;
export const statusMenu = (state) => state.site.menu.status;

export const selectLang = (state) => state.site.lang.data;
export const statusLang = (state) => state.site.lang.status;


export const selectPage = (state) => state.site.page;
export const { initLang, initPage } = SiteSlice.actions;

export default SiteSlice.reducer;
