import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { AppRouteComponents } from "./AppRouteComponents";
import { userEntitlement } from "../redux/slices/AuthSlice";
import IndexModules from "../site/modules/IndexModules";
import HttpError from "../site/HttpError";

function RoleRoutes() {
  const privileges = useSelector(userEntitlement);

  if (!privileges) return <></>;
  const getRoutes = (module) => {
    return AppRouteComponents.map(
      (component) =>
        component.module === module &&
        component.routes.map((route, i) =>
          route.privilege ? (
            privileges.map(
              (privilege, k) =>
                route.privilege.includes(privilege) && (
                  <Route
                    key={module + i + "privilege" + k}
                    path={route.path}
                    element={route.element}
                  />
                )
            )
          ) : (
            <Route key={module + i} path={route.path} element={route.element} />
          )
        )
    );
  };
  return (
    <Routes>
      <Route key={"test"} index element={<IndexModules />} />
      {getRoutes("content")}
      {getRoutes("profile")}
      {getRoutes("monitoring")}
      {getRoutes("record")}
      {getRoutes("payments")}
      {getRoutes("statistics")}
      {getRoutes("manage")}
      <Route path="*" element={HttpError (404,'Not found!','На главную')}/>
    </Routes>
  );
}
export default RoleRoutes;
