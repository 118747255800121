import React from "react";
import parseHtml from "html-react-parser";
import DOMPurify from "dompurify";

const TextTinyMce = ({ text }) => {
  return (
    <div className="text-tinymci">{parseHtml(DOMPurify.sanitize(text))}</div>
  );
};
export default TextTinyMce;
