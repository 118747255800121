import React from "react";
import { useParams } from "react-router-dom";

import { UserProfileForm } from "./form/components/UserProfileForm";
import moment from "moment";
import { date_format } from "../../../templates/Patterns";
import ApiHooks from "../../../../hooks/ApiHooks";

const UserProfile = () => {
  const { id } = useParams();
  const hookApi = ApiHooks();

  const handleSubmitProfile = async (values) => {
    values.birth_date = moment(values.birth_date).format(date_format);
    values.id = id;
    const user = { user: values };
    await hookApi(
      "/user/saveUserProfile",
      {
        data: user,
      },
      true
    );
  };
  const handleSubmitPassword = async (values) => {
    if (values.new_password !== values.new_password_again) {
      return;
    }
    values.id = id;
    await hookApi(
      "/user/changeUserPassword",
      {
        data: values,
      },
      true
    );
  };

  return (
    <>
      <UserProfileForm
        id={id}
        handleSubmitProfile={handleSubmitProfile}
        handleSubmitPassword={handleSubmitPassword}
      />
    </>
  );
};

export default UserProfile;
