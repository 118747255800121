import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Typography, Row, Col } from "antd";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import { login, selectStatus } from "../../../redux/slices/AuthSlice";
import GetInput from "../../templates/FormInput";
import { pattern } from "../../templates/Patterns";
import Trans, { getTitle } from "../../Trans";

const { Title } = Typography;

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectStatus);
  const location = useLocation();

  const onFinish = (values) => {
    localStorage.setItem("location", location.pathname);
    dispatch(login(values));
  };

  return (
    <Form name="normal_login" className="login-form" onFinish={onFinish}>
      <Title
        level={4}
        style={{ fontWeight: "normal", color: "rgba(0, 0, 0, 0.54)" }}
      >
        <Trans alias={"Personal cabinet"} />
      </Title>
      <GetInput
        name={"username"}
        pattern={pattern.login}
        required={true}
        withOutlabel={true}
        text={"Username"}
        prefix={<UserOutlined className="site-form-item-icon" />}
      />
      <GetInput
        name={"password"}
        type={"password"}
        required={true}
        withOutlabel={true}
        text={"Password"}
        prefix={<LockOutlined className="site-form-item-icon" />}
      />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          block
          loading={loading}
          icon={<LoginOutlined />}
        >{getTitle("Enter")}
        </Button>
      </Form.Item>
      <Row>
        <Col span={9}>
          <Link to="/registration" disabled={loading}>
            {getTitle("Register now")}
          </Link>
        </Col>
        <Col span={15}>
          <Link to="/about/instructions" disabled={loading}>
            {getTitle("Registration instructions")}
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
