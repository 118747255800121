import { useParams } from "react-router-dom";
import ContentStatic from "./static/ContentStatic";
import ContentLang from "./lang/ContentLang";
import ContentDynamic from "./dynamic/ContentDynamic";

const ContentRoute = () => {
  const contents = {
    functions: "3",
    regulation: "4",
    contacts: "5",
    responsibilities: "6",
    structure: "7",
    info: "8",
    laws: "17",
    faq: "18",
    resources: "19",
    service: "20",
    instructions: "21",
    charter: "23",
    offer: "26",
    vacancies: "27",

    site_lang: "24",

    managment: "25",

    news: "1",
    reviews: "2",
    plan: "9",
    audit: "10",
    purchase: "11",
    other: "12",
    indicator: "13",
    a_report: "14",
    q_report: "15",
    trip: "16",
    adverts: "22",
  };

  const { id } = useParams();

  return (
    <>
      {contents.functions === id && <ContentStatic type={id} />}
      {contents.regulation === id && <ContentStatic type={id} />}
      {contents.contacts === id && <ContentStatic type={id} />}
      {contents.responsibilities === id && <ContentStatic type={id} />}
      {contents.structure === id && <ContentStatic type={id} />}
      {contents.info === id && <ContentStatic type={id} />}
      {contents.laws === id && <ContentStatic type={id} />}
      {contents.faq === id && <ContentStatic type={id} />}
      {contents.resources === id && <ContentStatic type={id} />}
      {contents.service === id && <ContentStatic type={id} />}
      {contents.instructions === id && <ContentStatic type={id} />}
      {contents.charter === id && <ContentStatic type={id} />}
      {/* {contents.managment === id && <ContentStatic type={id} />} */}
      {contents.offer === id && <ContentStatic type={id} />}
      {contents.vacancies === id && <ContentStatic type={id} />}

      {contents.site_lang === id && <ContentLang />}

      {contents.news === id && <ContentDynamic type={id} />}
      {contents.reviews === id && <ContentDynamic type={id} />}
      {contents.plan === id && <ContentDynamic type={id} />}
      {contents.audit === id && <ContentDynamic type={id} />}
      {contents.purchase === id && <ContentDynamic type={id} />}
      {contents.other === id && <ContentDynamic type={id} />}
      {contents.indicator === id && <ContentDynamic type={id} />}
      {contents.a_report === id && <ContentDynamic type={id} />}
      {contents.q_report === id && <ContentDynamic type={id} />}
      {contents.trip === id && <ContentDynamic type={id} />}
      {contents.adverts === id && <ContentDynamic type={id} />}
    </>
  );
};

export default ContentRoute;
