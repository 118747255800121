import { Form, Row, Col, Divider, DatePicker } from 'antd';
import { getTitle } from '../../../../Trans';
import GetSelect from '../../../../templates/FormSelect';
import GetInput from '../../../../templates/FormInput';
import GetOthers from '../../../../templates/FormOther';

import { pattern, date_format } from '../../../../templates/Patterns';
import { REFERS } from '../../../../constants/refers';

const FormPledge = (props) => {
    return (
        <Form form={props.form} onFinish={props.onFinish} name={props.name} style={props.style} layout="vertical">
            <Row gutter={5}>
                <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                    <GetInput name={'xcode'} pattern={null} required={false} text={'xcode'} tooltip={getTitle('xcode info')} />
                </Col>
            </Row>
            {(props.client_type !== '1' && props.client_type !== '2') && <Row gutter={5}>
                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <GetSelect name={'doc_type'} required={true} refer={props.client_type !== '3' ? REFERS.conREF_DEC_TYPES : REFERS.conREF_DEC_TYPES_4ORGANS} func={(value, options) => { props.selectLabel({ type: options.label }) }} text={'doc_type'} />
                </Col>
                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <GetInput name={'doc_n'} pattern={null} required={true} text={'doc_n'} /*tooltip={getTitle('Right code info')}*/ />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                    <GetOthers name={'doc_d'} pattern={null} required={true} text={'doc_d'} component={<DatePicker format={date_format} style={{ width: '100%' }} />} />
                </Col>
            </Row>}
            {props.client_type !== '3' &&
                <Row gutter={5}>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <GetSelect name={'right_type'} required={true} refer={REFERS.conREF_RIGHT_TYPES} text={'right_type'} func={(value, options) => { props.selectLabel({ right_type: options.label }) }} />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetInput name={'right_code'} pattern={null} required={true} text={'right_code'} /*tooltip={getTitle('Right code info')}*/ />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetOthers name={'right_date'} pattern={null} required={true} text={'right_date'} component={<DatePicker format={date_format} style={{ width: '100%' }} />} />
                    </Col>
                </Row>}
            <Divider />
            <Row gutter={5}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} >
                    <GetInput name={'amount'} pattern={pattern.sum} required={props.client_type !== '3'} text={'amount'} tooltip={getTitle('Amount info')} />
                </Col>
                {props.client_type !== '3' &&
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetOthers name={'expired'} pattern={null} required={true} text={'expired'} tooltip={getTitle('Expired info')} component={<DatePicker format={date_format} style={{ width: '100%' }} />} />
                    </Col>
                }
            </Row>
        </Form>
    )
};
export default FormPledge;