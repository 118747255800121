import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, ScrollRestoration } from "react-router-dom";
import { statusMenu, getMenu, statusLang, getLang } from "../redux/slices/SiteSlice";

import TestTinyMci from "../testTinymci";
import Site from "../site/Site";
import Main from "../site/content/Main/Main";
import MainWithSideMenu from "../site/content/Main/MainWithSideMenu";
import Modules from "../site/Modules";
import Regist from "../site/registration/Register";
import { AppRouteComponents } from "./AppRouteComponents";
import { selectAuth, getUserPrivileges, userAuth } from "../redux/slices/AuthSlice";
import Cookies from "js-cookie";
import Activation from "../site/registration/Activation";
import ScrollToTop from "../hooks/useScrollToTop";

function AppRoutes() {
  const dispatch = useDispatch();
  const menustate = useSelector(statusMenu);
  const reduxLangState = useSelector(statusLang);
  let menuLoading = false;
  const user = useSelector(userAuth);
  const auth = useSelector(selectAuth);
  useEffect(() => {
    if (menustate === "idle" && !menuLoading) dispatch(getMenu());
    menuLoading = !menuLoading;
  }, [menustate, dispatch]);

  useEffect(() => {
    if (reduxLangState === "idle") dispatch(getLang());
  }, [reduxLangState, dispatch]);

  useEffect(() => {
    if (Cookies.get("token") && !user) {
      dispatch(getUserPrivileges());
    }
  }, [auth]);


  const getRoutes = (module) => {
    return AppRouteComponents.map(
      (component) =>
        component.module === module &&
        component.routes.map((route, i) => (
          <Route key={module + i} path={route.path} element={route.element} />
        ))
    );
  };
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="tiny" element={<TestTinyMci />} />
          <Route path="/main/*" element={<Modules />} />
          <Route path="/" element={<Site />}>
            {!auth && !Cookies.get("token") && <Route path="registration" element={<Regist />} />}
            <Route path="activate/:id" element={<Activation />} />
            <Route index element={<Main />} />
            <Route path="/" element={<MainWithSideMenu />}>
              {getRoutes("about")}
              {getRoutes("press_center")}
              {getRoutes("open_data")}
              {getRoutes("career")}
              {getRoutes("faq")}
              {getRoutes("contacts")}
            </Route>
            <Route path="*" element={<p>Not Found1</p>} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
export default AppRoutes;
