import { Input, Form } from "antd";
import { TransFunc } from "../Trans";
const { TextArea } = Input;
const GetTextArea = (props) => {
    const required_msg = TransFunc("Props is a must");
    const pattern_msg = TransFunc("Does not match with pattern");
        return (
            <Form.Item name={props.name} style={{ margin: 0 }}
                rules={[
                    { required: props.required, message: required_msg.NAME },
                    { pattern: props.pattern, message: pattern_msg.NAME },
                ]}>
                <TextArea maxLength={props.maxLength} autoSize={props.rows?false:true} rows={props.rows}/>
            </Form.Item>
        );
};

export default GetTextArea;
