import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const HighChartComponent = (props) => {
  const options = {
    chart: {
      type: props.type,
    },
    credits: false,
    title: {
      text: props.title,
      style: {
        fontSize: '14px',
        fontWeight: 'normal',
      },
    },
    subtitle: {
      text: [],
    },
    xAxis: props.xAxis,
    yAxis: props.yAxis,
    accessibility: { enabled: false },
    tooltip: {
      headerFormat: `<span style="font-size:10px">{point.key}</span><table>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      series: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointStart: props.pointStart,
        dataLabels: {
          enabled: true,
        },
      },
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: props.series,
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
export default HighChartComponent;
