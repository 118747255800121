import { Col, Row, Table, Typography } from "antd";
import React from "react";
import GetSelect from "../../../../templates/FormSelect";
import { getTitle } from "../../../../Trans";

export const ClientChangeRoleModal = ({ client_code, client_role, setRoleModal, client_name }) => {
  const dataSource = [
    {
      key: 1,
      code: client_code,
      name: client_name,
      value: client_role,
    },
  ];

  const columns = [
    {
      title: getTitle("Client code"),
      dataIndex: "code",
      key: "code",
      align: "center",
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: getTitle("name"),
      dataIndex: "name",
      key: "name",
      // width: "70%",
      align: "center",
      onCell: (_, index) => {
        return {
          ["style"]: { textAlign: "left" },
        };
      },
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Роль",
      dataIndex: "value",
      key: "value",
      // width: "60%",
      align: "center",
      render: () => (
        <GetSelect
          style={{ marginBottom: "0" }}
          selectStyle={{ minWidth: "200px" }}
          text={"Роль"}
          refer={"643"}
          func={(value) => setRoleModal((prev) => ({ ...prev, client_role: value }))}
          value={client_role}
        />
      ),
    },
  ];

  return (
    <Row gutter={[15, 0]} style={{ minWidth: "700px" }}>
      <Col lg={24}>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </Col>
    </Row>
  );
};
