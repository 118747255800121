import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { getTitle } from '../../../../../Trans';
import MonitoringTable from '../../../../../templates/MonitoringTable';
import TableSummaryComponent from './table-summary-component';

const StatisticsClientType = (props) => {
  const titleCountAll = getTitle('Count');
  const titleCountActive = getTitle('Count active');
  const titleCountInActive = getTitle('Count inactive');
  const titleName = getTitle('name');
  const { statistics, setColumns, loading, setSummaryTitleSize } = props;
  let currentPage = 1;
  const pageSize = 15;
  const titleSize = 2;
  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      width: 35,
      render: (text, record, index) =>
        (currentPage - 1) * pageSize + (index + 1),
      align: 'center',
    },
    {
      title: titleName,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: titleCountAll,
      dataIndex: 'count_all',
      key: 'count_all',
      align: 'center',
    },
    {
      title: titleCountActive,
      dataIndex: 'count_active',
      key: 'count_active',
      align: 'center',
    },
    {
      title: titleCountInActive,
      dataIndex: 'count_inactive',
      key: 'count_inactive',
      align: 'center',
    },
  ];

  useEffect(() => {
    setSummaryTitleSize(titleSize);
    setColumns(columns);
  }, []);

  const summaryData = [
    { value: statistics?.total?.count_all },
    { value: statistics?.total?.count_active },
    { value: statistics?.total?.count_inactive },
  ];

  return (
    <Row>
      <Col xs={24}>
        <MonitoringTable
          size={'middle'}
          columns={columns}
          dataSource={statistics?.data}
          loading={loading}
          pagination={{
            defaultPageSize: pageSize,
            onChange: (page) => {
              currentPage = page;
            },
          }}
          bordered={true}
          rowKey={(record) => record.order}
          summary={() => (
            <TableSummaryComponent
              titleColSpan={titleSize}
              summaryData={summaryData}
            />
          )}
        />
      </Col>
    </Row>
  );
};
export default StatisticsClientType;
