import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider, Form, Table, Button, Badge, Space, Pagination } from "antd";
import { FileImageOutlined, UnlockOutlined } from "@ant-design/icons";
import moment from "moment";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import { access_ } from "../../../../../redux/slices/AuthSlice";
import Trans, { getTitle } from "../../../../Trans";
import { date_format } from "../../../../templates/Patterns";
import GetBanPdf from "../../../../templates/GetBanPdf";
import ModalComponent from "../../../../templates/ModalComponent";
const NotaryCancelBanForm = () => {
  const pageSize = 20;
  const defaultFilterForm = { bdate: moment(), edate: moment() };
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    dec_code: null,
    ban_number: null,
    ban_state: null,
    page: 1,
    page_size: pageSize,
  });
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [modalPdf, setModalPdf] = useState(false);
  const [infoBan, setInfoBan] = useState({ ban_num: null, ban_id: null });
  const [data, setData] = useState([]);
  const loading = useSelector(loadingState);
  const access = useSelector(access_);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    let result = await hookApi("record/notaryBanList", { data: sendObj });
    if (result.data) {
      setData(result.data.data);
      setDataCount(result.data.count);
    };
  }

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    let data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.dec_code = values.dec_code ? values.dec_code : null;
    data.ban_num = values.ban_num ? values.ban_num : null;
    data.ban_state = values.ban_state ? values.ban_state : null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const getPdf = async (record) => {
    setModalPdf(true)
    setInfoBan({ ban_num: record.ban_num, ban_id: record.ban_id })
  };

  const notaryModal = (record) => {
    setInfoBan({ ban_num: record.ban_num, ban_id: record.ban_id })
    setModal(true);
  }

  const blankNotary = async (ban_id) => {
    window.open(`/main/record/notary/cancelban/${ban_id}`, "_blank");
    setModal(false);
    loadContent();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ban_id",
      key: "ban_id",
      align: "center",
    },
    {
      title: getTitle('Code'),
      dataIndex: "dec_code",
      align: 'center',
      fixed: 'left',
      key: "dec_code",
    },
    {
      title: getTitle("User"),
      dataIndex: "client_name",
      key: "client_name"
    },
    {
      title: getTitle("S.F.P"),
      dataIndex: "user_fio",
      key: "user_fio"
    },
    {
      title: getTitle("Username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: getTitle('Ban number'),
      dataIndex: "ban_num",
      align: 'center',
      key: "ban_num",
    },
    {
      title: getTitle("Ban date"),
      dataIndex: "ban_date",
      align: 'center',
      key: "ban_date",
    },
    {
      title: getTitle('Issue Ban'),
      dataIndex: "ban_edate",
      align: 'center',
      key: "ban_edate",
    },
    {
      title: getTitle('Date cancel ban'),
      dataIndex: "ban_cancel_date",
      align: 'center',
      key: "ban_cancel_date",
    },
    {
      title: getTitle('Request source'),
      dataIndex: "source_name",
      align: 'center',
      key: "source_name",
    },
    {
      title: getTitle('Error'),
      dataIndex: "res_note",
      align: 'center',
      key: "res_note",
    },
    {
      title: getTitle("Ban state"),
      dataIndex: "ban_state",
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{ backgroundColor: `${record.state === 1 ? "#52c41a" : "#f5222d"}` }}
        />
      ),
      key: "state_name",
    },
    {
      title: "",
      dataIndex: "details",
      key: "details",
      width: '1%',
      fixed: 'right',
      render: (_, record) => (
        <>
          <Space>
            {record.cancel &&
              <>
                <Button
                  disabled={false}
                  title="PDF"
                  type="primary"
                  shape="circle"
                  icon={<FileImageOutlined />}
                  onClick={() => getPdf(record)}
                />
                {
                  <Button
                    disabled={false}
                    title="Снятие запрета"
                    type="danger"
                    shape="circle"
                    icon={<UnlockOutlined />}
                    onClick={() => notaryModal(record)}
                  />
                }
              </>
            }
          </Space>
        </>
      )
    },
  ]

  return (
    <>
      <FilterForm
        form={form}
        disabled={loading}
        role={access.record_list_super}
        initialValues={defaultFilterForm}
        modal={() => setModal(true)}
        onFinish={handleFilterForm}
      />
      <Divider />
      <Table size="small" dataSource={data} columns={columns} loading={loading} rowKey={(record) => record.ban_id} />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      )}
      <ModalComponent
        title={<Trans alias={"Cancel ban in Notary"} />}
        open={modal}
        data={getTitle("Do you really want to cancel ban in the Notary") + ' ' + infoBan.ban_num + '?'}
        onOk={() => blankNotary(infoBan.ban_id)}
        onCancel={() => setModal(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <GetBanPdf modal={modalPdf} onCancel={() => setModalPdf()} titleId={infoBan.ban_num} id={infoBan.ban_id} url={'/record/getNotaryBanPDF'} title={"Create PDF"} text={"Create a file for arrest"} />
    </>
  );
};

export default NotaryCancelBanForm;
