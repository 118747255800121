import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { selectMenu } from "../../../redux/slices/SiteSlice";
import HeaderFullNav from "./HeaderFullNav";
import Trans from "../../Trans";
import { userAuth, userEntitlement } from "../../../redux/slices/AuthSlice";
import { NewFulllHeaderNav } from "./NewFulllHeaderNav";
import { useOverflowHidden } from "../../../hooks/useOverflowHidden";
const _ = require("underscore");

function HeaderNav({ forModules }) {
  const menuList = useSelector(selectMenu);
  const user = useSelector(userAuth);
  const entitlement = useSelector(userEntitlement);

  const [subMenuVisible, setSubMenuVisible] = useState(0);
  const [visible, setVisible] = useState(false);

  const { isHidden, setIsHidden } = useOverflowHidden();

  const SubMenuShow = (menuId) => {
    setSubMenuVisible(menuId);
  };

  const showDrawer = () => {
    setVisible(!visible);
    setIsHidden(!isHidden);
  };

  return (
    <>
      <Row className="header-nav">
        <Col span={24}>
          <div className="main-menu">
            <Space size="middle">
              <Link
                to="#"
                onClick={() => showDrawer()}
                key="mail"
                style={{ display: "inline-block" }}
              >
                <MenuOutlined style={{ margin: "0 10px" }} />
              </Link>
              {forModules && (
                <Link key={-1} to={"/"} className="display-block">
                  <Trans alias={"Home"} />
                </Link>
              )}
              {menuList &&
                menuList.map((obj) => (
                  <React.Fragment key={obj.key}>
                    {!forModules && obj.type === 0 && (
                      <Link
                        key={obj.key}
                        to={obj.url}
                        onMouseEnter={() => SubMenuShow(obj.key)}
                        className="display-block"
                      >
                        {obj.title}
                      </Link>
                    )}
                    {forModules &&
                      obj.type === 1 &&
                      (_.isEmpty(obj.privileges) ||
                        !_.isEmpty(_.intersection(entitlement, obj.privileges))) && (
                        <Link
                          key={obj.key}
                          to={obj.url}
                          onMouseEnter={() => SubMenuShow(obj.key)}
                          className="display-block"
                        >
                          {obj.title}
                        </Link>
                      )}
                  </React.Fragment>
                ))}
              {user && (
                <Link key={-2} to={"/main"} className="display-block">
                  <Trans alias={"Personal cabinet"} />
                </Link>
              )}
            </Space>
          </div>
        </Col>
      </Row>
      <Row className="main-menu-list">
        {menuList &&
          menuList.map((obj) => (
            <React.Fragment key={obj.key + "SubMenu"}>
              {obj.children.length !== 0 && (
                <Col
                  span={24}
                  style={{
                    display: subMenuVisible === obj.key ? "block" : "none",
                  }}
                  onMouseLeave={() => SubMenuShow(0)}
                >
                  <Row>
                    {obj.children.map((submenu) => (
                      <React.Fragment key={obj.key + "SubMenu" + submenu.key}>
                        {(_.isEmpty(submenu.privileges) ||
                          !_.isEmpty(_.intersection(entitlement, submenu.privileges))) && (
                          <Col span={8} style={{ padding: "10px" }}>
                            <Link
                              key={submenu.key + "fsds"}
                              to={submenu.url}
                              style={{ fontWeight: "bold" }}
                              className="display-block"
                            >
                              {submenu.title}
                            </Link>
                            {submenu.children &&
                              submenu.children.map((subsup) => (
                                <React.Fragment
                                  key={
                                    obj.key + "SubMenu" + submenu.key + "SubMenuChild" + subsup.key
                                  }
                                >
                                  {(_.isEmpty(subsup.privileges) ||
                                    !_.isEmpty(_.intersection(entitlement, subsup.privileges))) && (
                                    <Row>
                                      <Col style={{ paddingLeft: "24px" }}>
                                        <Link
                                          key={subsup.key + "SubMenuChild"}
                                          to={subsup.url}
                                          className="display-block"
                                        >
                                          {subsup.title}
                                        </Link>
                                      </Col>
                                    </Row>
                                  )}
                                </React.Fragment>
                              ))}
                          </Col>
                        )}
                      </React.Fragment>
                    ))}
                  </Row>
                </Col>
              )}
            </React.Fragment>
          ))}
      </Row>
      {/* <HeaderFullNav visible={visible} onClose={showDrawer} /> */}
      <NewFulllHeaderNav visible={visible} onClose={showDrawer} />
    </>
  );
}

export default HeaderNav;
