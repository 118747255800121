import { useSelector } from "react-redux";
import { Form, Row, Col, Button } from "antd";
import Trans from "../../../../Trans";
import GetInput from "../../../../templates/FormInput";
import { pattern } from "../../../../templates/Patterns";
import GetSelect from "../../../../templates/FormSelect";
import { REFERS } from "../../../../constants/refers";
import { client_role } from "../../../../../redux/slices/AuthSlice";
import { clientRoles } from "../../../../constants/priveleges";
const FilterForm = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };
  const client = useSelector(client_role);
  const type = Form.useWatch('type', props.form);
  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        {client !== clientRoles.super &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetSelect name={'type'} refer={REFERS.conREF_PROTEST_TYPES} text={'Protest type'} required={true} />
          </Col>
        }
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput pattern={pattern.client_code} name={"code"} text={"Code"} />
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput name={"debtor_inn_pin"} text={"Inn/Pin debtor"} pattern={pattern.number} maxLength={14} minLength={9} />
        </Col>
        {type === '1' &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput name={"username"} pattern={pattern.login} text={"Username"} />
          </Col>
        }
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button style={{ margin: "0 8px" }} onClick={() => { props.form.resetFields(); props.form.submit(); props.setDisExcel(); }}>
            <Trans alias={"Reset"} />
          </Button>
          {client !== clientRoles.super &&
            <Button type="success" onClick={() => { props.setModalProtest(true) }}>
              <Trans alias={"Send protest"} />
            </Button>
          }
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
