import React from "react";
import { Modal, Button } from "antd";

const ModalComponent = (props) => {
  return (
    <Modal
      open={props.open}
      title={props.title}
      onCancel={props.onCancel}
      getContainer={"#forModal"}
      footer={!props.notfooter&&[
        <Button
          style={{ display: props.onCancel ? "inline" : "none" }}
          key="back"
          onClick={props.onCancel}
          loading={props.loading}
        >
          {props.cancelTitle}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={props.loading}
          onClick={props.onOk}
        >
          {props.okTitle}
        </Button>,
      ]}
      width={props.width}
      centered={props.centered}
    >
      {props.data}
    </Modal>
  );
};

export default ModalComponent;
