import { Button } from 'antd';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FileExcelOutlined } from '@ant-design/icons';
import ApiHooks from '../../hooks/ApiHooks';

const ExportExcel = (props) => {
  const hookApi = ApiHooks();

  const getData = async (url, data) => {
    data.excel = 1;
    const result = await hookApi(url, { data: data });
    if (result.data.data) {
      if (url === 'statistic/getClientAction') {
        result.data.data.forEach((element) => {
          delete element.client_code;
          delete element.client_name;
        });
      }
      exportToCSV(result.data, props.summaryTitleSize, props.titleAmountSum);
    }
  };

  const exportToCSV = (data, summaryTitleSize, titleAmountSum) => {
    const { data: json, total } = data;

    // Получаем заголовки столбцов
    const header = props.columns.map((obj) => obj.title);
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileName = props.fileName;

    // Создаем новый рабочий лист
    const ws = XLSX.utils.book_new();

    // Добавляем заголовки в рабочий лист (первая строка)
    XLSX.utils.sheet_add_aoa(ws, [header]);
    // Добавляем данные из JSON начиная с A2, пропуская заголовки
    if (summaryTitleSize) {
      // Создаем массив данных для строки с суммами
      const totalData = [
        titleAmountSum,
        ...Array(summaryTitleSize - 1).fill(''),
      ];
      totalData.push(...Object.values(total)); // Добавляем значения из total в оставшиеся столбцы
      XLSX.utils.sheet_add_json(ws, [totalData], {
        origin: 'A2',
        skipHeader: true,
      });
      // Добавляем данные из JSON начиная с A3, пропуская заголовки
      XLSX.utils.sheet_add_json(ws, json, {
        origin: 'A3',
        skipHeader: true,
      });
    } else {
      XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });
    }

    // Добавляем данные из JSON начиная с A2, пропуская заголовки
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    // Генерация буфера с файлом
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true,
    });

    // Создание blob и сохранение файла
    const finalData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(finalData, `${fileName}.xlsx`);
  };

  return (
    <Button
      type="success"
      onClick={() => getData(props.url, props.sendObj)}
      disabled={props.disabled}
      icon={<FileExcelOutlined />}
    >
      Excel
    </Button>
  );
};
export default ExportExcel;
