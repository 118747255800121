import { useSelector } from "react-redux";
import { selectMenu } from "../../redux/slices/SiteSlice";
import { useLocation } from "react-router-dom";
import { Row, Col } from "antd";

const PageTitle = () => {
    let location = useLocation();
    const menu = useSelector(selectMenu);
    return (
        <Row>
            <Col span={24}>{
                menu?.map((elem) => (
                    location.pathname === elem.url ? <h3 key={elem.key}>{elem.title}</h3> :
                        elem.children?.map((elem2) =>
                            location.pathname === elem2.url && <h3 key={elem2.key}>{elem2.title}</h3>
                        )
                ))
            }
            </Col>
        </Row>
    );
};

export default PageTitle;
