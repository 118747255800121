import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Divider, Button, Table, Space } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ApiHooks from '../../../../hooks/ApiHooks';
import Confirm from '../../../templates/Confirm';
import { loadingState } from '../../../../redux/slices/LoadingSlice';
import { getTitle } from '../../../Trans';
import FilterForm from './components/FilterForm';
import GetTextArea from '../../../templates/FormTextArea';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  return (
    <td {...restProps}>{editing ? <GetTextArea name={dataIndex} required={true} maxLength={300} /> : children}</td>
  );
};

const RefersFormEdit = () => {
  const hookApi = ApiHooks();
  const [form] = Form.useForm();
  const [filter] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [refer, setRefer] = useState();
  const loading = useSelector(loadingState);
  const isEditing = (record) => record.code === editingKey;

  const getRefer = async (values) => {
    const result = await hookApi('refers/getRefersLangs', { ...values });
    if (result.data) {
      setRefer(values.refer);
      setEditingKey('');
      setData(result.data.data);
    }
  };

  const edit = (record) => {
    form.setFieldsValue({ code: '', name_ru: '', name_uz: '', name_oz: '', name_en: '', ...record });
    setEditingKey(record.code);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (code) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => code === item.code);
      if (index > -1) {
        let item = newData[index];
        let data = { ...item, ...row };
        newData.splice(index, 1, { ...data });
        data.refer_code = refer;
        let setRef = await hookApi('refers/setRefersLangs', { data: data }, true);
        if (setRef.code === 0) {
          setData(newData);
          setEditingKey('');
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: getTitle('Simple code'),
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      fixed: true,
    },
    {
      title: getTitle('Text in Russian'),
      dataIndex: 'name_ru',
      key: 'name_ru',
      editable: true,
    },
    {
      title: getTitle('Text in Uzbek'),
      dataIndex: 'name_uz',
      key: 'name_uz',
      editable: true,
    },
    {
      title: getTitle('Text in Uzbek (latin)'),
      dataIndex: 'name_oz',
      key: 'name_oz',
      editable: true,
    },
    {
      title: getTitle('Text in English'),
      dataIndex: 'name_en',
      key: 'name_en',
      editable: true,
    },
    {
      dataIndex: 'edit',
      align: 'center',
      key: 'edit',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Confirm
              shape="circle"
              func={() => save(record.code)}
              title={'Do you really want to save?'}
              btnDisabled={loading}
              btnIcon={<CheckOutlined />}
              btnType={'success'}
              btnTitle={'Save'}
            />
            <Confirm
              shape="circle"
              func={cancel}
              title={'Cancel changed'}
              btnDisabled={loading}
              btnIcon={<CloseOutlined />}
              btnType={'danger'}
              btnTitle={'Cancel'}
            />
          </Space>
        ) : (
          <>
            <Button
              type="primary"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              shape="circle"
              icon={<EditOutlined />}
            />
          </>
        );
      },
      fixed: 'right',
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({ record, dataIndex: col.dataIndex, title: col.title, editing: isEditing(record) }),
    };
  });

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={getRefer}
        disabled={loading}
        resetTable={setData}
        setEditingKey={setEditingKey}
      />
      <Divider />
      <Form form={form} component={false}>
        <Table
          loading={loading}
          size="small"
          components={{ body: { cell: EditableCell } }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{ onChange: cancel, showSizeChanger: false, defaultPageSize: 15 }}
        />
      </Form>
    </>
  );
};

export default RefersFormEdit;
