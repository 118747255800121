import React, { useState, useEffect } from 'react';
import { Table, Form, Row, Col, Select, DatePicker, Button } from 'antd';
import { getTitle } from '../../../../Trans';

const ViewData = (props) => {
  const dataSource = [
    {
      key: 1,
      name: getTitle('Unique service code'),
      value: props.data.id,
    },
    {
      key: 2,
      name: getTitle('Date and time of registration'),
      value: props.data.registered,
    },
    {
      key: 3,
      name: getTitle('Service type'),
      value: props.data.service_name,
    },
    {
      key: 4,
      name: getTitle('Sum'),
      value: props.data.amount,
    },
    {
      key: 5,
      name: getTitle('Service status'),
      value: props.data.state_name,
    },
    {
      key: 6,
      name: getTitle('Pin') + '/' + getTitle('Inn'),
      value: props.data.client_pin_inn,
    },
    {
      key: 7,
      name: getTitle('Company name') + '/' + getTitle('S.F.P'),
      value: props.data.client_name,
    },
    {
      key: 8,
      name: getTitle('Payment system'),
      value: props.data.center_name,
    },
    {
      key: 9,
      name: getTitle('Transaction ID'),
      value: props.data.transaction_id,
    },
    {
      key: 10,
      name: getTitle('Transaction date'),
      value: props.data.transaction_date,
    },
    {
      key: 11,
      name: getTitle('Transaction amount'),
      value: props.data.transaction_amount,
    },
  ];

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: 200,
      //   render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: 200,
    },
  ];
  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
      />
    </>
  );
};

export default ViewData;
