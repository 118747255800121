import { useState, useEffect } from 'react';
import { Form, Col } from 'antd';
import GetSelect from '../../../../templates/FormSelect';
import GetOthers from '../../../../templates/FormOther';
import Juridical from './components/CreditorDebitor/FormJuridical';
import Physical from './components/CreditorDebitor/FormPhysical';
import Individual from './components/CreditorDebitor/FormIndividual';
import Debtor_juridical from './components/CreditorDebitor/debtor_juridical';
import Debtor_physical from './components/CreditorDebitor/debtor_physical';
import Debtor_individual from './components/CreditorDebitor/debtor_individual';
import Trans from '../../../../Trans';
import { useSelector } from 'react-redux';
import { access_ } from '../../../../../redux/slices/AuthSlice';

const FormDebitor = (props) => {

    const subject_type = Form.useWatch('subject_type', props.form);
    const resident = Form.useWatch('resident', props.form);
    const [checkSmallB, setCheckSmallb] = useState(false);
    const [disabledAll, setDisabledAll] = useState(false);
    const access = useSelector(access_)

    useEffect(() => {
        if (props.dataEdit?.debtor) {
            if (props.dataEdit.debtor.small_business !== '') {
                setCheckSmallb(true);
            };
        };
        if (props.client_type === '1' && props.name === 'creditor' && !access.accessEdit) {
            setDisabledAll(true);
        };
        if (props.client_type === '2' && props.name === 'debitor' && !access.accessEdit) {
            setDisabledAll(true);
        };
        if (props.client_type === '3' && props.name === 'creditor' && !access.accessEdit) {
            setDisabledAll(true);
        };
    }, []);

    const checked = (e) => {
        setCheckSmallb(!checkSmallB)
        if (!e.target.checked) {
            props.form.setFieldValue('small_business', null);
        }
    };

    const onChange = (accepted) => {
        let agree = <Trans alias={'Yes'} />;
        props.form.setFieldValue('accepted', accepted ? '1' : null);
        props.selectLabel({ accepted: agree });
    };

    return (
        <Form form={props.form} onFinish={props.onFinish} layout="vertical" name={props.name} style={props.style}>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <GetSelect disabled={disabledAll} name={'subject_type'} required={true} refer={'006'} text={'subject_type'} func={(value, options) => { props.selectLabel({ subject_type: options.label }) }} />
            </Col>
            <Col span={24}>
                {subject_type === '1' && <Juridical selectLabel={props.selectLabel} form={props.form} disabled={disabledAll} />}
                {subject_type === '2' && <Physical selectLabel={props.selectLabel} form={props.form} disabled={disabledAll} />}
                {subject_type === '3' && <Individual selectLabel={props.selectLabel} form={props.form} disabled={disabledAll} />}
            </Col>
            {((props.debtor && resident === '1') || subject_type === '3') && <>
                {subject_type === '1' && <Debtor_juridical checked={checked} checkSmallB={checkSmallB} selectLabel={props.selectLabel} form={props.form} loc_region={props.loc_region} disabled={disabledAll} client_type={props.client_type} />}
                {subject_type === '2' && <Debtor_physical selectLabel={props.selectLabel} form={props.form} loc_region={props.loc_region} disabled={disabledAll} client_type={props.client_type} />}
                {subject_type === '3' && <Debtor_individual checked={checked} checkSmallB={checkSmallB} form={props.form} loc_region={props.loc_region} selectLabel={props.selectLabel} disabled={disabledAll} client_type={props.client_type} />}
            </>}
            {(props.client_type !== '3' && props.debtor) &&
                <Col span={24}>
                    <GetOthers name={'accepted'} pattern={null} required={true} text={'accepted'} component={'Checkbox'} onChange={(e) => onChange(e.target.checked)} valuePropName="checked" />
                </Col>}
        </Form>
    )
};
export default FormDebitor;