import React from "react";
import { Table } from "antd";
import { getTitle } from "../../../Trans";

export const ViewData = ({ data, username = "" }) => {

  const dataSource = [
    {
      key: 1,
      name: getTitle("Username"),
      value: username,
      // hidden: data.subject_type === 2,
    },
    {
      key: 2,
      name: getTitle("name"),
      value: data.name,
      hidden: data.subject_type === 2,
    },
    {
      key: 3,
      name: getTitle("First name"),
      value: data.first_name,
    },
    {
      key: 4,
      name: getTitle("Last name"),
      value: data.last_name,
    },
    {
      key: 5,
      name: getTitle("Patronymic"),
      value: data.patronymic,
    },
    {
      key: 6,
      name: getTitle("Birth date"),
      value: data.birth_date,
    },
    {
      key: 7,
      name: getTitle("Phone"),
      value: data.phone,
    },
    {
      key: 8,
      name: "Филиал",
      value: data.branch,
    },
    {
      key: 9,
      name: getTitle("Account"),
      value: data.account,
    },
    {
      key: 11,
      name: getTitle("Pin"),
      value: data.pin,
    },
    {
      key: 12,
      name: getTitle("Email"),
      value: data.email,
    },
    {
      key: 13,
      name: "Паспорт серия",
      value: data.passport_s + data.passport_n,
    },

    {
      key: 14,
      name: getTitle("Date and time of registration"),
      value: data.opened,
    },
    // {
    //   key: 14,
    //   name: "Country",
    //   value: data.country_name,
    // },
    {
      key: 15,
      name: getTitle("Address"),
      value: data.address,
    },
    {
      key: 16,
      name: "Резидент",
      value: data.resident_name,
    },
    {
      key: 17,
      name: "Доступ для ввода",
      value: data.enterable_name,
    },
    {
      key: 18,
      name: "Доступ для изменения",
      value: data.printable_name,
    },
    {
      key: 19,
      name: "Статус",
      value: data.state_name,
    },
  ].filter((item) => !item.hidden);

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      width: '40%',
      onCell: (_, index) => {
        return {
          ['style']: { background: '#fafafa', fontWeight: 'bold' },
        }
      },
    },
    {
      dataIndex: "value",
      key: "value",
      width: '60%'
    },
  ];
  
  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
      />
    </>
  );
};
