import { Row, Col, DatePicker, Form } from 'antd';
import { REFERS } from '../../../../../../constants/refers';
import GetInput from '../../../../../../templates/FormInput';
import GetOthers from '../../../../../../templates/FormOther';
import GetSelect from '../../../../../../templates/FormSelect';
import { pattern, date_format } from '../../../../../../templates/Patterns';

const Physical = (props) => {
    const resident = Form.useWatch('resident', props.form);
    return (<>
        <Row gutter={5}>
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <GetInput name={'last_name'} pattern={pattern.fio} required={true} text={'last_name'} disabled={props.disabled} />
            </Col>
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <GetInput name={'first_name'} pattern={pattern.fio} required={true} text={'first_name'} disabled={props.disabled} />
            </Col>
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <GetInput name={'patronymic'} pattern={pattern.fio} required={false} text={'patronymic'} disabled={props.disabled} />
            </Col>
        </Row>
        <Row gutter={5}>
            <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                <GetSelect name={'resident'} required={true} refer={REFERS.conREF_RESIDENT} text={'resident'} func={(value, options) => { props.selectLabel({ resident: options.label }) }} disabled={props.disabled} />
            </Col>
            {resident === '1' &&
                <>
                    <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={'pin'} pattern={pattern.pin} required={true} text={'pin'} disabled={props.disabled} />
                    </Col>
                    <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                        <GetOthers name={'birth_date'} pattern={null} required={true} text={'birth_date'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.disabled} />} />
                    </Col>
                    <Col xxl={2} xl={2} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={'passport_s'} pattern={pattern.passportS} required={true} text={'passport_s'} disabled={props.disabled} />
                    </Col>
                    <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={'passport_n'} pattern={pattern.passportN} required={true} text={'passport_n'} disabled={props.disabled} />
                    </Col>
                </>}
            {resident === '2' &&
                <>
                    <Col xxl={2} xl={2} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={'passport_s'} pattern={pattern.passportForeignS} required={true} text={'passport_s'} disabled={props.disabled} />
                    </Col>
                    <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={'passport_n'} pattern={pattern.passportForeignN} required={true} text={'passport_n'} disabled={props.disabled} />
                    </Col>
                    <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                        <GetOthers name={'birth_date'} pattern={null} required={true} text={'birth_date'} component={<DatePicker format={date_format} style={{ width: '100%' }} />} disabled={props.disabled} />
                    </Col>
                    <Col xxl={9} xl={9} lg={12} md={12} sm={24} xs={24}>
                        <GetSelect name={'country'} required={true} refer={REFERS.conREF_COUNTRY} text={'country'} func={(value, options) => { props.selectLabel({ country: options.label }) }} disabled={props.disabled} />
                    </Col>
                </>
            }
            <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                <GetInput name={'phone'} pattern={pattern.phone} text={'phone'} required={true} addonBefore={'+'} disabled={props.disabled} />
            </Col>
            <Col xxl={4} xl={4} lg={12} md={24} sm={24} xs={24}>
                <GetInput name={'email'} pattern={pattern.email} required={true} text={'email'} disabled={props.disabled} />
            </Col>
        </Row>
        <Row gutter={5}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                <GetInput name={'address'} required={true} text={'address'} disabled={props.disabled} />
            </Col>
        </Row>
    </>
    )
}
export default Physical;