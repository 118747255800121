import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Form } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import TextForm from "./components/TextForm";
import FileForm from "./components/FileForm";
import { getTitle } from "../../../Trans";
import { loadingState } from "../../../../redux/slices/LoadingSlice";

const ContentStatic = (props) => {
  const [textform] = Form.useForm();
  const [fileform] = Form.useForm();
  const loading = useSelector(loadingState);
  const [contentId, setContentId] = useState(0);
  const [initTextForm, setInitTextForm] = useState({
    short_text_ru: "",
    short_text_uz: "",
    short_text_oz: "",
    short_text_en: "",
    text_ru: "",
    text_uz: "",
    text_oz: "",
    text_en: "",
  });
  const [initFileform, setInitFileForm] = useState([]);

  const hookApi = ApiHooks();

  const loadData = async () => {
    const result = await hookApi("content/getContentStaticData", {
      type: props.type * 1,
    });
    if (result?.data) {
      setContentId(result.data.id);
      setInitTextForm(result.data.text);
      setInitFileForm(result.data.docs);
      // setInitValue(result.data);
      textform.setFieldsValue(result.data.text);
    }
  };

  const handleTextForm = async (values) => {
    await hookApi(
      "content/saveContentStaticData",
      {
        content_id: contentId,
        data: values,
      },
      true
    );
  };

  const handleFileForm = async (values) => {
    const file = values.upload_file?.file;
    let obj = {};
    obj.content_id = contentId;
    obj.doc_id = values.doc_id || null;
    obj.mime_type = file?.type || null;
    obj.doc_type = values.doc_type;
    obj.doc_url = values.doc_url || null;
    obj.doc_name_ru = values.doc_name_ru;
    obj.doc_name_uz = values.doc_name_uz;
    obj.doc_name_oz = values.doc_name_en;
    obj.doc_name_en = values.doc_name_en;
    const data = new FormData();
    data.append("upload_file", file?.originFileObj);
    data.append("data", JSON.stringify(obj));
    const result = await hookApi(
      "content/saveContentStaticDataFile",
      data,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    );
    if (result?.code === 0) {
      fileform.resetFields();
      loadData();
    }
  };

  const handleFileDelete = async (doc_id) => {
    const result = await hookApi(
      "content/deleteContentStaticDataFile",
      {
        content_id: contentId,
        doc_id: doc_id,
      },
      true
    );
    if (result?.code === 0) {
      loadData();
      let docs = initFileform;
      docs = docs.filter((item) => item.key !== doc_id);
      setInitFileForm(docs);
    }
  };

  useEffect(() => {
    loadData();
  }, [props.type]);

  const tabItems = [
    {
      key: "1",
      label: getTitle("Content texts"),
      children: (
        <TextForm
          form={textform}
          onFinish={handleTextForm}
          data={initTextForm}
          loading={loading}
        />
      ),
      disabled: loading,
    },
    {
      key: "2",
      label: getTitle("Attached content files"),
      children: (
        <FileForm
          form={fileform}
          onFinish={handleFileForm}
          loading={loading}
          data={initFileform}
          deleteFile={handleFileDelete}
        />
      ),
      disabled: loading,
    },
  ];

  return (
    <Tabs
      size="large"
      style={{
        background: "#fefefe",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "500px",
      }}
      items={tabItems}
    />
  );
};

export default ContentStatic;
