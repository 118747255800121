import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { getApi } from "../../web/api";
import Slider from "react-slick";
import { conf } from "../../config";

const settings = {
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};
function OrgLinks() {
  const [content, setContent] = useState([]);
  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    let res = await getApi(`${conf.server}main/org_links`);
    setContent(res ? res : []);
  };
  return (
    <Row className="org-links-row">
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Slider {...settings}>
          {content.map((obj) => (
            <a
              href={obj.URL_GOVER}
              target="_blank"
              rel="noopener noreferrer"
              key={obj.URL_GOVER}
            >
              <Col className="org-links-col">
                <Col>
                  <img
                    className="org-links-img"
                    src={`${conf.server}static/${obj.URL_SERVER}`}
                    alt=""
                  />
                </Col>
                <Col className="org-links-text">{obj.TITLE}</Col>
              </Col>
            </a>
          ))}
        </Slider>
      </Col>
    </Row>
  );
}
export default OrgLinks;
