import React from 'react';
import { Form, Row, Col, Button, DatePicker } from 'antd';
import Trans, { getTitle } from '../../../../Trans';
import GetSelect from '../../../../templates/FormSelect';
import { REFERS } from '../../../../constants/refers';
import ExportExcel from '../../../../templates/ExportExcel';
import GetOthers from '../../../../templates/FormOther';
import GetInput from '../../../../templates/FormInput';
import {
  date_format,
  month_format,
  pattern,
} from '../../../../templates/Patterns';

const DateRangePicker = ({ namePrefix, dateType, dateFormat }) => {
  return (
    <>
      <Col lg={3} md={12} xs={24}>
        <GetOthers
          name={`${namePrefix}bdate`}
          pattern={null}
          required={true}
          text={'Begin date'}
          component={
            <DatePicker
              format={dateFormat}
              style={{ width: '100%' }}
              disabled={false}
              picker={dateType === 'month' ? 'month' : 'date'}
            />
          }
        />
      </Col>
      <Col lg={3} md={12} xs={24}>
        <GetOthers
          name={`${namePrefix}edate`}
          pattern={null}
          required={true}
          text={'End date'}
          component={
            <DatePicker
              format={dateFormat}
              style={{ width: '100%' }}
              disabled={false}
              picker={dateType === 'month' ? 'month' : 'date'}
            />
          }
        />
      </Col>
    </>
  );
};

const StatisticsFilterForm = (props) => {
  const titleAmountSum = getTitle('Total');

  const {
    selectLabel,
    setType,
    disabled,
    columns,
    fileName,
    resetForm,
    form,
    sendObj,
    excel,
    onFinish,
    initialValues,
    summaryTitleSize,
  } = props;

  const type = Form.useWatch('type', form);

  const formConfig = {
    form: form,
    onFinish: onFinish,
    disabled: disabled,
    name: 'filterForm',
    layout: 'vertical',
    className: 'form-back',
    initialValues: initialValues,
  };

  const isDateRangeType = ['14', '15'].includes(type);
  const isMonthRangeType = ['16', '17'].includes(type);
  const isRangeType = isDateRangeType || isMonthRangeType;

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={8} xl={10} lg={14} md={24} xs={24}>
          <GetSelect
            name={'type'}
            refer={REFERS.conREF_STATISTIC_SETTINGS}
            text={'Search'}
            required={true}
            func={(value, options) => {
              selectLabel(options?.label);
              setType(value);
              form.setFieldValue('client_role', null);
              resetForm(true);
            }}
          />
        </Col>

        {isDateRangeType && (
          <DateRangePicker
            namePrefix=""
            dateType="date"
            dateFormat={date_format}
          />
        )}
        {isMonthRangeType && (
          <DateRangePicker
            namePrefix=""
            dateType="month"
            dateFormat={month_format}
          />
        )}
        {(isRangeType || type === '3') && (
          <Col xl={4} md={8} xs={24}>
            <GetSelect
              name={'client_role'}
              refer={REFERS.conREF_CLIENT_ROLES}
              text={'Client role'}
              func={(value) => {
                form.setFieldValue('client_role', value);
              }}
            />
          </Col>
        )}
        {isRangeType && (
          <>
            <Col xl={3} md={6} sm={12} xs={24}>
              <GetInput
                required={false}
                maxLength={300}
                name={'client_name'}
                text={'Company name'}
              />
            </Col>
            <Col xl={3} md={6} sm={12} xs={24}>
              <GetInput
                pattern={pattern.client_code}
                required={false}
                name={'client_code'}
                text={'Client code'}
              />
            </Col>
          </>
        )}
        {(type === '15' || type === '17') && (
          <Col xl={3} md={6} sm={12} xs={24}>
            <GetInput
              pattern={pattern.login}
              required={false}
              name={'user_name'}
              text={'Username'}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={'Search'} />
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              resetForm();
            }}
          >
            <Trans alias={'Reset'} />
          </Button>
          {excel && (
            <ExportExcel
              disabled={disabled}
              columns={columns}
              fileName={fileName}
              url={`statistic/getStatisticByParam`}
              sendObj={sendObj}
              summaryTitleSize={summaryTitleSize}
              titleAmountSum={titleAmountSum}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default StatisticsFilterForm;
