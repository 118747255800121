import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Table, Space, Badge } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ApiHooks from "../../../../../hooks/ApiHooks";
import Confirm from "../../../../templates/Confirm";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import Trans, { getTitle } from "../../../../Trans";
import GetTextArea from "../../../../templates/FormTextArea";
import ModalComponent from "../../../../templates/ModalComponent";

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    return (
        <td {...restProps}>
            {editing ? (
                <GetTextArea name={dataIndex} required={true} maxLength={300} />
            ) : (
                children
            )}
        </td>
    );
};

const SiteSettingsForm = () => {

    const hookApi = ApiHooks();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [modal, setModal] = useState(false);
    const [reqData, setReqData] = useState();
    const [modalTitle, setModalTitle] = useState();
    const loading = useSelector(loadingState);
    const isEditing = (record) => record.id === editingKey;

    useEffect(() => {
        getExternalServiceList();
    }, []);

    const modalOnOff = (record) => {
        setModal(true);
        setReqData(record);
        setModalTitle(record.setting === 'false' ? 'Are you sure you want to disable this?' : record.setting === 'true' ? "Are you sure you want to enable this?" : null)
    };

    const setSiteSetting = async () => {
        let obj = {};
        obj.id = reqData.id;
        obj.setting = reqData.setting === 'false' ? 'true' : reqData.setting === 'true' ? 'false' : null;
        const result = await hookApi("settings/setSiteSetting", { data: obj })
        if (result) {
            await getExternalServiceList();
            setModal(false);
        }
    };

    const getExternalServiceList = async () => {
        const result = await hookApi("settings/getSiteSettingsList", {});
        if (result.data) {
            setEditingKey('');
            setData(result.data.data);
        }
    };

    const edit = (record) => {
        form.setFieldsValue({ id: '', setting: '', ...record });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (id) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => id === item.id);
            if (index > -1) {
                let item = newData[index];
                let data = { ...item, ...row }
                newData.splice(index, 1, { ...data });
                let obj = {};
                obj.id = data.id;
                obj.setting = data.setting;
                let setRef = await hookApi("settings/setSiteSetting", { data: obj }, true);
                if (setRef.code === 0) {
                    setData(newData);
                    setEditingKey('');
                }
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: "id",
            key: 'id',
            align: "center",
            fixed: true,
        },
        {
            title: getTitle('name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: getTitle('Settings'),
            dataIndex: 'setting',
            align: "center",
            editable: true,
            render: (_, record) => (
                <>
                    {record.setting_type === 0 && <Badge count={record.setting} style={{ backgroundColor: `${record.setting === 'true' ? "#52c41a" : "#f5222d"}` }} />}
                    {record.setting_type === 1 && <>{record.setting}</>}
                </>
            ),
            key: 'setting'
        },
        {
            dataIndex: 'edit',
            align: "center",
            key: 'edit',
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Confirm
                            shape="circle"
                            func={() => save(record.id)}
                            title={"Do you really want to save?"}
                            btnDisabled={loading}
                            btnIcon={<CheckOutlined />}
                            btnType={'success'}
                            btnTitle={"Save"}
                        />
                        <Confirm
                            shape="circle"
                            func={cancel}
                            title={"Cancel changed"}
                            btnDisabled={loading}
                            btnIcon={<CloseOutlined />}
                            btnType={'danger'}
                            btnTitle={"Cancel"}
                        />
                    </Space>
                ) : (<Space>
                    {record.setting_type === 1 &&
                        <Button
                            title="Редактировать"
                            type="primary"
                            disabled={editingKey !== ''}
                            shape="circle"
                            onClick={() => edit(record)}
                            icon={<EditOutlined />} />
                    }
                    {(record.setting_type === 0 && record.setting === 'false') &&
                        <Button
                            title="Включить"
                            disabled={editingKey !== ''}
                            type="success"
                            shape="circle"
                            icon={<CheckOutlined />}
                            onClick={() => modalOnOff(record)}
                        />}
                    {(record.setting_type === 0 && record.setting === 'true') &&
                        <Button
                            title="Отключить"
                            disabled={editingKey !== ''}
                            type="danger"
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => modalOnOff(record)}
                        />}
                </Space>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return { ...col, onCell: (record) => ({ record, dataIndex: col.dataIndex, title: col.title, editing: isEditing(record) }) };
    });

    return (
        <>
            <Form form={form} component={false}>
                <Table
                    loading={loading}
                    size='middle'
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{ onChange: cancel, showSizeChanger: false, defaultPageSize: 15 }}
                />
            </Form>
            <ModalComponent
                title={<Trans alias={"Disabling and enabling"} />}
                open={modal}
                data={getTitle(modalTitle)}
                onOk={() => setSiteSetting()}
                onCancel={() => setModal(false)}
                loading={loading}
                cancelTitle={<Trans alias={"No"} />}
                okTitle={<Trans alias={"Yes"} />}
            />
        </>
    );
};

export default SiteSettingsForm;
