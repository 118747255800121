import { Form, DatePicker, Button, Space } from "antd";
import { date_format } from "../../../../../templates/Patterns";
import Trans, { getTitle } from "../../../../../Trans";

const FilterForm = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    name: "filterForm",
    layout: "inline",
    className: "form-back",
    disabled: props.loading,
    initialValues: props.initialValues,
  };
  return (
    <Form {...formConfig}>
      <Form.Item label={getTitle("Begin date")} name="bdate">
        <DatePicker format={date_format} />
      </Form.Item>
      <Form.Item label={getTitle("End date")} name="edate">
        <DatePicker format={date_format} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button
            onClick={() => {
              props.form.resetFields();
              props.form.submit();
            }}
          >
            <Trans alias={"Reset"} />
          </Button>
          <Button type="primary" onClick={props.openEmpty}>
            <Trans alias={"Create"} />
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FilterForm;
