import { Button } from 'antd';
import { FileExcelOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";

const ExportExcelTable = (props) => {

    const excel = () => {
        let col = props.columns;
        col.forEach((item) => {
            if (item.children) {
                item.children.forEach((obj) => {
                    obj.render = "";
                });
            }
            if (item.render && item.dataIndex !== 'index') {
                item.render = ''
            }
        });
        const excel = new Excel();
        excel
            .setTHeadStyle({ background: 'FFE6F4FF' })
            .addSheet("Лист")
            .addColumns(col)
            .addDataSource(props.dataSource)
            .saveAs(props.fileName.substr(0,100) + ".xlsx");
    };
    return (
        <Button type='success' onClick={() => excel()} disabled={props.disabled} icon={<FileExcelOutlined />}>Excel</Button>
    )
};
export default ExportExcelTable;