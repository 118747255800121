import { configureStore } from "@reduxjs/toolkit";
import SiteSlice from "../slices/SiteSlice";
import AuthSlice from "../slices/AuthSlice";
import RefersSlice from "../slices/RefersSlice";
import LoadingSlice from "../slices/LoadingSlice";

export default configureStore({
  reducer: {
    site: SiteSlice,
    auth:AuthSlice,
    refers:RefersSlice,
    loading: LoadingSlice
  },
});

