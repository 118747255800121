import React, { useState, useEffect } from "react";
import { Card, Statistic, Avatar } from "antd";
import {
  UserOutlined,
  ExclamationOutlined,
  CarOutlined,
  HomeOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import Slider from "react-slick";
import ApiHooks from "../../../hooks/ApiHooks";

function MainStatistics() {

  const hookApi = ApiHooks();
  const [data, setData] = useState([]);

  useEffect(() => {
    get_main_statistics();
  }, []);

  const get_main_statistics = async () => {
    const result = await hookApi("/main/get_main_statictics", {});
    if (result.code === 0) {
      setData(result.data);
    }
  };

  let obj = {
    UnorderedListOutlined: UnorderedListOutlined,
    UserOutlined: UserOutlined,
    CarOutlined: CarOutlined,
    HomeOutlined: HomeOutlined,
    ExclamationOutlined: ExclamationOutlined
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <Slider {...settings}>
        {data.map((element) => (
          <Card className="main-statistics-card" key={element.value + element.icon}>
            <Statistic
              key={element.value}
              // title={element.title}
              value={element.value}
              precision={0}
              // valueStyle={{ color: "#3f8600",}}
              prefix={
                <Avatar
                  className={element.className}
                  // style={{ backgroundColor: `${element.color}` }}
                  icon={React.createElement(obj[element.icon])}
                />
              }
            />
            <div style={{ marginTop: '10px' }}>{element.title}</div>
          </Card>
        ))}
      </Slider>
    </>
  );
}
export default MainStatistics;
