import React from "react";
import { Table } from "antd";

import { getTitle } from "../../../Trans";

export const ViewDataJuridical = ({ data }) => {
  const dataSource = [
    {
      key: 1,
      name: getTitle("Username"),
      value: data.username,
    },
    {
      key: 2,
      name: getTitle("First name"),
      value: data.first_name,
    },
    {
      key: 3,
      name: getTitle("Last name"),
      value: data.last_name,
    },
    {
      key: 10,
      name: getTitle("Patronymic"),
      value: data.patronymic,
    },
    {
      key: 4,
      name: getTitle("Pin"),
      value: data.pin,
    },
    {
      key: 5,
      name: getTitle("Birth date"),
      value: data.birth_date,
    },
    {
      key: 6,
      name: getTitle("Email"),
      value: data.email,
    },
    {
      key: 7,
      name: getTitle("Phone"),
      value: data.phone,
    },
    {
      key: 9,
      name: "Серия пасспорта",
      value: `${data.passport_seria} ${data.passport_number}`,
    },
    {
      key: 8,
      name: getTitle("Date and time of registration"),
      value: data.opened,
    },
  ];

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      width: '40%',
      onCell: (_, index) => {
        return {
          ['style']: { background: '#fafafa', fontWeight: 'bold' },
        }
      },
    },
    {
      dataIndex: "value",
      key: "value",
      width: '60%'
    },
  ];

  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        showHeader={false}
      />
    </>
  );
};
