import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { getTitle } from '../../../../../Trans';
import MonitoringTable from '../../../../../templates/MonitoringTable';
import HighChartComponent from '../../../../../templates/HightChartComponent';
import TableSummaryComponent from './table-summary-component';

const StatisticsObligationSize = (props) => {
  const titleCount = getTitle('Count');
  const titleName = getTitle('name');
  const titleClient = getTitle('Client code');
  const { statistics, setColumns, loading, type, setSummaryTitleSize } = props;
  const titleSize = 3;

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      width: 35,
      align: 'center',
    },
    {
      title: titleClient,
      dataIndex: 'client_code',
      key: 'client_code',
      align: 'center',
    },
    {
      title: titleName,
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: titleCount,
      dataIndex: 'dec_count',
      key: 'dec_count',
      align: 'center',
    },
  ];

  useEffect(() => {
    setSummaryTitleSize(titleSize);
    setColumns(columns);
  }, []);

  const summaryData = [{ value: statistics?.total?.dec_count }];

  const pieData = statistics?.data?.map((value) => ({
    name: value.client_name,
    y: value.dec_count,
  }));

  return (
    <Row gutter={[16, 16]}>
      <Col lg={10} xs={24}>
        <MonitoringTable
          size={'middle'}
          columns={columns}
          dataSource={statistics?.data}
          loading={loading}
          pagination={{ pageSize: 10, showSizeChanger: false }}
          bordered={true}
          rowKey={(record) => record.order}
          summary={() => (
            <TableSummaryComponent
              titleColSpan={titleSize}
              summaryData={summaryData}
            />
          )}
        />
      </Col>
      <Col lg={14} xs={24}>
        <Row>
          <Col xs={24}>
            <HighChartComponent
              type="pie"
              title={titleCount}
              series={[{ name: '', data: pieData }]}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default StatisticsObligationSize;
