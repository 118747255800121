import React from "react";
import { useState } from "react";
import { Form, Row, Col, Select, Button } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import GetInput from "../../../../../templates/FormInput";
import { pattern } from "../../../../../templates/Patterns";
import CadastreInput from "../../../../../templates/CadastreInput";

const FilterForm = (props) => {
  const [visibleInp, setVisibleInp] = useState(0);
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };
  const options = [
    { value: 1, label: getTitle("Code") },
    { value: 2, label: getTitle("Inn") },
    { value: 3, label: getTitle("Pin") },
    { value: 4, label: getTitle("element_car") },
    { value: 5, label: getTitle("element_cadastre") },
    { value: 6, label: getTitle("tech_num") }
  ];
  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
          <Form.Item name='filter' label={'Реквизиты поиска'} rules={[{ required: true, message: getTitle("Props is a must") }]}>
            <Select options={options} onChange={setVisibleInp} />
          </Form.Item>
        </Col>
        {visibleInp === 1 &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput pattern={pattern.declaration_code} name={"code"} required={true} text={"Code"} />
          </Col>}
        {visibleInp === 2 &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput pattern={pattern.inn} name={"inn"} required={true} text={"Inn"} />
          </Col>}
        {visibleInp === 3 &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput pattern={pattern.pin} name={"pin"} required={true} text={"Pin"} />
          </Col>}
        {visibleInp === 4 &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput pattern={pattern.en_number} name={"body_num"} required={true} text={"element_car"} />
          </Col>}
        {visibleInp === 5 &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <CadastreInput name={'cadastre'} text={'element_cadastre'} />
          </Col>}
        {visibleInp === 6 &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput name={'tech_num'} required={true}  text={'tech_num'} />
          </Col>}
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => {
              props.form.resetFields();
              props.form.submit();
              props.resetData();
            }}
          >
            <Trans alias={"Reset"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
