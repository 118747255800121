import { Col, Row, Pagination } from 'antd';
import { useEffect } from 'react';
import { getTitle } from '../../../../../Trans';
import MonitoringTable from '../../../../../templates/MonitoringTable';
import TableSummaryComponent from './table-summary-component';

const StatisticsTotalClientAdmin = (props) => {
  const dec_add_count = getTitle('dec_add_count');
  const dec_upd_count = getTitle('dec_upd_count');
  const dec_del_count = getTitle('dec_del_count');
  const dec_order_count = getTitle('dec_order_count');
  const notary_ban_count = getTitle('notary_ban_count');
  const notary_cancel_count = getTitle('notary_cancel_count');
  const notary_search_count = getTitle('notary_search_count');
  const police_search_count = getTitle('police_search_count');
  const clientCode = getTitle('Client code');
  const clientName = getTitle('Company name');
  const user_name = getTitle('Username');
  const user_fio = getTitle('User');
  const stat_date = getTitle('Date');

  const {
    statistics,
    setColumns,
    loading,
    dataCount,
    pageSize,
    currentPage,
    handleChangePage,
    setSummaryTitleSize,
  } = props;

  const isSpecialType = props.type === '14' || props.type === '16';
  const titleSize = isSpecialType ? 4 : 6;

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      align: 'center',
    },
    {
      title: stat_date,
      dataIndex: 'stat_date',
      align: 'center',
    },
    {
      title: clientCode,
      dataIndex: 'client_code',
      align: 'center',
    },
    {
      title: clientName,
      dataIndex: 'client_name',
    },
    {
      title: user_name,
      dataIndex: 'user_name',
      hidden: isSpecialType,
    },
    {
      title: user_fio,
      dataIndex: 'user_fio',
      align: 'center',
      hidden: isSpecialType,
    },
    {
      title: dec_add_count,
      dataIndex: 'dec_add_count',
      align: 'center',
    },
    {
      title: dec_upd_count,
      dataIndex: 'dec_upd_count',
      align: 'center',
    },
    {
      title: dec_del_count,
      dataIndex: 'dec_del_count',
      align: 'center',
    },
    {
      title: dec_order_count,
      dataIndex: 'dec_order_count',
      align: 'center',
    },
    {
      title: notary_ban_count,
      dataIndex: 'notary_ban_count',
      align: 'center',
    },
    {
      title: notary_cancel_count,
      dataIndex: 'notary_cancel_count',
      align: 'center',
    },
    {
      title: notary_search_count,
      dataIndex: 'notary_search_count',
      align: 'center',
    },
    {
      title: police_search_count,
      dataIndex: 'police_search_count',
      align: 'center',
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setColumns(columns);
    setSummaryTitleSize(titleSize);
  }, []);

  const summaryData = [
    { value: statistics?.total?.dec_add_count },
    { value: statistics?.total?.dec_upd_count },
    { value: statistics?.total?.dec_del_count },
    { value: statistics?.total?.dec_order_count },
    { value: statistics?.total?.notary_ban_count },
    { value: statistics?.total?.notary_cancel_count },
    { value: statistics?.total?.notary_search_count },
    { value: statistics?.total?.police_search_count },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <MonitoringTable
          size="small"
          columns={columns}
          dataSource={statistics?.data}
          loading={loading}
          pagination={false}
          bordered={true}
          rowKey={(record) => record.order}
          summary={() => (
            <TableSummaryComponent
              titleColSpan={titleSize}
              summaryData={summaryData}
            />
          )}
        />
        {dataCount > pageSize && (
          <Pagination
            style={{ marginTop: 10 }}
            disabled={loading}
            defaultCurrent={1}
            current={currentPage}
            total={dataCount}
            pageSize={pageSize}
            onChange={handleChangePage}
            showSizeChanger={false}
          />
        )}
      </Col>
    </Row>
  );
};
export default StatisticsTotalClientAdmin;
