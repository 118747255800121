import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Pagination, Divider, Badge, Button } from "antd";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import { ReloadOutlined } from "@ant-design/icons"
import MonitoringTable from "../../../../templates/MonitoringTable";
import ModalComponent from "../../../../templates/ModalComponent";
import moment from "moment";
import { date_format } from "../../../../templates/Patterns";
import Trans, { getTitle } from "../../../../Trans";

const EmailUsers = () => {
  const pageSize = 20;
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
    action_id: "-1",
    error: "0"
  };

  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState();
  const [modal, setModal] = useState();

  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    email: null,
    state: null,
    page: 1,
    page_size: pageSize,
  });

  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    const result = await hookApi("monitoring/getEmailUser", { data: sendObj, });
    if (result.data) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const resendModal = (id) => {
    setId(id);
    setModal(true);
  };

  const resendMail = async (id) => {
    const result = await hookApi("monitoring/resendEmailUser", { id: id, });
    if (result) {
      loadContent();
      setModal(false);
    }
  }

  const handleFilterForm = async (values) => {
    const data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.email = values.email ? values.email : null;
    data.state = values.state ? values.state : null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      align: 'center',
      fixed: 'left',
      width: '2%'
    },
    {
      title: getTitle('email'),
      dataIndex: "email",
      key: "email",
      fixed: 'left',
      width: '13%'
    },
    {
      title: getTitle("Date and time of registration"),
      dataIndex: "registered",
      key: "registered",
      align: 'center',
      width: '8%'
    },
    {
      title: getTitle("Date and time of sending"),
      dataIndex: "sended",
      key: "sended",
      align: 'center',
      width: '8%'
    },
    {
      title: getTitle("Theme"),
      dataIndex: "theme",
      key: "theme",
      onCell: (_, index) => {
        return {
          ['style']: { maxWidth: '200px' }
        }
      },
      width: '8%'
    },
    {
      title: getTitle("Content texts"),
      dataIndex: "content",
      key: "content",
      onCell: (_, index) => {
        return {
          ['style']: { maxWidth: '200px' }
        }
      },
      width: '15%'
    },
    {
      title: getTitle("Activation code"),
      dataIndex: "act_code",
      key: "act_code",
      align: 'center',
      width: '10%'
    },
    {
      title: getTitle("Activation code status"),
      dataIndex: "act_code_name",
      key: "act_code_name",
      align: 'center',
      width: '7%'
    },
    {
      title: getTitle("Configuration parameters"),
      dataIndex: "config",
      onCell: (_, index) => {
        return {
          ['style']: { maxWidth: '100px' }
        }
      },
      key: "config",
      width: '12%'
    },
    {
      title: getTitle("Error text"),
      dataIndex: "err_msg",
      key: "err_msg",
      width: '10%'
    },
    {
      title: getTitle('State'),
      dataIndex: "state",
      key: "state",
      align: 'center',
      fixed: 'right',
      width: '7%',
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{ backgroundColor: `${record.state === 2 ? "#52c41a" : record.state === 9 ? "#f5222d" : record.state === 0 ? "#08979c" : "#2f54eb"}` }}
        />
      ),
    },
    {
      title: "",
      key: "buttons",
      fixed: 'right',
      width: '1%',
      render: (_, record) => (
        <Button
          size="small"
          title="Переотправить"
          disabled={loading}
          type="danger"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => resendModal(record.id)}
        />
      ),
    },
  ];

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        initialValues={defaultFilterForm}
      />
      <Divider />
      <MonitoringTable
        size='small'
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
      />
      <ModalComponent
        open={modal}
        data={getTitle("Resend notification")}
        onOk={() => resendMail(id)}
        onCancel={() => setModal(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default EmailUsers;
