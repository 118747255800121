export const getJsonResult = async (obj) => {
    let js = { creditor: {}, debtor: {}, object: {} };

    js.code = obj.code ? obj.code : null;
    js.xcode = obj.pledge.xcode ? obj.pledge.xcode : null;
    js.right_date = obj.pledge.right_date ? obj.pledge.right_date : null;
    js.right_code = obj.pledge.right_code ? obj.pledge.right_code : null;
    js.right_type = obj.pledge.right_type ? obj.pledge.right_type : null;
    js.doc_type = obj.pledge.doc_type ? obj.pledge.doc_type : null;
    js.client_type = obj.pledge.client_type ? obj.pledge.client_type : null;
    js.doc_n = obj.pledge.doc_n ? obj.pledge.doc_n : null;
    js.doc_d = obj.pledge.doc_d ? obj.pledge.doc_d : null;
    js.expired = obj.pledge.expired ? obj.pledge.expired : null;
    js.amount = obj.pledge.amount ? obj.pledge.amount : null;

    js.creditor.inn = obj.creditor.inn ? obj.creditor.inn : null;
    js.creditor.name = obj.creditor.name ? obj.creditor.name : null;
    js.creditor.subject_type = obj.creditor.subject_type ? obj.creditor.subject_type : null;
    js.creditor.resident = obj.creditor.resident ? obj.creditor.resident : null;
    js.creditor.org_form = obj.creditor.org_form ? obj.creditor.org_form : null;
    js.creditor.okpo = obj.creditor.okpo ? obj.creditor.okpo : null;
    js.creditor.first_name = obj.creditor.first_name ? obj.creditor.first_name : null;
    js.creditor.last_name = obj.creditor.last_name ? obj.creditor.last_name : null;
    js.creditor.patronymic = obj.creditor.patronymic ? obj.creditor.patronymic : null;
    js.creditor.phone = obj.creditor.phone ? obj.creditor.phone : null;
    js.creditor.email = obj.creditor.email ? obj.creditor.email : null;
    js.creditor.address = obj.creditor.address ? obj.creditor.address : null;
    js.creditor.branch = obj.creditor.branch ? obj.creditor.branch : null;
    js.creditor.account = obj.creditor.account ? obj.creditor.account : null;
    js.creditor.passport_s = obj.creditor.passport_s ? obj.creditor.passport_s : null;
    js.creditor.passport_n = obj.creditor.passport_n ? obj.creditor.passport_n : null;
    js.creditor.country = obj.creditor.country ? obj.creditor.country : null;
    js.creditor.pin = obj.creditor.pin ? obj.creditor.pin : null;
    js.creditor.birth_date = obj.creditor.birth_date ? obj.creditor.birth_date : null;

    js.debtor.inn = obj.debtor.inn ? obj.debtor.inn : null;
    js.debtor.name = obj.debtor.name ? obj.debtor.name : null;
    js.debtor.subject_type = obj.debtor.subject_type ? obj.debtor.subject_type : null;
    js.debtor.resident = obj.debtor.resident ? obj.debtor.resident : null;
    js.debtor.org_form = obj.debtor.org_form ? obj.debtor.org_form : null;
    js.debtor.okpo = obj.debtor.okpo ? obj.debtor.okpo : null;
    js.debtor.first_name = obj.debtor.first_name ? obj.debtor.first_name : null;
    js.debtor.last_name = obj.debtor.last_name ? obj.debtor.last_name : null;
    js.debtor.patronymic = obj.debtor.patronymic ? obj.debtor.patronymic : null;
    js.debtor.phone = obj.debtor.phone ? obj.debtor.phone : null;
    js.debtor.email = obj.debtor.email ? obj.debtor.email : null;
    js.debtor.address = obj.debtor.address ? obj.debtor.address : null;
    js.debtor.branch = obj.debtor.branch ? obj.debtor.branch : null;
    js.debtor.account = obj.debtor.account ? obj.debtor.account : null;
    js.debtor.passport_s = obj.debtor.passport_s ? obj.debtor.passport_s : null;
    js.debtor.passport_n = obj.debtor.passport_n ? obj.debtor.passport_n : null;
    js.debtor.country = obj.debtor.country ? obj.debtor.country : null;
    js.debtor.pin = obj.debtor.pin ? obj.debtor.pin : null;
    js.debtor.birth_date = obj.debtor.birth_date ? obj.debtor.birth_date : null;
    js.debtor.accepted = obj.debtor.accepted ? obj.debtor.accepted : null;
    js.debtor.education = obj.debtor.education ? obj.debtor.education : null;
    js.debtor.region = obj.debtor.region ? obj.debtor.region : null;
    js.debtor.loc_region = obj.debtor.loc_region ? obj.debtor.loc_region : null;
    js.debtor.oked = obj.debtor.oked ? obj.debtor.oked : null;
    js.debtor.sex = obj.debtor.sex ? obj.debtor.sex : null;
    js.debtor.age = obj.debtor.age ? obj.debtor.age : null;
    js.debtor.small_business = obj.debtor.small_business ? obj.debtor.small_business : null;

    js.object.provision_group = obj.object.provision_group ? obj.object.provision_group : null;
    js.object.provision_type = obj.object.provision_type ? obj.object.provision_type : null;
    js.object.description = obj.object.description ? obj.object.description : null;
    js.object.element_value = obj.object.element_value ? obj.object.element_value : null;
    js.object.element_cost = obj.object.element_cost ? obj.object.element_cost : null;
    js.object.element_year = obj.object.element_year ? obj.object.element_year : null;
    js.object.element_code = null;

    if (obj.object.provision_type === '101' || obj.object.provision_type === '102' || obj.object.provision_type === '706') {
        js.object.element_code = '3'
    }
    if (obj.object.provision_type === '201' || obj.object.provision_type === '202' || obj.object.provision_type === '203' || obj.object.provision_type === '204' || obj.object.provision_type === '205' || obj.object.provision_type === '206') {
        js.object.element_code = '7'
    }
    if (obj.object.provision_type === '701' || obj.object.provision_type === '702') {
        js.object.element_code = '37'
    }
    // if (obj.object.provision_group==='100') {
    //     js.object.element_code = '3'
    // }
    // if (obj.object.provision_group === '200') {
    //     js.object.element_code = '7'
    // }
    // if (obj.object.provision_group === '700') {
    //     js.object.element_code = '37'
    // }
    return js;
}