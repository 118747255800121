import { Form, Tabs, Row, Col } from "antd";
import { getTitle } from "../../../../Trans";
import Confirm from "../../../../templates/Confirm";
import TextFormItems from "./TextFormItems";

const TextForm = (props) => {
  // const contentTypes = {
  //   functions: 3,
  //   regulation: 4,
  //   responsibilities: 6,
  //   structure: 7,
  //   info: 8,
  //    charter: 23,
  //    site_lang: 24,
  //   contacts: 5,
  //   faq: 18,
  // };
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    name: "textform",
    layout: "vertical",
    disabled: props.loading,
  };

  const tabItems = [
    {
      key: "1",
      forceRender: true,
      label: getTitle("Text in Russian"),
      children: (
        <TextFormItems
          form={props.form}
          initialTextValue={props.data.text_ru}
          short_text_name={"short_text_ru"}
          short_text_label={"Short text in Russian"}
          text_name={"text_ru"}
          text_label={"Text in Russian"}
        />
      ),
      disabled: props.loading,
    },
    {
      key: "2",
      forceRender: true,
      label: getTitle("Text in Uzbek"),
      children: (
        <TextFormItems
          form={props.form}
          initialTextValue={props.data.text_uz}
          short_text_name={"short_text_uz"}
          short_text_label={"Short text in Uzbek"}
          text_name={"text_uz"}
          text_label={"Text in Uzbek"}
        />
      ),
      disabled: props.loading,
    },
    {
      key: "3",
      forceRender: true,
      label: getTitle("Text in Uzbek (latin)"),
      children: (
        <TextFormItems
          form={props.form}
          initialTextValue={props.data.text_oz}
          short_text_name={"short_text_oz"}
          short_text_label={"Short text in Uzbek (latin)"}
          text_name={"text_oz"}
          text_label={"Text in Uzbek (latin)"}
        />
      ),
      disabled: props.loading,
    },
    {
      key: "4",
      forceRender: true,
      label: getTitle("Text in English"),
      children: (
        <TextFormItems
          form={props.form}
          initialTextValue={props.data.text_en}
          short_text_name={"short_text_en"}
          short_text_label={"Short text in English"}
          text_name={"text_en"}
          text_label={"Text in English"}
        />
      ),
      disabled: props.loading,
    },
  ];

  return (
    <Form {...formConfig}>
      <Row justify="end">
        <Col>
          <Confirm
            form={props.form}
            title={"Do you really want to save?"}
            btnText={"Save"}
            btnDisabled={props.loading}
          />
        </Col>
      </Row>
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        size="large"
        tabBarGutter={25}
        items={tabItems}
        style={{
          background: "#fefefe",
          padding: "10px",
          borderRadius: "5px",
          minHeight: "500px",
        }}
      />
    </Form>
  );
};

export default TextForm;
