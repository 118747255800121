import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadingState } from "../../../../../../redux/slices/LoadingSlice";
import ApiHooks from "../../../../../../hooks/ApiHooks";
import { Form } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import { date_format } from "../../../../../templates/Patterns";
import ModalComponent from "../../../../../templates/ModalComponent";
import moment from "moment";
import FormInfoCancelBan from "./FormInfoCancelBan";
import FormSuccess from "./FormSuccess";

const FormCancelBan = () => {

    const [form] = Form.useForm();
    const { id } = useParams();
    const hookApi = ApiHooks();
    const loading = useSelector(loadingState);
    const [modalAddBan, setModalAddBan] = useState(false);
    const [sendData, setSendData] = useState([]);
    const [banInfo, setBanInfo] = useState([]);
    const new_car = Form.useWatch('new_car', form);
    const [successForm, setSuccessForm] = useState(false);
    const [responseBan, setResponseBan] = useState();

    useEffect(() => {
        if (id) {
            getBan(id);
        };
    }, []);

    const getBan = async (ban_id) => {
        const obj = {};
        const result = await hookApi("record/notaryPrevCancelBanData", { ban_id: ban_id });
        if (result.data) {
            obj.code = result.data.dec_code;
            obj.ban_num = result.data.ban_num;
            obj.ban_date = result.data.ban_date;
            obj.ban_edate = result.data.ban_edate;

            obj.creditor_name = result.data.creditor_name;
            obj.creditor_inn = result.data.creditor_inn;

            obj.debtor_name = result.data.debtor_name;
            obj.debtor_inn_pin = result.data.debtor_inn ? result.data.debtor_inn : result.data.debtor_pin;
            obj.debtor_pass_s = result.data.debtor_pass_s;
            obj.debtor_pass_n = result.data.debtor_pass_n;
            obj.debtor_birth_date = result.data.debtor_birth_date;
            obj.debtor_address = result.data.debtor_address;
            obj.debtor_district = result.data.debtor_district_name;

            obj.doc_type = result.data.doc_type ? result.data.doc_type : null;
            obj.doc_num = result.data.doc_num ? result.data.doc_num : null;
            obj.doc_date = result.data.doc_date ? moment(result.data.doc_date) : null;

            setBanInfo(result.data);
            form.setFieldsValue(obj);
        }
    };

    const notaryModal = (values) => {
        setModalAddBan(true);
        setSendData(values)
    }

    const setBan = async () => {
        let obj = {};
        obj.ban_id = id;
        obj.reg_num = banInfo.ban_num;
        obj.code = sendData.code;
        obj.doc_type = sendData.doc_type;
        obj.doc_num = sendData.doc_num;
        obj.doc_date = moment(sendData.doc_date).format(date_format);
        const result = await hookApi("record/notaryCancelBan", { data: obj }, 'ban');
        if (result) {
            setModalAddBan(false);
            if (result.data.err_code === '0') {
                result.data.ban_id = id;
                result.data.ban_num = banInfo.ban_num;
                setResponseBan(result.data);
                setSuccessForm(true);
            }
        }
    };
    return (
        <>
            {successForm && <FormSuccess responseBan={responseBan} />}
            {!successForm && <FormInfoCancelBan banInfo={banInfo} notaryModal={notaryModal} form={form} new_car={new_car} />}
            <ModalComponent
                title={<Trans alias={"Cancel ban in Notary"} />}
                open={modalAddBan}
                data={getTitle("Do you really want to cancel ban in the Notary") + '?'}
                onOk={() => setBan()}
                onCancel={() => setModalAddBan(false)}
                loading={loading}
                cancelTitle={<Trans alias={"No"} />}
                okTitle={<Trans alias={"Yes"} />}
            />
        </>
    )
};

export default FormCancelBan;
