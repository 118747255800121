import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined, MailOutlined, ExclamationOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Pagination, Divider, Button, Badge, Space } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import ViewData from "./components/ViewData";
import DrawerComponent from '../../../templates/DrawerComponent'
import Trans, { getTitle } from "../../../Trans";
import FilterForm from "./components/FilterForm";
import { client_role } from "../../../../redux/slices/AuthSlice";
import MonitoringTable from "../../../templates/MonitoringTable";
import FilterFormModal from "./components/FilterFormModal";
import DrawerChat from "./components/DrawerChat";
import ModalComponent from "../../../templates/ModalComponent";
import GetTextArea from "../../../templates/FormTextArea";

const ProtestForm = () => {
  const pageSize = 20;
  const [filter] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [form] = Form.useForm();
  const [dec_form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataDetail, setDataDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalProtest, setModalProtest] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState(false);
  const [modalConfirmProtest, setModalConfirmProtest] = useState(false);
  const [modalCloseProtest, setModalCloseProtest] = useState(false);
  const [sendObj, setSendObj] = useState({ type: null, code: null, username: null, debtor_inn_pin: null, page: 1, page_size: pageSize });
  const loading = useSelector(loadingState);
  const [comments, setComments] = useState([]);
  const [protestId, setProtestId] = useState();
  const [protestState, setProtestState] = useState();

  const client = useSelector(client_role);
  const hookApi = ApiHooks();

  const type = Form.useWatch('type', filter);

  useEffect(() => {
    setDataSource([]);
  }, [type]);

  const getProtestList = async (data) => {
    const result = await hookApi("/record/get_declaration_protest_list", { data: data });
    if (result.code === 0) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
    }
  }

  const sendProtest = async (values) => {
    const result = await hookApi("/record/set_declaration_protest", { data: values }, 'success');
    if (result.code === 0) {
      getProtestList({ type: '0', code: null, username: null, debtor_inn_pin: null, page: 1, page_size: pageSize });
    }
    setModalProtest(false)
  };

  const getHistoryMsg = async (id, state) => {
    setProtestId(id);
    setProtestState(state)
    const result = await hookApi("/record/get_declaration_protest_msgs", { data: { protest_id: id } });
    if (result.code === 0) {
      let arr = [];
      result.data.data.forEach(element => {
        arr.push({ author: <div style={{ color: `${element.type === 0 ? "#061178" : "#f5222d"}` }}>{element.client_name}</div>, content: element.message, datetime: element.registered })
      });
      setComments(arr);
      setModalMsg(true);
    }
  }

  const sendProtestMsG = async (values) => {
    const result = await hookApi("/record/set_declaration_protest_msg", { data: { protest_id: protestId, message: values.message } });
    if (result.code === 0) {
      getHistoryMsg(protestId);
    }
  };

  const getConfirmDecProtest = async (id) => {
    setProtestId(id);
    setModalConfirmProtest(true);
  }

  const changeProtestDec = async (values) => {
    const result = await hookApi("/record/change_declaration_protest", { data: { protest_id: protestId, message: values.message } }, 'success');
    if (result.code === 0) {
      setModalConfirmProtest(false);
      getProtestList({ type: '0', code: null, username: null, debtor_inn_pin: null, page: 1, page_size: pageSize });
    }
  };
  const getCloseDecProtest = async (id) => {
    setProtestId(id);
    setModalCloseProtest(true);
  }

  const closeProtest = async () => {
    const result = await hookApi("/record/close_declaration_protest", { data: { protest_id: protestId } }, 'success');
    if (result.code === 0) {
      setModalCloseProtest(false);
      getProtestList({ type: '0', code: null, username: null, debtor_inn_pin: null, page: 1, page_size: pageSize });
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    handleFilterForm(sendObj, value);
  };

  const handleFilterForm = async (values, page) => {
    const data = {};
    data.type = values.type ? values.type : null;
    data.code = values.code ? values.code : null;
    data.username = values.username ? values.username : null;
    data.debtor_inn_pin = values.debtor_inn_pin ? values.debtor_inn_pin : null;
    data.page_size = pageSize
    data.page = page || 1;
    getProtestList(data)
    setSendObj({ ...sendObj, ...data });
  };

  const info = async (code) => {
    const result = await hookApi("record/getDeclarationView", { code });
    const dataTable = (<ViewData data={result.data} />);
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: 'Код записи',
      dataIndex: 'dec_code',
      align: 'center'
    },
    {
      title: 'Инициатор записи',
      dataIndex: 'dec_client_name'
    },
    {
      title: 'Логин инициатора',
      dataIndex: 'dec_user_name',
      hidden: (type === '0')
    },
    {
      title: 'Должник',
      dataIndex: 'client_name',
      hidden: (type === '1')
    },
    {
      title: 'ИНН/ПИФНЛ должника',
      dataIndex: 'debtor_inn_pin',
      hidden: (type === '0'),
      align: 'center'
    },
    {
      title: 'Дата внесения',
      dataIndex: 'opened',
      align: 'center'
    },
    {
      title: 'Дата удаления',
      dataIndex: 'closed'
    },
    {
      title: 'Тема',
      dataIndex: 'theme'
    },
    {
      title: 'Осталось дней до ввода возражения',
      dataIndex: 'day',
      align: 'center'
    },
    {
      title: 'Статус возражения',
      dataIndex: "state",
      fixed: 'right',
      align: 'center',
      render: (text, record) => (<Badge count={record.state_name} style={{ backgroundColor: `${record.state === 1 ? "#52c41a" : record.state === 0 ? "#fadb14" : "#f5222d"}` }} />),
      key: "state",
    },
    {
      title: "Действие",
      key: "buttons",
      align: 'center',
      fixed: 'right',
      render: (_, record) => (
        <Space>
          <Button disabled={loading} type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => info(record.dec_code)} />
          <Button disabled={loading} type="info" shape="circle" icon={<MailOutlined />} onClick={() => getHistoryMsg(record.id, record.state)} />
          {(record.day < 1 && record.owner) &&
            <Button disabled={loading} type="warning" shape="circle" icon={<ExclamationOutlined />} onClick={() => getConfirmDecProtest(record.id)} />
          }
          {record.state !== 2 &&
            <Button disabled={loading} type="danger" shape="circle" icon={<DeleteOutlined />} onClick={() => getCloseDecProtest(record.id)} />
          }
        </Space>
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <FilterForm /*sendObj={sendObj}*/ form={filter} onFinish={handleFilterForm} disabled={loading} setModalProtest={setModalProtest} client={client} />
      <Divider />
      <DrawerComponent title={<Trans alias={"Show data"} />} open={modalOpen} data={dataDetail} onCancel={() => setModalOpen(false)} width={'50%'} />
      <DrawerComponent open={modalProtest} title={<Trans alias={'Send protest'} />} onCancel={!loading ? () => setModalProtest(false) : null} width={'50%'}
        data={<FilterFormModal form={modalForm} onFinish={sendProtest} setModalProtest={setModalProtest} disabled={loading} />} />
      <DrawerChat
        title={'Сообщения'}
        open={modalMsg}
        onCancel={() => setModalMsg(false)}
        width={'40%'}
        comments={comments}
        handleSubmit={sendProtestMsG}
        loading={loading}
        form={form}
        client={client}
        state={protestState}
      />
      <ModalComponent
        title={'Данное сообщение отобразиться на выписки данной записи!'}
        open={modalConfirmProtest}
        onCancel={() => setModalConfirmProtest(false)}
        width={'40%'}
        loading={loading}
        notfooter={true}
        data={
          <Form name={dec_form} onFinish={changeProtestDec}>
            <GetTextArea name='message' required={true} rows={3} />
            <Form.Item>
              <Button htmlType="submit" loading={loading} type="primary" style={{ margin: "10px" }}>
                {getTitle('Send')}
              </Button>
            </Form.Item>
          </Form>}
        client={client}
      />
      <ModalComponent
        title={'Удаление возражения'}
        open={modalCloseProtest}
        data={'Удалить данное возражение?'}
        onOk={() => closeProtest()}
        onCancel={() => setModalCloseProtest(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <MonitoringTable
        size='small'
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey={(record) => record.id}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
          showTotal={(total) => `Всего ${total}`}
        />
      )}
    </>
  );
};

export default ProtestForm;
