import { Col, DatePicker, Form, Row } from "antd";
import React from "react";
import GetInput from "../../../../../templates/FormInput";
import { date_format, pattern } from "../../../../../templates/Patterns";
import { getTitle } from "../../../../../Trans";
import Confirm from "../../../../../templates/Confirm";

export const UserJuridicForm = (props) => {
  return (
    <Form
      form={props.form}
      name={props.name}
      layout="vertical"
      onFinish={props.onFinish}
      disabled={props.disabled}
    >
      <Row gutter={5}>
        <Col span={8}>
          <GetInput
            name={"first_name"}
            // pattern={pattern.name}
            required={true}
            text={"First name"}
          />
        </Col>
        <Col span={8}>
          <GetInput
            name={"last_name"}
            // pattern={pattern.name}
            required={true}
            text={"Last name"}
          />
        </Col>
        <Col span={8}>
          <GetInput
            name={"patronymic"}
            // pattern={pattern.name}
            required={true}
            text={"Patronymic"}
          />
        </Col>
        <Col span={8}>
          <GetInput name={"username"} pattern={pattern.login} required={true} text={"Username"} />
        </Col>

        <Col span={2}>
          <GetInput
            name={"passport_s"}
            pattern={pattern.passportS}
            required={true}
            text={"passport_s"}
          />
        </Col>
        <Col span={6}>
          <GetInput
            name={"passport_n"}
            pattern={pattern.passportN}
            required={true}
            text={"passport_n"}
          />
        </Col>
        <Col span={8}>
          <GetInput name={"pin"} pattern={pattern.pin} required={true} text={"Pin"} />
        </Col>
        <Col span={8}>
          <GetInput name={"email"} pattern={pattern.email} required={true} text={"Email"} />
        </Col>
        <Col span={8}>
          <GetInput name={"phone"} pattern={pattern.phone} required={true} text={"Phone"} />
        </Col>
        <Col span={8}>
          <Form.Item
            label={getTitle("Birth date")}
            name="birth_date"
            rules={[{ required: true, message: "Error" }]}
          >
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>

        <Col span={10}>
          <GetInput name={"address"} required={true} text={"Address"} />
        </Col>
        <Col span={24}>
          <Confirm
            form={props.form}
            title={"Do you really want to save?"}
            btnText={"Save"}
            btnDisabled={props.disabled}
          />
        </Col>
      </Row>
    </Form>
  );
};
