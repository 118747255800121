import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm";
import ClientForm from "./ClientForm";
import { useSelector } from "react-redux";
import { userAuth } from "../../../redux/slices/AuthSlice";
import { Row, Col, Dropdown } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UserOutlined,
  EnvironmentOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Trans from "../../Trans";

const langsList = [
  {
    title: "ЎЗБ",
    lang: "uz",
  },
  {
    title: "O‘ZB",
    lang: "oz",
  },
  {
    title: "РУС",
    lang: "ru",
  },
  {
    title: "ENG",
    lang: "en",
  },
];

function HeaderLink() {
  const [loginType, setLoginType] = useState(false);
  const [clientType, setClientType] = useState(false);
  const [gray, setGray] = useState(0);
  const lang = localStorage.getItem("lang");
  const user = useSelector(userAuth);

  const changeLang = (param) => {
    if (param !== lang) {
      localStorage.setItem("lang", param);
      window.location.reload();
    }
  };

  if (!lang) changeLang("uz");
  useEffect(() => {
    document.getElementById("root").style.filter = `grayscale(${gray})`;
  }, [gray]);

  const showLogin = () => {
    setLoginType(!loginType);
    setClientType(!clientType);
  };

  return (
    <Row className="header-link">
      <Col span={12}>
        <Row>
          <Col>
            <Link to="#" className="header-link-btn" onClick={() => setGray(gray ^ 1)}>
              <Row className="header-link-btn-row">
                <Col className="header-link-btn-col">
                  {gray ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </Col>
                <Col className="hide-header-text">
                  <Trans alias={"headerlink.visually_impaired"} />
                </Col>
              </Row>
            </Link>
          </Col>
          <Col>
            <Link to="/contacts" className="header-link-btn">
              <Row className="header-link-btn-row">
                <Col className="header-link-btn-col">
                  <EnvironmentOutlined />
                </Col>
                <Col className="hide-header-text">
                  <Trans alias={"headerlink.contacts"} />
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row justify="end">
          {langsList.map((elm) => (
            <Col key={elm.title} className={lang === elm.lang ? "header-link-btn-active" : ""}>
              <a href="#" className="header-link-btn-lang" onClick={() => changeLang(elm.lang)}>
                {elm.title}
              </a>
            </Col>
          ))}
          <Col>
            <Link to="#" className="header-link-btn header-link-btn-lang-dropdown">
              <Row className="header-link-btn-row">
                <Col className="header-link-btn-col">
                  <Dropdown
                    overlay={
                      <div className="header-link-dropdown">
                        {langsList.map((elm) => (
                          <div
                            key={elm.title}
                            className={lang === elm.lang ? "header-link-btn-active" : ""}
                          >
                            <a href="#" onClick={() => changeLang(elm.lang)}>
                              {elm.title}
                            </a>
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <DownOutlined />
                  </Dropdown>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col>
            <Link to="#" className="header-link-btn" onClick={showLogin}>
              <Row className="header-link-btn-row">
                <Col className="header-link-btn-col">
                  <UserOutlined className="header-link-btn-user" />
                </Col>
                <Col className="hide-header-text">
                  <Trans alias={"Personal cabinet"} />
                </Col>
              </Row>
            </Link>
            {user
              ? clientType && <ClientForm modal={clientType} showLogin={() => showLogin()} />
              : loginType && <LoginForm modal={loginType} showLogin={() => showLogin()} />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default HeaderLink;
