import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Divider } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import { getTitle } from "../../../Trans";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../templates/MonitoringTable";
import moment from "moment";
import { date_format } from "../../../templates/Patterns";

const RequestStatisticsForm = () => {
  const defaultFilterForm = { bdate: moment(), edate: moment() };
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [excel, setExcel] = useState(false);
  const [selectLabel, setSelectLabel] = useState();
  const [fileName, setFileName] = useState('');
  const [columns, setColumns] = useState([]);
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();
  
  const totalTrans = getTitle("Total");
  const successTrans = getTitle("Success");
  const errorsTrans = getTitle("Erroneous");
  const Undefineds = getTitle("Undefineds");

  let col = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      width: 35,
      render: (text, record, index) => (index + 1),
      fixed: 'left',
      align: 'center'
    },
    {
      title: getTitle("name"),
      dataIndex: "name",
      key: "name",
      fixed: 'left',
    },
    {
      title: getTitle('Total number'),
      align: 'center',
      key: 'Total number',
      children: [
        {
          title: totalTrans,
          width: 50,
          dataIndex: "all_total",
          fixed: 'left',
          onCell: () => ({ className: 'blue-td' }),
          align: "center",
        },
        {
          title: successTrans,
          fixed: 'left',
          width: 50,
          dataIndex: "all_success",
          onCell: () => ({ className: 'green-td' }),
          align: "center",
        },
        {
          title: errorsTrans,
          fixed: 'left',
          width: 50,
          dataIndex: "all_error",
          onCell: () => ({ className: 'red-td' }),
          align: "center",
        },
      ]
    }
  ]
  const getColumns = async () => {
    const refers = await hookApi("settings/getInternalServiceList", { code: filter.getFieldValue('internal_service') });
    if (refers.data.data) {
      refers.data.data.forEach((element, index) => {
        let obj = {
          title: element.name,
          align: 'center',
          fixed: 'left',
          children: [
            {
              title: totalTrans,
              width: 50,
              dataIndex: `m${index + 1}_total`,
              onCell: () => ({ className: 'blue-td' }),
              align: "center",
            },
            {
              title: successTrans,
              width: 50,
              dataIndex: `m${index + 1}_success`,
              onCell: () => ({ className: 'green-td' }),
              align: "center",
            },
            {
              title: errorsTrans,
              width: 50,
              dataIndex: `m${index + 1}_error`,
              onCell: () => ({ className: 'red-td' }),
              align: "center",
            },
          ],
        }
        col.push(obj)
      })
    };
    col.push(
      {
        title: Undefineds,
        align: 'center',
        fixed: 'left',
        children: [
          {
            title: totalTrans,
            width: 50,
            dataIndex: `m0_total`,
            onCell: () => ({ className: 'blue-td' }),
            align: "center",
          },
          {
            title: successTrans,
            width: 50,
            dataIndex: `m0_success`,
            onCell: () => ({ className: 'green-td' }),
            align: "center",
          },
          {
            title: errorsTrans,
            width: 50,
            dataIndex: `m0_error`,
            onCell: () => ({ className: 'red-td' }),
            align: "center",
          },
        ],
      }
    )
    setColumns(col);
  };

  const getRequestCountByMethod = async (values) => {
    let bdate = values.bdate = moment(values.bdate).format(date_format);
    let edate = values.edate = moment(values.edate).format(date_format);
    const result = await hookApi("statistic/getRequestCount", { data: values });
    if (result.data.data?.length) {
      getColumns();
      setDataSource(result.data.data);
      setExcel(true);
      let obj = filter.getFieldsValue();
      obj.bdate = bdate;
      obj.edate = edate;
      setFileName(`${selectLabel}(${obj.bdate}-${obj.edate})`);
    };
  };

  return (
    <div>
      <FilterForm
        form={filter}
        onFinish={getRequestCountByMethod}
        disabled={loading}
        initialValues={defaultFilterForm}
        resetTable={setDataSource}
        setExcel={setExcel}
        excel={excel}
        selectLabel={setSelectLabel}
        setColumns={setColumns}
        columns={columns}
        fileName={fileName}
        dataSource={dataSource}
      />
      <Divider />
      <MonitoringTable
        size='small'
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey={(record) => record.name}
      />
    </div>
  );
};

export default RequestStatisticsForm;
