import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button,Badge } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import { getTitle } from "../../../Trans";
import MonitoringTable from "../../../templates/MonitoringTable";

const CentersList = () => {
  const [dataSource, setDataSource] = useState([]);
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    const result = await hookApi("site/payments/getPaymentCenters", {});
    if (result.data) {
      setDataSource(result.data.data);
    }
  };

  const columns = [
    {
      title: "Код",
      dataIndex: "key",
      key: "key",
      width: '5%',
      align:'center'
    },
    {
      title: getTitle("Name in Russian"),
      dataIndex: "name_ru",
      key: "name_ru",
      width: '20%',
    },
    {
      title: getTitle("Name in Uzbek"),
      dataIndex: "name_uz",
      key: "name_uz",
      width: '20%',
    },
    {
      title: getTitle("Name in Uzbek (Latin)"),
      dataIndex: "name_oz",
      key: "name_oz",
      width: '20%',
    },
    {
      title: getTitle("Name in English"),
      dataIndex: "name_en",
      key: "name_en",
      width: '20%',
    },
    {
      title: getTitle("Username"),
      dataIndex: "username",
      key: "username",
      width: '10%',
    },
    {
      title: getTitle("IP"),
      dataIndex: "ip",
      key: "ip",
      width: '5%',
    },
    {
      title: getTitle("State"),
      dataIndex: "state_name",
      key: "state_name",
      width: '4%',
      align:'center',
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{
            backgroundColor: `${record.state !== 1 ? "#f5222d" : "#52c41a"}`,
          }}
        />
      ),
    },
    {
      title: (
        <Link to={`/main/payments/centers/0`} disabled={loading}>
          <Button
            type="default"
            shape="circle"
            icon={<UsergroupAddOutlined />}
            title={getTitle("Add")}
          />
        </Link>
      ),
      key: "buttons",
      width: '1%',
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          <Link
            title="Просмотр"
            to={`/main/payments/centers/${record.code}`}
            disabled={loading}
          >
            <Button type="danger" shape="circle" icon={<EditOutlined />} />
          </Link>
        </div>
      ),
    },
  ];
  return (
    <MonitoringTable
      columns={columns}
      size ='small'
      dataSource={dataSource}
      loading={loading}
      pagination={false}
      bordered={true}
    />
  );
};

export default CentersList;
