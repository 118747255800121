import React from "react";
import { Row, Col } from "antd";
import MainNews from "./MainNews";
import MainStatistics from "./MainStatistics";
import MainInteractiv from "./MainInteractiv";
function Main() {
  return (
    <>
      <Row>
        <Col span={24}>
          <MainStatistics />
        </Col>
      </Row>
      <MainNews />
      {/* <MainInteractiv /> */}
    </>
  );
}
export default Main;
