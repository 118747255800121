import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Row, Col, Divider, Typography, Button } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import MainRightMenu from "./MainRightMenu";
import { useSelector } from "react-redux";
import { selectPage, selectMenu } from "../../../redux/slices/SiteSlice";
import Trans from "../../Trans";

const { Text } = Typography;

function MainWithSideMenu() {
  const pageSelector = useSelector(selectPage);
  const menuList = useSelector(selectMenu);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [backBtn, setBackBtn] = useState("");
  const location = useLocation();
  useEffect(() => {
    getBreadcrumb();
  }, [menuList, pageSelector]);

  const getBreadcrumb = () => {
    let array = [];
    menuList.forEach((element) => {
      if (element.key === pageSelector.key) {
        array.push(element);
      } else {
        element.children.forEach((element2) => {
          if (element2.key === pageSelector.key) {
            array.push(element);
            array.push(element2);
          } else {
            element2.children.forEach((element3) => {
              if (element3.key === pageSelector.key) {
                array.push(element);
                array.push(element2);
                array.push(element3);
              }
            });
          }
        });
      }
    });
    setBreadCrumb(array);
  };
  useEffect(() => {
    getBackButton();
  }, [breadCrumb, location]);

  const getBackButton = () => {
    setBackBtn("");
    if (breadCrumb.length > 1) {
      let url = breadCrumb[breadCrumb.length - 1].url;
      if (url !== location.pathname) setBackBtn(url);
      if (url === location.pathname)
        setBackBtn(breadCrumb[breadCrumb.length - 2].url);
    }
  };
  return (
    <Row className="main-with-site-menu" justify="end">
      <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24} offset={1}>
        <Row className="breadcrumb-row">
          <Col>
            <Link to="/" className="breadcrumb-link">
              <Trans alias="Home" />
            </Link>
            {breadCrumb.map((element) => (
              <React.Fragment key={element.key}>
                /
                {element.key !== pageSelector.key ? (
                  <Link to={element.url} className="breadcrumb-link">
                    {element.title}
                  </Link>
                ) : (
                  <Text style={{ margin: "10px" }} type="secondary">{element.title}</Text>
                )}
              </React.Fragment>
            ))}
          </Col>
        </Row>
        <div className="page-title">{pageSelector.title}</div>
        <Divider />
        <Outlet />
        <Row>
          <Divider />
          {backBtn ? (
            <Link to={backBtn}>
              <Button
                size="large"
                className="yellow-button"
                icon={<RollbackOutlined />}
              >
                <Trans alias="back" />
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xxl={7} xl={7} lg={7} md={24} sm={24} xs={24} offset={1}>
        <MainRightMenu />
      </Col>
      <Divider />
    </Row>
  );
}

export default MainWithSideMenu;
