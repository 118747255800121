import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button, Select, Divider } from "antd";
import { postApi } from "../../../web/api";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { selectMenu } from "../../../redux/slices/SiteSlice";
import { conf } from "../../../config";
const { Option } = Select;

const settings = {
  arrows: false,
  // autoplay: true,
  dots: true,
  infinite: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  // autoplaySpeed: 7000,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function MainNews() {
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const menuList = useSelector(selectMenu);
  const [contents, setContents] = useState([]);
  const [currentContent, setCurrentContent] = useState([]);

  useEffect(() => {
    getTabs();
  }, [menuList]);

  useEffect(() => {
    loadContent();
  }, [currentTab]);

  const getTabs = () => {
    const arr = menuList.find((elem) => elem.key === 2);
    if (arr) {
      setTabs(arr.children);
      setCurrentTab(arr.children[0].key);
    }
  };

  const loadContent = async () => {
    if (currentTab === 0) return;
    let arr = contents.concat();
    let ind = arr.findIndex((elem) => elem.currentTab === currentTab);
    let temp = {};
    if (ind === -1) {
      const res = await getContent(currentTab);
      temp.contents = res;
      temp.currentTab = currentTab;
      arr.push(temp);
      setContents(arr);
    } else {
      temp = Object.assign({}, arr[ind]);
    }
    setCurrentContent(temp.contents);
  };

  const getContent = async (pCurrentType) => {
    let res = await postApi("main/content_dynamic_list", {
      menu_id: pCurrentType,
      bdate: "",
      edate: "",
      lang: "uz",
      page: "1",
    });
    return res ? res : [];
  };

  return (
    <div className="main-news-row">
      <Row justify="center">
        {tabs.map((element) => (
          <Button
            key={element.key}
            className="main-news-tabs"
            type="link"
            size="large"
            onClick={() => setCurrentTab(element.key)}
            style={{
              borderBottom:
                currentTab === element.key
                  ? "2px solid #000"
                  : "2px solid transparent",
            }}
          >
            {element.title}
          </Button>
        ))}
        <div className="main-news-tabs">
          <Select
            size="large"
            style={{ width: "300px" }}
            defaultValue={17}
            value={currentTab}
            onChange={(value) => setCurrentTab(value)}
          >
            {tabs.map((element) => (
              <Option key={element.key} value={element.key}>
                {element.title}
              </Option>
            ))}
          </Select>
        </div>
      </Row>
      <Divider />
      <Row>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Slider {...settings}>
            {currentContent.map((obj) => (
              <div key={obj.ID}>
                <Row style={{ padding: "10px" }}>
                  {obj.HAVEIMG > 0 && (
                    <Col span={10}>
                      <img
                        className="main-news-img"
                        alt="example"
                        src={`${conf.server}main/content_dynamic/thumb_file/${obj.ID}`}
                      />
                    </Col>
                  )}
                  <Col
                    span={obj.HAVEIMG > 0 ? 14 : 24}
                    className="main-news-col"
                  >
                    <Row>
                      <Col span={24}>
                        <div style={{ textAlign: "end" }}>
                          {obj.CREATED_DATE}
                        </div>
                      </Col>
                      <Col span={24}>
                        <Link to={`press_center/news/${obj.ID}`} title={obj.TITLE}>
                          <Col className="font-news">
                            {obj.TITLE}
                          </Col>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>
    </div>
  );
}
export default MainNews;
