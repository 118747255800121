import React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
import Trans from '../../../Trans';
import GetInput from '../../../templates/FormInput';
import GetSelect from '../../../templates/FormSelect';
import { REFERS } from '../../../constants/refers';

const { Title } = Typography;

export const FilterForm = (props) => {
  const { form, onFinish, disabled, initialValues, title, isClientList } =
    props;
  const formConfig = {
    form: form,
    onFinish: onFinish,
    disabled: disabled,
    name: 'filterForm',
    layout: 'vertical',
    className: 'form-back',
    initialValues: initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={[10, 0]}>
        <Col style={{ marginBottom: '15px' }} xs={24}>
          <Title level={4}>{title}</Title>
        </Col>
        {props?.inputs.map((input, index) => {
          return (
            <Col key={index} xl={3} md={6} sm={12} xs={24}>
              <GetInput {...input} />
            </Col>
          );
        })}
        {isClientList && (
          <>
            <Col xl={3} md={6} sm={12} xs={24}>
              <GetSelect
                name={'client_role'}
                refer={REFERS.conREF_CLIENT_ROLES}
                text={'User type'}
                func={(value) => {
                  form.setFieldValue('client_role', value);
                }}
              />
            </Col>
            <Col xl={3} md={6} sm={12} xs={24}>
              <GetSelect
                name={'state'}
                refer={REFERS.conREF_USER_STATES}
                text={'State'}
                func={(value) => {
                  form.setFieldValue('state', value);
                }}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={'Search'} />
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              form.resetFields();
              form.submit();
            }}
          >
            <Trans alias={'Reset'} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
