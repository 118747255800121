import { Form, Row, Col, Button } from "antd";
import Trans from "../../../../../../Trans";
import GetSelect from '../../../../../../templates/FormSelect'
import { REFERS } from "../../../../../../constants/refers"

const FilterForm = (props) => {

  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back"
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <GetSelect name={'external_service'} refer={REFERS.conREF_EXTERNAL_SERVICE} text={'Web service'} required={true} func={()=>props.resetTable([])}/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button style={{ margin: "0 8px" }} onClick={() => { props.form.resetFields(); props.form.submit();props.resetTable([]);props.setEditingKey('') }}>
            <Trans alias={"Reset"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
