import { Form, Row, Col, DatePicker, Button, Divider } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import { date_format, pattern } from "../../../../../templates/Patterns";
import GetInput from "../../../../../templates/FormInput";
import GetOthers from "../../../../../templates/FormOther";
import GetSelect from "../../../../../templates/FormSelect";
import { REFERS } from "../../../../../constants/refers";

const NotaryBanForm = (props) => {

    const formConfig = {
        onFinish: props.notaryModal,
        name: "addban",
        form: props.form,
        layout: "vertical",
        className: "form-back"
    };

    return (
        <>
            <Form {...formConfig}>
                <Row gutter={10}>
                    <Col span={24}>
                        <Divider orientation="left"><h2>{getTitle('Information about applicants')}</h2></Divider>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={"creditor_name"} text={"name"} disabled={true} />
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                        <GetInput name={"creditor_inn"} text={"Inn"} disabled={true} />
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                        <GetInput name={"code"} text={"Code"} disabled={true} />
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                        <GetInput name={"record_date"} text={"Recording date"} disabled={true} />
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <GetInput name={"doc_type"} text={"type"} disabled={true} />
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <GetInput name={"doc_num"} text={"Number of the document providing"} required={true} disabled={props.banInfo.resend} />
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                        <GetOthers name={'ban_edate'} pattern={null} required={true} text={'Issue Ban'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.banInfo.resend} />} />
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetOthers name={'doc_date'} pattern={null} required={true} text={'Date of the document providing'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.banInfo.resend} />} />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Divider orientation="left"><h2>{getTitle('Information about the pledgor')}</h2></Divider>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                        <GetInput name={"debtor_name"} text={"Name pledgor"} disabled={true} />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                        <GetInput name={"debtor_inn_pin"} text={"Inn/Pin debtor"} disabled={true} />
                    </Col>
                    {props.banInfo.debtor_pin &&
                        <>
                            <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                                <GetInput name={'debtor_pass_s'} disabled={true} text={'passport_s'} />
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                                <GetInput name={'debtor_pass_n'} disabled={true} text={'passport_n'} />
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                                <GetInput name={'debtor_birth_date'} disabled={true} text={'birth_date'} />
                            </Col>
                            <Col xxl={5} xl={5} lg={5} md={12} sm={24} xs={24}>
                                <GetInput name={'issue_org'} required={true} text={'Passport issued by'} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                                <GetOthers name={'issue_date'} pattern={null} required={true} text={'Document issue date'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.banInfo.resend} />} />
                            </Col>
                        </>
                    }
                    <Col xxl={5} xl={5} lg={5} md={12} sm={24} xs={24}>
                        <GetSelect name={'debtor_district'} required={true} refer={REFERS.conREF_SOATO_7} text={'loc_region'} disabled={props.banInfo.resend} />
                    </Col>
                    <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
                        <GetInput name={'debtor_address'} text={'address'} disabled={true} />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Divider orientation="left"><h2>{getTitle('Property Information')}</h2></Divider>
                    </Col>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <GetInput name={'description'} text={'Details property'} required={true} disabled={props.banInfo.resend} />
                    </Col>
                    {props.banInfo.body_num &&
                        <>
                            <Col xxl={4} xl={4} lg={4} md={24} sm={24} xs={24}>
                                <GetInput name={"body_num"} required={true} text={"Body Car Number"} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <GetSelect name={'new_car'} required={true} func={null} refer={REFERS.conREF_NOTARY_CAR_TYPES} text={'State registration of a vehicle'} disabled={props.banInfo.resend} />
                            </Col>
                            {(props.new_car === '0' || props.new_car === '2') && <>
                                <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                    <GetInput name={"engine_num"} required={true} text={"Engine number"} disabled={props.banInfo.resend} />
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                    <GetInput name={"mark"} required={true} text={"Mark"} disabled={props.banInfo.resend} />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                                    <GetInput name={"color"} required={true} text={"Color"} disabled={props.banInfo.resend} />
                                </Col>
                            </>}
                            {props.new_car === '0' &&
                                <>
                                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                        <GetInput name={"state_num"} required={true} text={"State license plate"} disabled={props.banInfo.resend} />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                        <GetInput name={"chassis_num"} required={true} text={"Chassis number"} disabled={props.banInfo.resend} />
                                    </Col>
                                    <Col xxl={2} xl={2} lg={2} md={12} sm={24} xs={24}>
                                        <GetInput name={"year_create"} required={true} text={"Year of issue"} disabled={props.banInfo.resend} />
                                    </Col>
                                    <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
                                        <GetInput name={"tech_serial"} required={true} pattern={pattern.en} text={"Technical passport series"} maxLength={14} disabled={props.banInfo.resend} />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                        <GetInput name={"tech_num"} required={true} text={"Technical passport number"} disabled={props.banInfo.resend} />
                                    </Col>
                                    <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                                        <GetOthers name={'tech_date'} required={true} pattern={null} text={'Document issue date'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={props.banInfo.resend} />} />
                                    </Col>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <GetInput name={"tech_issue"} required={true} text={"Organization issuing the document"} disabled={props.banInfo.resend} />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                        <GetInput name={"vehicle_id"} required={true} text={"Unique vehicle number in police"} disabled={props.banInfo.resend} />
                                    </Col>
                                </>}
                        </>}
                    {props.banInfo.cadastre_num &&
                        <>
                            <Col xxl={4} xl={4} lg={4} md={24} sm={24} xs={24}>
                                <GetInput name={"cadastre_num"} text={"Cadastre Number"} disabled={true} />
                            </Col>
                            {/* <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
                                <GetInput name={"property"} text={"Description of the collateral"} required={true} disabled={props.banInfo.resend} />
                            </Col> */}
                            <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                                <GetSelect name={'district'} required={true} refer={REFERS.conREF_SOATO_7} text={'loc_region'} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                <GetInput name={"street"} text={"Street"} required={true} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                <GetInput name={"home"} text={"House"} required={true} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                <GetInput name={"flat"} text={"Flat"} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
                                <GetInput name={"block"} text={"Housing"} disabled={props.banInfo.resend} />
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <GetInput name={"obj_name"} text={"Object name"} disabled={props.banInfo.resend} />
                            </Col>
                        </>}
                </Row>
                <Row justify="center">
                    {props.banInfo.resend &&
                        <Col>
                            <Button type="danger" htmlType="submit">
                                <Trans alias={"Resend"} />
                            </Button>
                        </Col>
                    }
                    {!props.banInfo.resend &&
                        <Col>
                            <Button type="primary" htmlType="submit">
                                <Trans alias={"Send"} />
                            </Button>
                            <Button style={{ margin: "0 8px" }} onClick={() => { props.form.resetFields() }}>
                                <Trans alias={"Reset"} />
                            </Button>
                        </Col>
                    }
                </Row>
            </Form>
        </>
    )
};

export default NotaryBanForm;
