import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { getLogo } from "../../../config";
import { NewFulllHeaderNav } from "./NewFulllHeaderNav";
import { useOverflowHidden } from "../../../hooks/useOverflowHidden";

function HeaderMobile() {
  const [visible, setVisible] = useState(false);
  const { isHidden, setIsHidden } = useOverflowHidden();

  const showDrawer = () => {
    setVisible(!visible);
    // setIsHidden(!isHidden);
  };

  return (
    <>
      <Row justify="center" align="middle" className="header-mobile-row">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row>
            <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
              <MenuOutlined onClick={showDrawer} />
            </Col>
            <Col xxl={21} xl={21} lg={21} md={21} sm={21} xs={21}>
              <Link to="/">
                <img src={getLogo()} alt="" />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <HeaderFullNav visible={visible} onClose={showDrawer} /> */}
      <NewFulllHeaderNav visible={visible} onClose={showDrawer} />
    </>
  );
}

export default HeaderMobile;
