import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined, FileProtectOutlined, EditOutlined, DeleteOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Pagination, Divider, Button, Badge } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import ViewData from "./components/ViewData";
import ModalComponent from "../../../templates/ModalComponent";
import DrawerComponent from '../../../templates/DrawerComponent'
import Trans, { getTitle } from "../../../Trans";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../templates/MonitoringTable";
import moment from "moment";
import { date_format } from "../../../templates/Patterns";
import { access_ } from "../../../../redux/slices/AuthSlice";
import { client_role } from "../../../../redux/slices/AuthSlice";
import GetOrder from "../../../templates/GetOrder";
import FormPaymentPay from "../order/components/FormPaymentPay";

const RecordMonitoringForm = () => {
  const pageSize = 20;
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
    action_id: "-1",
    error: "0"
  };
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataDetail, setDataDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOrder, setModalOrder] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalNotary, setModalNotary] = useState(false);
  const [record_code, setRecord_code] = useState('');
  const [payment, setPayment] = useState();
  const [disExcel, setDisExcel] = useState(true);
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    code: null,
    client_name: null,
    user_login: null,
    source: null,
    debtor_inn_pin: null,
    debtor_name: null,
    // element_value:null,
    page: 1,
    page_size: pageSize
  });
  const loading = useSelector(loadingState);
  const access = useSelector(access_);
  const client = useSelector(client_role);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    const result = await hookApi("record/getDeclarationList", { data: sendObj });
    if (result.data) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
      if (result.data.count > 0)
        setDisExcel(false)
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    setPayment();
    const data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.code = values.code;
    data.client_name = values.client_name;
    data.user_login = values.user_login;
    data.source = values.source;
    data.debtor_inn_pin = values.debtor_inn_pin;
    data.debtor_name = values.debtor_name;
    // data.element_value = values.element_value?values.element_value.replaceAll("_", ""):null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const info = async (code) => {
    const result = await hookApi("record/getDeclarationView", { code });
    const dataTable = (<ViewData data={result.data} />);
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const getConfirm = (code) => {
    setRecord_code(code);
    setModalOrder(true);
  }

  const editRecord = (code) => {
    setRecord_code(code);
    setModalEdit(true);
  }

  const deleteModal = (code) => {
    setRecord_code(code);
    setModalDelete(true);
  }

  const notaryModal = (code) => {
    setRecord_code(code);
    setModalNotary(true);
  }

  const deleteRecord = async (code) => {
    const result = await hookApi("record/deleteDeclaration", { code: code });
    if (result) {
      setModalDelete(false);
      loadContent();
    }
  };

  const blankNotary = async (code) => {
    window.open(`/main/record/notary/addban/${code}`, "_blank");
    // const result = await hookApi("record/notaryAddBan", { code: code });
    // if (result) {
    setModalNotary(false);
    loadContent();
    // }
  };

  const onPayment = async (res) => {
    setModalOrder(false)
    let result = await hookApi("record/getOrder", { code: record_code }, 'order');
    if (result.payment_id) {
      setPayment(result);
    }
  }

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
      align: "center",
      fixed: 'left',
    },
    {
      title: getTitle('Code'),
      dataIndex: "code",
      align: 'center',
      fixed: 'left',
      key: "code",
    },
    {
      title: getTitle("User"),
      dataIndex: "client_name",
      key: "client_name",
      hidden: (!access.record_list_super),
      width: 300,
    },
    {
      title: getTitle("Administrator/Register"),
      dataIndex: "user_name",
      key: "user_name",
      width: 300,
    },
    {
      title: getTitle("Date and time of registration"),
      dataIndex: "registered",
      align: 'center',
      width: 150,
      key: "registered",
    },
    {
      title: getTitle("Date and time of deletion"),
      dataIndex: "erased",
      align: 'center',
      width: 150,
      key: "erased",
    },
    {
      title: getTitle("End date"),
      dataIndex: "expired",
      align: 'center',
      key: "expired",
    },
    {
      title: getTitle("Amount of liabilities"),
      dataIndex: "amount",
      align: 'center',
    },
    {
      title: getTitle("Record version"),
      dataIndex: "version",
      align: 'center',
      width: 70,
      key: "version",
    },
    {
      title: getTitle('Ban number'),
      dataIndex: "ban",
      align: 'center',
      width: 80,
      render: (text, record) => (<span>{record.ban.ban_num}</span>),
      key: "ban.ban_num",
    },
    {
      title: getTitle("Ban date"),
      dataIndex: "ban",
      width: 100,
      align: 'center',
      render: (text, record) => (<span>{record.ban.ban_date}</span>),
      key: "ban.ban_date",
    },
    {
      title: getTitle('Issue Ban'),
      dataIndex: "ban",
      align: 'center',
      width: 100,
      render: (text, record) => (<span>{record.ban.ban_edate}</span>),
      key: "ban.ban_edate",
    },
    {
      title: getTitle("Ban state"),
      dataIndex: "ban",
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Badge
          count={record.ban.ban_state_name}
          style={{ backgroundColor: `${record.ban.ban_state === 1 ? "#52c41a" : "#f5222d"}` }}
        />
      ),
      key: "ban.ban_state_name",
    },
    {
      title: getTitle("State"),
      dataIndex: "state",
      fixed: 'right',
      align: 'center',
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{ backgroundColor: `${record.state === 1 ? "#52c41a" : "#f5222d"}` }}
        />
      ),
      key: "state",
    },
    {
      title: "",
      key: "buttons",
      align: 'center',
      fixed: 'right',
      render: (_, record) => (
        <>
          <Button
            disabled={loading}
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => info(record.code)}
          />
          {(((record.printable === 1 && access.record_order_certified) || access.record_order_super) &&
            <Button
              disabled={loading}
              type="success"
              shape="circle"
              icon={<FileProtectOutlined />}
              onClick={() => getConfirm(record.code)}
            />)
          }
          {(record.state === 1 && access.record_update && record.version === 3 &&
            <Button
              disabled={loading}
              type="warning"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => editRecord(record.code)}
            />)}
          {(record.state === 1 && access.record_delete &&
            <Button
              disabled={loading}
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteModal(record.code)}
            />)}
          {(record.can_add_ban &&
            <Button
              disabled={loading}
              type="lightdark"
              shape="circle"
              icon={<LockOutlined />}
              onClick={() => notaryModal(record.code)}
            />)}
        </>
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <FilterForm
        sendObj={sendObj}
        form={filter}
        onFinish={handleFilterForm}
        disExcel={disExcel}
        setDisExcel={() => setDisExcel(true)}
        disabled={loading}
        initialValues={defaultFilterForm}
        role={{ record_list_admin: access.record_list_admin, record_list_super: access.record_list_super }}
      />
      <Divider />
      <DrawerComponent
        title={<Trans alias={"Show data"} />}
        open={modalOpen}
        data={dataDetail}
        onCancel={() => setModalOpen(false)}
        width={'60%'}
      />
      <GetOrder modal={modalOrder} onCancel={() => setModalOrder()} record_code={record_code} onPayment={() => onPayment()} client={client} />
      <ModalComponent
        title={<Trans alias={"Editing a post"} />}
        open={modalEdit}
        data={getTitle("Do you really want to change the entry") + ' ' + record_code + '?'}
        onOk={() => window.location.href = `/main/record/edit/${record_code}`}
        onCancel={() => setModalEdit(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Deleting record"} />}
        open={modalDelete}
        data={getTitle("Are you sure you want to delete the entry") + ' ' + record_code + '?'}
        onOk={() => deleteRecord(record_code)}
        onCancel={() => setModalDelete(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Setting a ban in Notary"} />}
        open={modalNotary}
        data={getTitle("Do you really want to set a recording ban in the Notary") + ' ' + record_code + '?'}
        onOk={() => blankNotary(record_code)}
        onCancel={() => setModalNotary(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <MonitoringTable
        size='small'
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
        rowKey={(record) => record.key}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
          showTotal={(total) => `Всего ${total}`}
        />
      )}
      {payment &&
        <FormPaymentPay payment_id={payment.payment_id} loading={loading} />
      }
    </>
  );
};

export default RecordMonitoringForm;
