import React from "react";
import { Form, Row, Col, DatePicker, Button } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import GetInput from "../../../../../templates/FormInput";
import GetSelect from "../../../../../templates/FormSelect";
import { date_format } from "../../../../../templates/Patterns";
import { REFERS } from '../../../../../constants/refers'

const FilterForm = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
          <Form.Item label={getTitle("Begin date")} name="bdate">
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>
        <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
          <Form.Item label={getTitle("End date")} name="edate">
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput name={"email"} text={"email"} />
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetInput name={"code"} text={"Code"} />
        </Col>
        <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
          <GetSelect name={'state'} refer={REFERS.conREF_EMAIL_STATE} text={'State'} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => {
              props.form.resetFields();
              props.form.submit();
            }}
          >
            <Trans alias={"Reset"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
