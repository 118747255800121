import axios from "axios";
import { conf } from "../config";
import Cookies from 'js-cookie';
import { ErrorsApi } from '../web/errors';
export const lang = localStorage.getItem("lang");

export const api = axios.create({
  withCredentials: true,
  baseURL: conf.server,
  timeout: 3000 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const tokenConfig = () => {
  let token = Cookies.get("token");
  let config = {
    headers: {
      'token': token
    }
  }
  return config;
};


export const news = async () => {
  try {
    const res = await api.post("main/content_dynamic_list", { pBeginDate: "", pEndDate: "", pLang: "uz", pPage: "1" });
    return res.data;
  } catch (error) {
    ErrorsApi(error);
  }
}

export const getApi = async (url, wihtoutLang) => {
  try {
    let address = url;
    if (!wihtoutLang) {
      address += `/${lang}`;
    }
    const res = await api.get(address);
    return res.data;
  } catch (error) {
    ErrorsApi(error);
  }
};

export const postApi = async (url, obj) => {
  try {
    obj.lang = lang;
    const res = await api.post(url, obj);
    return res.data;
  } catch (error) {
    ErrorsApi(error);
  }
}

export const getCapt = async (url) => {
  try {
    const res = await api.get(url);
    return res.data;
  } catch (error) {
    ErrorsApi(error);
  }
};