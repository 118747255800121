import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../../../Trans';
import GetBanPdf from '../../../../../templates/GetBanPdf';
import { loadingState } from '../../../../../../redux/slices/LoadingSlice';

const FormSuccess = (props) => {
    const loading = useSelector(loadingState);
    const [modal, setModal] = useState(false);
    return (
        <>
            <Row justify='center'>
                <Col>
                    <h3>{props.responseBan.response.res_note}</h3>
                </Col>
            </Row>
            <Row justify='center'>
                <Col>
                    <Space>
                        <Button type='success' size='large' onClick={() => setModal(true)} loading={loading}>{getTitle('Get PDF')}</Button>
                        <Link to='/main/record/notary/list'><Button type='warning' size='large' loading={loading}>{getTitle('Register of prohibitions')}</Button></Link>
                    </Space>
                </Col>
            </Row>
            <GetBanPdf modal={modal} onCancel={() => setModal()} titleId={props.responseBan.ban_num} id={props.responseBan.ban_id}
                url={'/record/getNotaryBanPDF'} title={"Create PDF"} text={"Create a file for arrest"} />
        </>
    )
};
export default FormSuccess;