import { Tabs } from "antd";
import { getTitle } from "../../../../../Trans";
import FileFormItem from "./FileFormItem";
import FileFormList from "./FileFormList";

const FileForm = (props) => {
  const tabItems = [
    {
      key: "1",
      label: getTitle("File form"),
      children: (
        <FileFormItem
          form={props.form}
          onFinish={props.onFinish}
          loading={props.loading}
        />
      ),
      disabled: props.loading,
    },
    {
      key: "2",
      label: getTitle("List of files"),
      children: (
        <FileFormList
          data={props.data}
          deleteFile={props.deleteFile}
          loading={props.loading}
        />
      ),
      disabled: props.loading,
    },
  ];
  return (
    <Tabs
      tabPosition="left"
      defaultActiveKey="1"
      size="large"
      tabBarGutter={25}
      items={tabItems}
      style={{
        background: "#fefefe",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "500px",
      }}
    />
  );
};

export default FileForm;
