import React from "react";
import { Drawer, Comment, List, Form, Button } from "antd";
import { getTitle } from "../../../../Trans";
import GetTextArea from "../../../../templates/FormTextArea";
import { clientRoles } from "../../../../constants/priveleges";
const DrawerChat = (props) => {

    const CommentList = ({ comments }) => (
        <List
            dataSource={comments}
            itemLayout="vertical"
            renderItem={(props) => <Comment {...props} />}
        />
    );

    const Editor = ({ form, loading, onSubmit }) => (
        <Form name={form} onFinish={onSubmit}>
            <GetTextArea name='message' required={true} rows={3} />
            <Form.Item>
                <Button htmlType="submit" loading={loading} type="primary" style={{ margin: "10px" }}>
                    {getTitle('Send')}
                </Button>
            </Form.Item>
        </Form>
    );
    return (
        <Drawer open={props.open} title={props.title} onClose={props.onCancel} width={props.width}
            footer={(props.client !== clientRoles.super && props.state !== 2) &&
                <Comment
                    content={
                        <Editor
                            onSubmit={props.handleSubmit}
                            loading={props.loading}
                            form={props.form}
                        />
                    }
                />}>
            {props.comments.length > 0 && <CommentList comments={props.comments} />}
        </Drawer>
    );
};

export default DrawerChat;
