import { Row, Col, Form } from 'antd';
import GetSelect from '../../../../../../templates/FormSelect';
import { getTitle } from '../../../../../../Trans';
import { useEffect } from 'react';
import { REFERS } from '../../../../../../constants/refers';

const Debtor_physical = (props) => {
    const region = Form.useWatch('region', props.form);

    useEffect(() => {
        if (props.loc_region.loc_region);
        props.form.setFieldValue('loc_region', props.loc_region.loc_region)
    }, []);

    useEffect(() => {
        if (!props.loc_region.loc_region) {
            props.form.setFieldValue('loc_region', null)
        }
    }, [region]);

    return (
        <>
            {props.client_type !== '3' &&
                <>
                    <Row gutter={5}>
                        <Col span={24}>
                            <h2>{getTitle('Additional information about the debtor')}</h2>
                        </Col>
                        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                            <GetSelect name={'age'} required={true} refer={REFERS.conREF_AGES} text={'age'} func={(value, options) => { props.selectLabel({ age: options.label }) }} />
                        </Col>
                        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                            <GetSelect name={'education'} required={true} refer={REFERS.conREF_EDUCATIONS} text={'education'} func={(value, options) => { props.selectLabel({ education: options.label }) }} />
                        </Col>
                        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                            <GetSelect name={'sex'} required={true} refer={REFERS.conREF_SEX} text={'sex'} func={(value, options) => { props.selectLabel({ sex: options.label }) }} />
                        </Col>
                    </Row>
                    <Row gutter={5}>
                        <Col span={24}>
                            <h2>{getTitle('Location of the debtor')}</h2>
                        </Col>
                        <Col xxl={9} xl={9} lg={12} md={12} sm={24} xs={24}>
                            <GetSelect name={'region'} required={true} refer={REFERS.conREF_REGIONS} text={'region'} func={(value, options) => { props.selectLabel({ location_region: options.label }) }} />
                        </Col>
                        <Col xxl={9} xl={9} lg={12} md={12} sm={24} xs={24}>
                            {region ? <GetSelect name={'loc_region'} required={true} refer={REFERS.conREF_LOCAL_REGIONS} referGroup={region} text={'loc_region'} func={(value, options) => { props.selectLabel({ loc_region: options.label }) }} /> :
                                <GetSelect name={'loc_region'} required={true} refer={REFERS.conREF_LOCAL_REGIONS} referGroup={props.loc_region.region} text={'loc_region'} func={(value, options) => { props.selectLabel({ loc_region: options.label }) }} />}
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}
export default Debtor_physical;