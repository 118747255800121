import { Row, Col, Button, Divider } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Trans from '../../../../Trans';
import ModalComponent from '../../../../templates/ModalComponent';
import PaymnentSystemList from '../../../../templates/PaymentSystemList';

const FormPaymentPay = (props) => {
  const [modal, setModal] = useState(true);
  return (
    <ModalComponent
      open={modal}
      data={
        <>
          <Row justify='center'>
            <Col>
              <h3><Trans alias={'It is necessary to pay for the service of entering an entry into the pledge register'} /></h3>
              <h3><Trans alias={'Unique service code'} />{'-' + props.payment_id}</h3>
              <Link to='/main/payments/list'><Button type='success' size='large' loading={props.loading} block><Trans alias={'List of services for payment'} /></Button></Link>
            </Col>
          </Row>
          <Divider/>
          <PaymnentSystemList justify='center'/>
          </>
      }
      onCancel={() => setModal(false)}
    />
  )
};
export default FormPaymentPay;






