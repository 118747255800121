import { Row, Col, Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../../Trans';
import GetOrder from '../../../../templates/GetOrder';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { loadingState } from '../../../../../redux/slices/LoadingSlice';
import { access_ } from '../../../../../redux/slices/AuthSlice';
import { client_role } from '../../../../../redux/slices/AuthSlice';
import ApiHooks from '../../../../../hooks/ApiHooks';
import FormPaymentPay from '../../order/components/FormPaymentPay';

const FormSuccess = (props) => {
    const loading = useSelector(loadingState);
    const [modal, setModal] = useState(false);
    const [payment_id, setPayment_id] = useState();
    const access = useSelector(access_);
    const client = useSelector(client_role);
    const hookApi = ApiHooks();

    const onPayment = async () => {
        setModal(false);
        let result = await hookApi("record/getOrder", { code: props.dec_code }, 'order');
        if (result.payment_id) {
            setPayment_id(result.payment_id);
        }
    };

    return (
        <>
            {(props.paymentId || payment_id) &&
                <FormPaymentPay loading={loading} payment_id={props.paymentId ? props.paymentId : payment_id} />
            }
            <Row justify='center'>
                <Col><h4>{getTitle('The entry was successfully entered into the Pledge Register system')}</h4></Col>
            </Row>
            <Row justify='center'>
                <Col>
                    <Space>
                        {access.record_create && <Button type='primary' size='large' onClick={()=>window.location.href='/main/record/create/'} loading={loading}>{getTitle('Create a new entry')}</Button>}
                        {access.record_order_certified && <Button type='success' size='large' onClick={() => setModal(true)} loading={loading}>{getTitle('Get an extract')}</Button>}
                        {(access.record_delete || access.record_update) && <Link to='/main/record/list'><Button type='warning' size='large' loading={loading}>{getTitle('Go to the register of records')}</Button></Link>}
                    </Space>
                </Col>
            </Row>
            <GetOrder modal={modal} onCancel={() => setModal()} record_code={props.dec_code} onPayment={() => onPayment()} client={client} />
        </>
    )
};
export default FormSuccess;