import React from "react";
import { Drawer } from "antd";


const DrawerComponent = (props) => {
  return (
    <Drawer
      open={props.open}
      title={props.title}
      onClose={props.onCancel}
      width={props.width}
    >
      {props.data}
    </Drawer>
  );
};

export default DrawerComponent;
