import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Trans from "../../../../Trans";

const ViewData = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => { getData() }, [props.data]);

  const getData = () => {
    let obj_ = {};
    obj_ = props.data ? props.data : {};
    let arr = [{ key: 'pledge', name: <Trans alias='pledge' /> }];
    Object.entries(obj_).forEach(([key, val], index) => {
      if (val) {
        let obj = {};
        obj.key = key;
        obj.name = <Trans alias={key} />
        if (key !== 'creditor' || key !== 'debtor' || key !== 'object') {
          obj.value = val;
        }
        arr.push(obj);
        if (key === 'creditor' || key === 'debtor') {
          Object.entries(val).forEach(([key, val], index) => {
            if (val) {
              let objChild = {};
              objChild.key = key + index + Math.random();
              objChild.name = <Trans alias={key} />
              objChild.value = val;
              arr.push(objChild);
            }
          });
        }
        if (key === 'object') {
          obj_.object.map((value) => {
            Object.entries(value).forEach(([key, val], index) => {
              let obj = {};
              if (typeof val !== 'object' &&
                !Array.isArray(val)) {
                if (val) {
                  if (key === 'element_value') {
                    if (obj_.object[0].provision_group_code === '5' || obj_.object[0].provision_group_code === '7' || obj_.object[0].provision_type_code === '101' || obj_.object[0].provision_type_code === '102' || obj_.object[0].provision_type_code === '706') {
                      key = 'element_cadastre';
                    }
                    if (obj_.object[0].provision_group_code === '6' || obj_.object[0].provision_group_code === '8' || obj_.object[0].provision_group_code === '200') {
                      key = 'element_car';
                    }
                    if (obj_.object[0].provision_type_code === '701' || obj_.object[0].provision_type_code === '702') {
                      key = 'tech_num';
                    }
                  }
                  obj.key = 'obj' + index;
                  obj.name = <Trans alias={key} />;
                  obj.value = val;
                  if (obj.name.props.alias === 'provision_group_code' || obj.name.props.alias === 'element_code' || obj.name.props.alias === 'provision_type_code') {
                    obj = {};
                  }
                  arr.push(obj);
                }
              }
            });
          })
        }
      }
    });
    setData(arr)
  };

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      onCell: (_, text) => {
        if (_.key === 'creditor' || _.key === 'debtor' || _.key === 'object' || _.key === 'pledge') {
          return {
            ['style']: { background: '#fafafa', fontWeight: 'bold' },
            colSpan: 2
          }
        }
      },
    },
    {
      dataIndex: 'value',
      key: 'value',
      onCell: (_, index) => {
        if (_.key === 'creditor' || _.key === 'debtor' || _.key === 'object' || _.key === 'pledge') {
          return { colSpan: 0 }
        }
      },
    }
  ];
  return (
    <>
      <Table size='small' columns={columns} dataSource={data} pagination={false} bordered showHeader={false} rowKey={(record) => record.key + `${Math.random() * 101}`} />
    </>
  );
};

export default ViewData;
