import { Col, Row, Statistic } from 'antd';
import { useEffect } from 'react';
import { getTitle } from '../../../../../Trans';
import MonitoringTable from '../../../../../templates/MonitoringTable';
import HighChartComponent from '../../../../../templates/HightChartComponent';
import TableSummaryComponent from './table-summary-component';

const StatisticsObligationYear = (props) => {
  const titleYear = getTitle('Year');
  const titleCount = getTitle('Count');
  const titleSum = getTitle('Sum');
  const { statistics, setColumns, setSummaryTitleSize, loading } = props;
  const titleSize = 2;

  const categories = [];
  const amount_sum = [];
  const dec_count = [];

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      width: 35,
      render: (text, record, index) => {
        categories.push(record.year);
        amount_sum.push(Number(record.amount_sum));
        dec_count.push(record.dec_count);
        return index + 1;
      },
      align: 'center',
    },
    {
      title: titleYear,
      dataIndex: 'year',
      key: 'year',
      align: 'center',
    },
    {
      title: titleCount,
      dataIndex: 'dec_count',
      render: (text, record, index) => (
        <Statistic
          value={text}
          precision={0}
          groupSeparator={' '}
          valueStyle={{ fontSize: '14px' }}
        />
      ),
      key: 'dec_count',
      align: 'center',
    },
    {
      title: titleSum,
      dataIndex: 'amount_sum',
      render: (text, record, index) => (
        <Statistic
          value={text}
          precision={2}
          groupSeparator={' '}
          valueStyle={{ fontSize: '14px' }}
        />
      ),
      key: 'amount_sum',
      align: 'center',
    },
  ];

  useEffect(() => {
    setColumns(columns);
    setSummaryTitleSize(titleSize);
  }, []);

  const summaryData = [
    { value: statistics?.total?.dec_count },
    { value: statistics?.total?.amount_sum, precision: 2 },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col lg={10} xs={24}>
        <MonitoringTable
          size="middle"
          columns={columns}
          dataSource={statistics?.data}
          loading={loading}
          pagination={false}
          bordered={true}
          rowKey={(record) => record.order}
          summary={() => (
            <TableSummaryComponent
              titleColSpan={titleSize}
              summaryData={summaryData}
            />
          )}
        />
      </Col>
      <Col lg={14} xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <HighChartComponent
              type="spline"
              title={titleSum}
              xAxis={{
                type: 'category',
                categories: categories,
                crosshair: true,
              }}
              yAxis={{ min: 0, title: { text: '' } }}
              series={[{ name: titleSum, data: amount_sum }]}
            />
          </Col>
          <Col xs={24}>
            <HighChartComponent
              type="column"
              title={titleCount}
              xAxis={{
                type: 'category',
                categories: categories,
                crosshair: true,
              }}
              yAxis={{ min: 0, title: { text: '' } }}
              series={[{ name: titleCount, data: dec_count }]}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default StatisticsObligationYear;
