import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Pagination, Divider, Button } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import ViewData from "./components/ViewData";
import Trans from "../../../Trans";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../templates/MonitoringTable";
import moment from "moment";
import { date_format } from "../../../templates/Patterns";
import DrawerComponent from "../../../templates/DrawerComponent";

const WsFactoring = () => {
  const pageSize = 20;
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
    action_id: "-1",
    error: "0"
  };
  
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataDetail, setDataDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    log_id: null,
    ip: null,
    client_code: null,
    user_id: null,
    error: 0,
    action_id: null,
    page: 1,
    page_size: pageSize,
  });
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    const result = await hookApi("monitoring/getWsFactoring", { data: sendObj });
    if (result.data) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    const data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.log_id = values.log_id ? values.log_id * 1 : null;
    data.ip = values.ip || null;
    data.client_code = values.client_code ? values.client_code * 1 : null;
    data.user_id = values.user_id ? values.user_id * 1 : null;
    data.error = values.error ? values.error * 1 : 0;
    data.action_id = values.action_id ? values.action_id * 1 : null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const info = async (id) => {
    const result = await hookApi("monitoring/getWsFactoringData", { log_id: id });
    const dataTable = (<ViewData request={JSON.stringify(result.data.request)} response={JSON.stringify(result.data.response)} />);
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      width: 50,
    },
    {
      title: "Пользователь",
      dataIndex: "client",
      key: "client",
      width: 300,
    },
    {
      title: "Администратор / Регистратор",
      dataIndex: "user",
      key: "user",
      width: 300,
    },
    {
      title: "Дата и время начала",
      dataIndex: "b_time",
      key: "b_time",
      align: 'center',
      width: 150,
    },
    {
      title: "Дата и время конца",
      dataIndex: "e_time",
      key: "e_time",
      align: 'center',
      width: 150,
    },
    {
      title: "IP",
      dataIndex: "ip",
      align: 'center',
      key: "ip",
      width: 100,
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Код ошибки",
      dataIndex: "err_code",
      key: "err_code",
      align: 'center',
      width: 100,
    },
    {
      title: "Текст ошибки",
      dataIndex: "err_msg",
      key: "err_msg",
    },
    {
      title: "",
      key: "buttons",
      width: 10,
      render: (_, record) => (
        <Button
          title="Просмотр"
          disabled={loading}
          type="primary"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => info(record.key)}
        />
      ),
    },
  ];

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        initialValues={defaultFilterForm}
      />
      <Divider />
      <DrawerComponent
        title={<Trans alias={"Show data"} />}
        open={modalOpen}
        data={dataDetail}
        onCancel={() => setModalOpen(false)}
        width={'60%'} />
      <MonitoringTable
        size='small'
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default WsFactoring;
