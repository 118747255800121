import React from "react";
import {
  IeOutlined,
  FileDoneOutlined,
  NotificationOutlined,
  FileSearchOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  DotChartOutlined,
  AuditOutlined,
  CalculatorOutlined,
  RiseOutlined,
  ClusterOutlined,
  FundOutlined,
  SolutionOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  UserSwitchOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  LikeOutlined,
  LaptopOutlined,
  FileWordOutlined,
  FullscreenOutlined,
  FileExclamationOutlined,
  TeamOutlined,
  TranslationOutlined,
  FundProjectionScreenOutlined,
  FontSizeOutlined,
  BankOutlined,
  DollarOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  DeploymentUnitOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
const iconMaps = {
  IeOutlined,
  FileDoneOutlined,
  NotificationOutlined,
  FileSearchOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  DotChartOutlined,
  AuditOutlined,
  CalculatorOutlined,
  RiseOutlined,
  ClusterOutlined,
  FundOutlined,
  SolutionOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  UserSwitchOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  LikeOutlined,
  LaptopOutlined,
  FileWordOutlined,
  FullscreenOutlined,
  FileExclamationOutlined,
  TeamOutlined,
  TranslationOutlined,
  FundProjectionScreenOutlined,
  FontSizeOutlined,
  BankOutlined,
  DollarOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  DeploymentUnitOutlined,
  OrderedListOutlined,
};

const IconComponent = ({ param, props }) => {
  return <>{param && React.createElement(iconMaps[param], props)}</>;
};

export default IconComponent;
