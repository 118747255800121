import { Col, Row, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { getTitle } from '../../../../../Trans';
import MonitoringTable from '../../../../../templates/MonitoringTable';
import HighChartComponent from '../../../../../templates/HightChartComponent';
import TableSummaryComponent from './table-summary-component';

const StatisticsRecordClientYear = (props) => {
  const titleCount = getTitle('Count');
  const titleYear = getTitle('Year');
  const titleName = getTitle('name');
  const { statistics, setColumns, loading, setSummaryTitleSize } = props;
  const titleSize = 3;

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      width: 35,
      align: 'center',
    },
    {
      title: titleYear,
      dataIndex: 'year',
      key: 'year',
      align: 'center',
    },
    {
      title: titleName,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: titleCount,
      dataIndex: 'dec_count',
      render: (text) => (
        <Statistic
          value={text}
          precision={0}
          groupSeparator={' '}
          valueStyle={{ fontSize: '14px' }}
        />
      ),
      key: 'dec_count',
      align: 'center',
    },
  ];

  useEffect(() => {
    setColumns(columns);
    setSummaryTitleSize(titleSize);
  }, [columns]);

  useEffect(() => {
    createDataChart();
  }, [statistics?.data]);

  const [chartData, setChartDate] = useState([]);

  const summaryData = [{ value: statistics?.total?.dec_count }];

  const createDataChart = async () => {
    let strArr = [];
    let objArr = [];
    statistics?.data.forEach((element) => {
      let obj = {};
      let ind = strArr.indexOf(element.name);
      if (ind === -1) {
        strArr.push(element.name);
        obj.name = element.name;
        obj.data = [[element.year, element.dec_count]];
        objArr.push(obj);
      } else {
        obj = objArr[ind];
        if (element.year) obj.data.push([element.year, element.dec_count]);
        objArr[ind] = obj;
      }
    });
    setChartDate(objArr);
  };
  return (
    <Row gutter={[16, 16]}>
      <Col lg={10} xs={24}>
        <MonitoringTable
          size="middle"
          columns={columns}
          dataSource={statistics?.data}
          loading={loading}
          pagination={true}
          bordered={true}
          rowKey={(record) => record.order}
          summary={() => (
            <TableSummaryComponent
              titleColSpan={titleSize}
              summaryData={summaryData}
            />
          )}
        />
      </Col>
      <Col lg={14} xs={24}>
        <Row>
          <Col xs={24}>
            <HighChartComponent
              type="spline"
              title={''}
              xAxis={{ type: 'category', categories: '', crosshair: true }}
              yAxis={{ min: 0, title: { text: '' } }}
              pointStart={2014}
              series={chartData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default StatisticsRecordClientYear;
