import { Tooltip, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MonitoringTable from "../../../../templates/MonitoringTable";
import { getTitle } from "../../../../Trans";

const FileFormList = (props) => {
  const deleteText = getTitle("Delete");
  const docTypeFile = getTitle("Downloadable file");
  const docTypeLink = getTitle("Link to external file");

  const columns = [
    {
      title: getTitle("File type"),
      dataIndex: "doc_type",
      key: "doc_type",
      render: (type) => <>{type === 0 ? docTypeFile : docTypeLink}</>,
    },
    {
      title: getTitle("Name in Russian"),
      dataIndex: "doc_name_ru",
      key: "doc_name_ru",
      width: 275,
    },
    {
      title: getTitle("Name in Uzbek"),
      dataIndex: "doc_name_uz",
      key: "doc_name_uz",
      width: 275,
    },
    {
      title: getTitle("Name in Uzbek (Latin)"),
      dataIndex: "doc_name_oz",
      key: "doc_name_oz",
      width: 275,
    },
    {
      title: getTitle("Name in English"),
      dataIndex: "doc_name_en",
      key: "doc_name_en",
      width: 275,
    },
    {
      title: "",
      key: "buttons",
      width: 10,
      render: (_, file) => (
        <Tooltip title={deleteText}>
          <Button
            disabled={props.loading}
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => props.deleteFile(file.doc_id)}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <MonitoringTable
      columns={columns}
      dataSource={props.data}
      loading={props.loading}
      pagination={false}
      bordered={true}
    />
  );
};

export default FileFormList;
