import React from "react";
import { Tabs, Form, Input, Row, Col, Button } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Trans from "../../../../Trans";
const Components = {
  Trans: Trans,
};
const ClientFormComponent = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const getElement = (param) => {
    if (typeof Components[param.component] !== "undefined") {
      return React.createElement(Components[param.component], {
        key: param.id,
        alias: param.alias,
      });
    }
  };
  return (
    <div>
      ClientFormComponent {getElement({ component: "Trans", alias: "Request" })}
      <Trans alias={"Request"} />
      CLiwerwe
    </div>
  );
};

export default ClientFormComponent;
