import { useState } from "react";
import { Form, Row, Col, Select, Button } from "antd";
import Trans, { getTitle } from "../../../../Trans";
import GetInput from "../../../../templates/FormInput";
import { pattern } from "../../../../templates/Patterns";
import Confirm from "../../../../templates/Confirm";

const FilterFormModal = (props) => {

  const [searchType, setSearchType] = useState(1);

  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "modalForm",
    layout: "vertical",
    className: "form-back",
    initialValues: { filter: searchType },
  };

  const options = [
    { value: 1, label: getTitle("Technical passport") },
    { value: 2, label: getTitle("VIN number") }
  ];

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name='filter' label={getTitle("Search by data")} rules={[{ required: true, message: getTitle("Props is a must") }]}>
            <Select options={options} onChange={setSearchType} />
          </Form.Item>
        </Col>
        {searchType === 1 &&
          <>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"tech_s"} required={true} text={"Technical passport series"} />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"tech_n"} required={true} text={"Technical passport number"}  />
            </Col>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <GetInput name={"state_num"} required={true} text={"State license plate"}  />
            </Col>
          </>
        }
        {searchType === 2 &&
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <GetInput name={"vin_num"} required={true} text={"VIN number"} />
          </Col>}
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <GetInput name={"pin"} text={"Pin"} pattern={pattern.pin} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Confirm form={props.form} title={'Do you really want to generate the file?'} btnText='Search' btnType="primary" />
          <Button style={{ margin: "0 8px" }} onClick={() => {props.form.resetFields();props.form.submit();}}>
            <Trans alias={"Reset"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterFormModal;
