import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Form, Row, Col, Button } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import GetInput from "../../../templates/FormInput";
import { pattern } from "../../../templates/Patterns";
import Confirm from "../../../templates/Confirm";
import { getTitle } from "../../../Trans";
import GetSelect from "../../../templates/FormSelect";
import { RollbackOutlined } from "@ant-design/icons";

const CenterForm = (props) => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();
  useEffect(() => {
    loadContent(id);
  }, [id]);

  const loadContent = async (id) => {
    if (id != 0) {
      const result = await hookApi("site/payments/getPaymentCenter", {
        center_code: id,
      });
      form.setFieldsValue(result.data);
    }
  };
  const handleSubmitProfile = async (values) => {
    let data = values;
    data.code = id ? id * 1 : null;
    await hookApi(
      "site/payments/setPaymentCenter",
      {
        data: data,
      },
      true
    );
  };
  const formConfig = {
    form: form,
    onFinish: handleSubmitProfile,
    disabled: loading,
    name: "centerForm",
    layout: "vertical",
    className: "form-back",
    //initialValues: props.initialValues,
  };
  return (
    <Form {...formConfig}>
      <Row gutter={5} justify="center">
        <Col span={8}>
          <GetInput
            name={"name_ru"}
            pattern={pattern.name}
            required={true}
            text={"Name in Russian"}
          />
        </Col>
        <Col span={8}>
          <GetInput
            name={"name_uz"}
            pattern={pattern.name}
            required={true}
            text={"Name in Uzbek"}
          />
        </Col>
      </Row>
      <Row gutter={5} justify="center">
        <Col span={8}>
          <GetInput
            name={"name_oz"}
            pattern={pattern.name}
            required={true}
            text={"Name in Uzbek (Latin)"}
          />
        </Col>
        <Col span={8}>
          <GetInput
            name={"name_en"}
            pattern={pattern.name}
            required={true}
            text={"Name in English"}
          />
        </Col>
      </Row>
      <Row gutter={5} justify="center">
        <Col span={8}>
          <GetInput name={"username"} pattern={pattern.login} required={true} text={"Username"} />
        </Col>
        <Col span={8}>
          <GetInput name={"password"} pattern={pattern.login} required={true} text={"Password"} />
        </Col>
      </Row>
      <Row gutter={5} justify="center">
        <Col span={8}>
          <GetInput name={"ip"} pattern={pattern.ip_list} required={true} text={"IP"} />
        </Col>
        <Col span={8}>
          <GetSelect name={"state"} text={"State"} refer={"630"} />
        </Col>
      </Row>
      <Row gutter={5} justify="end">
        <Col span={12}>
          <Confirm
            form={form}
            title={"Do you really want to save?"}
            btnText={"Save"}
            btnDisabled={loading}
            btnType="primary"
          />
          <Link to={`/main/payments/centers`} disabled={loading}>
            <Button type="lightdark" style={{ margin: "0 8px" }} icon={<RollbackOutlined />}>
              {getTitle("back")}
            </Button>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

export default CenterForm;
