import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Pagination, Divider, Badge, Button } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../templates/MonitoringTable";
import moment from "moment";
import { date_format } from "../../../templates/Patterns";
import Trans, { getTitle } from "../../../Trans";

const SmsRecords = () => {
    const pageSize = 20;
    const defaultFilterForm = {
        bdate: moment(),
        edate: moment(),
        action_id: "-1",
        error: "0"
    };

    const [filter] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [sendObj, setSendObj] = useState({
        bdate: moment().format(date_format),
        edate: moment().format(date_format),
        page: 1,
        page_size: pageSize,
    });

    const loading = useSelector(loadingState);
    const hookApi = ApiHooks();

    useEffect(() => {
        loadContent();
    }, [sendObj]);

    const loadContent = async () => {
        const result = await hookApi("monitoring/get_sms_dec_list", { data: sendObj, });
        if (result.data) {
            setDataSource(result.data.data);
            setDataCount(result.data.count);
        }
    };

    const handleChangePage = async (value) => {
        setCurrentPage(value);
        setSendObj({ ...sendObj, page: value });
    };

    const handleFilterForm = async (values) => {
        const data = {};
        data.bdate = moment(values.bdate).format(date_format);
        data.edate = moment(values.edate).format(date_format);
        data.page = 1;
        setCurrentPage(1);
        setSendObj({ ...sendObj, ...data });
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "key",
            key: "key",
            align: 'center',
            fixed: 'left',
            width: '2%'
        },
        {
            title: getTitle("Date and time of registration"),
            dataIndex: "registered",
            key: "registered",
            align: 'center',
            width: '8%'
        },
        {
            title: getTitle("Date and time of sending"),
            dataIndex: "sended",
            key: "sended",
            align: 'center',
            width: '8%'
        },
        {
            title: getTitle("Code"),
            dataIndex: "dec_code",
            key: "dec_code",
            align: 'center',
            width: '10%'
        },
        {
            title: getTitle("Phone"),
            dataIndex: "phone",
            key: "phone",
            align: 'center',
            width: '10%'
        },
        {
            title: 'HTTP status',
            dataIndex: "http_state",
            key: "http_state",
            align: 'center',
            width: '10%'
        },
        {
            title: 'HTTP msg',
            dataIndex: "http_msg",
            align: 'center',
            key: "http_msg",
            width: '10%'
        },
        {
            title: getTitle("Error text"),
            dataIndex: "err_text",
            key: "err_text",
            width: '10%'
        },
        {
            title: getTitle("Content texts"),
            dataIndex: "content",
            key: "content",
            onCell: (_, index) => {
                return {
                    ['style']: { maxWidth: '200px' }
                }
            },
            width: '15%'
        },
        {
            title: getTitle('State'),
            dataIndex: "state_name",
            key: "state_name",
            align: 'center',
            fixed: 'right',
            width: '7%',
            render: (text, record) => (
                <Badge
                    count={record.state_name}
                    style={{ backgroundColor: `${record.state === 2 ? "#52c41a" : record.state === 3 ? "#f5222d" : record.state === 1 ? "#08979c" : "#2f54eb"}` }}
                />
            ),
        },
    ];

    return (
        <>
            <FilterForm
                form={filter}
                onFinish={handleFilterForm}
                disabled={loading}
                initialValues={defaultFilterForm}
            />
            <Divider />
            <MonitoringTable
                size='small'
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={false}
                bordered={true}
            />
            {dataCount > pageSize && (
                <Pagination
                    disabled={loading}
                    defaultCurrent={1}
                    current={currentPage}
                    total={dataCount}
                    pageSize={pageSize}
                    onChange={handleChangePage}
                />
            )}
        </>
    );
};

export default SmsRecords;
