
import { Layout } from "antd";
import SiteHeader from "./header/SiteHeader";
import SiteFooter from "./footer/SiteFooter";
import RoleRoutes from "../router/RoleRoutes";
import { useSelector } from "react-redux";
import { userAuth } from "../redux/slices/AuthSlice";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import PageTitle from "./templates/PageTitle";
const { Header, Content, Footer } = Layout;

const Modules = () => {
  const user = useSelector(userAuth);
  if (!user && !Cookies.get("token")) {
    return (<Navigate to="/" />);
  }
  return (
    <Layout className="header-background">
      <Header className="header">
        <SiteHeader forModules />
      </Header>
      <Content style={{ padding: "5px 10px", minHeight: "640px" }}>
        <PageTitle />
        <RoleRoutes />
      </Content>
      <Footer>
        <SiteFooter />
      </Footer>
    </Layout>
  );
};

export default Modules;
