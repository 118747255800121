import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Badge } from "antd";
import ApiHooks from "../../../../../hooks/ApiHooks";
import { loadingState } from "../../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import MonitoringTable from "../../../../templates/MonitoringTable";
import { getTitle } from "../../../../Trans";
import { access_ } from "../../../../../redux/slices/AuthSlice";
import DrawerComponent from "../../../../templates/DrawerComponent";
import Trans from "../../../../Trans";

const RecordOrderSearchFormForCB = () => {
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [html, setHtml] = useState([]);
  const loading = useSelector(loadingState);
  const access = useSelector(access_);
  const [open, setOpen] = useState(false);
  const hookApi = ApiHooks();

  const handleFilterForm = async (values) => {
    const data = {};
    data.code = values.code ? values.code : null;
    data.inn = values.inn ? values.inn : null;
    data.pin = values.pin ? values.pin : null;
    data.body_num = values.body_num ? values.body_num : null;
    data.cadastre = values.cadastre ? values.cadastre.replaceAll("_", "") : null;
    data.tech_num = values.tech_num ? values.tech_num : null;
    const result = await hookApi("record/getDeclarationSearchCB", { data: data });
    if (result.data) {
      setDataSource(result.data.data);
    }
  };

  const getHtml = async (code) => {
    const result = await hookApi("record/getSearchCBHTML", { code: code });
    if (result.code === 0) {
      setHtml(result.data);
      setOpen(true);
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      render: (value, row, index) => index + 1,
      align: "center",
      width: "5%",
      key: "index",
    },
    {
      title: getTitle("Code"),
      dataIndex: "code",
      align: "center",
      width: "15%",
      key: "code",
    },
    {
      title: getTitle("Creditor name"),
      dataIndex: "creditor_name",
      align: "center",
      width: "15%",
      key: "creditor_name",
    },
    {
      title: getTitle("Inn/Pin creditor"),
      dataIndex: "creditor_inn_pin",
      align: "center",
      width: "15%",
      key: "creditor_inn_pin",
    },
    {
      title: getTitle("Debtor name"),
      dataIndex: "debtor_name",
      align: "center",
      width: "15%",
      key: "debtor_name",
    },
    {
      title: getTitle("Inn/Pin debtor"),
      dataIndex: "debtor_inn_pin",
      align: "center",
      width: "15%",
      key: "debtor_inn_pin",
    },
    {
      title: getTitle("State"),
      dataIndex: "state_name",
      align: "center",
      width: "15%",
      key: "state_name",
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{
            backgroundColor: `${record.state === "1" ? "#52c41a" : "#f5222d"}`,
          }}
        />
      ),
    },
    {
      title: "",
      key: "buttons",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <>
          <Button
            disabled={loading}
            type="success"
            shape="circle"
            icon={<SearchOutlined />}
            target="blank_"
            onClick={() => getHtml(record.code)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        role={access.record_list_super}
        resetData={() => {
          setDataSource([]);
        }}
      />
      <Divider />{
        dataSource.length > 0 && (
          <MonitoringTable
            size="small"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={true}
            bordered={true}
            rowKey={(record) => record.code}
          />
        )}
      <DrawerComponent
        title={<Trans alias={"Show data"} />}
        open={open}
        data={<div dangerouslySetInnerHTML={{ __html: html }} />}
        onCancel={() => setOpen(false)}
        width={'50%'} />
    </>
  );
};

export default RecordOrderSearchFormForCB;
