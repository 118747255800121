import { Form, Input } from "antd";
import Trans, { getTitle } from "../../../../../Trans";
import TinyMceEditor from "../../../../../templates/TinyMceEditor";

const TextFormItems = (props) => {
  const titleRules = [
    { required: true, message: getTitle("Props is a must") },
    { max: 500, message: getTitle("Props length error") },
  ];
  const shortTextRules = [
    //  { required: true, message: getTitle("Props is a must") },
    { max: 500, message: getTitle("Props length error") },
  ];
  const longTextRules = [
    { required: true, message: getTitle("Props is a must") },
    { max: 100000, message: getTitle("Props length error") },
  ];
  const changeData = (value, field) => {
    props.form.setFieldValue(field, value);
  };

  return (
    <>
      <Form.Item
        name={props.title_name}
        label={getTitle(props.title_label)}
        rules={titleRules}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={props.short_text_name}
        label={getTitle(props.short_text_label)}
        rules={shortTextRules}
      >
        <Input />
      </Form.Item>
      <Trans alias={props.text_label} />
      <TinyMceEditor
        onEditorChange={(value) => changeData(value, props.text_name)}
        initialValue={props.initialTextValue}
      />
      <Form.Item name={props.text_name} rules={longTextRules}>
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};

export default TextFormItems;
