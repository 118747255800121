import { Row, Col, Button } from 'antd';
import Trans from '../Trans';
import { conf } from '../../config';

const PaymnentSystemList = (props) => {
    return (
        <>
            <Row justify={props.justify}>
                <Col>
                    <Trans alias={'List of payment systems'} />
                </Col>
            </Row>
            <Row justify={props.justify}>
                <Col style={{ padding: "0 4px" }}>
                    <Button style={{ background: "transparent", boxShadow: '0 0 0', border: 0, height: '43px' }} href="https://m.click.uz/services" target="_blank" rel="noopener noreferrer" >
                        <svg width="120" height="41" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 23">
                            <path fill="#222633" d="M29.4954 15.4987C29.0037 15.4987 28.545 15.4231 28.1226 15.2753C27.7002 15.1274 27.3372 14.904 27.0336 14.6116C26.73 14.3191 26.4924 13.9479 26.3208 13.501C26.1492 13.0541 26.0634 12.5284 26.0634 11.9239V11.661C26.0634 11.1024 26.1558 10.6096 26.3406 10.1824C26.5254 9.75857 26.7729 9.40043 27.0864 9.10471C27.3999 8.81228 27.7629 8.59214 28.182 8.441C28.6011 8.29314 29.0367 8.21757 29.4954 8.21757C29.9871 8.21757 30.4194 8.28329 30.7956 8.41471C31.1685 8.54614 31.4886 8.72686 31.7526 8.95357C32.0166 9.18029 32.2212 9.44971 32.373 9.75529C32.5215 10.0609 32.6205 10.3894 32.6634 10.741H30.8814C30.7857 10.4157 30.6306 10.1627 30.4194 9.97214C30.2082 9.78486 29.9013 9.68957 29.4954 9.68957C29.2677 9.68957 29.0532 9.729 28.8552 9.80786C28.6572 9.88671 28.4823 10.0083 28.3338 10.1693C28.1853 10.3303 28.0665 10.534 27.9774 10.7804C27.8883 11.0269 27.8454 11.3193 27.8454 11.661V11.9239C27.8454 12.2919 27.8883 12.6073 27.9708 12.8701C28.0533 13.133 28.1721 13.3499 28.3206 13.5207C28.4691 13.6916 28.6473 13.8164 28.8486 13.8953C29.0499 13.9741 29.2644 14.0136 29.4954 14.0136C29.8485 14.0136 30.1422 13.9249 30.3864 13.7507C30.6273 13.5766 30.7923 13.317 30.8814 12.9753H32.6634C32.6205 13.3597 32.5116 13.708 32.34 14.0201C32.1684 14.3323 31.9473 14.5951 31.6734 14.8153C31.3995 15.0354 31.0827 15.203 30.7164 15.3213C30.3501 15.4396 29.9442 15.4987 29.4954 15.4987Z"></path>
                            <path fill="#222633" d="M36.2208 15.4199C35.8149 15.4199 35.4684 15.3837 35.178 15.3081C34.8876 15.2326 34.6467 15.1011 34.4586 14.9139C34.2705 14.7266 34.1319 14.4769 34.0428 14.1647C33.9537 13.8526 33.9108 13.4583 33.9108 12.9753V6.15086H35.6532V12.7814C35.6532 13.0016 35.6697 13.1856 35.6994 13.3334C35.7291 13.4813 35.7819 13.6029 35.8512 13.6949C35.9205 13.7869 36.0195 13.8526 36.1416 13.892C36.2637 13.9314 36.4188 13.9511 36.6036 13.9511C36.7092 13.9511 36.7983 13.9479 36.8676 13.9446C36.9369 13.9413 37.0095 13.9347 37.0788 13.9249V15.3706C37.026 15.3804 36.9666 15.387 36.9006 15.3969C36.8346 15.4067 36.7653 15.4133 36.6894 15.4166C36.6135 15.4199 36.5376 15.4231 36.4584 15.4231H36.2208V15.4199Z"></path>
                            <path fill="#222633" d="M39.2007 7.63271C38.874 7.63271 38.6166 7.54071 38.4285 7.35671C38.2404 7.17271 38.1447 6.946 38.1447 6.67329C38.1447 6.394 38.2404 6.164 38.4285 5.98328C38.6166 5.80257 38.874 5.71386 39.2007 5.71386C39.5274 5.71386 39.7815 5.80586 39.9729 5.98986C40.161 6.17386 40.2567 6.40057 40.2567 6.67329C40.2567 6.95257 40.161 7.18257 39.9729 7.36329C39.7848 7.544 39.5274 7.63271 39.2007 7.63271ZM38.3328 8.38186H40.0752V15.341H38.3328V8.38186Z"></path>
                            <path fill="#222633" d="M44.8866 15.4987C44.3949 15.4987 43.9362 15.4231 43.5138 15.2753C43.0914 15.1274 42.7284 14.904 42.4248 14.6116C42.1212 14.3191 41.8836 13.9479 41.712 13.501C41.5404 13.0541 41.4546 12.5284 41.4546 11.9239V11.661C41.4546 11.1024 41.547 10.6096 41.7318 10.1824C41.9166 9.75857 42.1641 9.40043 42.4776 9.10471C42.7911 8.81228 43.1541 8.59214 43.5732 8.441C43.9923 8.29314 44.4279 8.21757 44.8866 8.21757C45.3783 8.21757 45.8106 8.28329 46.1868 8.41471C46.5597 8.54614 46.8798 8.72686 47.1438 8.95357C47.4078 9.18029 47.6124 9.44971 47.7642 9.75529C47.9127 10.0609 48.0117 10.3894 48.0546 10.741H46.2726C46.1769 10.4157 46.0218 10.1627 45.8106 9.97214C45.5994 9.78486 45.2925 9.68957 44.8866 9.68957C44.6589 9.68957 44.4444 9.729 44.2464 9.80786C44.0484 9.88671 43.8735 10.0083 43.725 10.1693C43.5765 10.3303 43.4577 10.534 43.3686 10.7804C43.2795 11.0269 43.2366 11.3193 43.2366 11.661V11.9239C43.2366 12.2919 43.2795 12.6073 43.362 12.8701C43.4445 13.133 43.5633 13.3499 43.7118 13.5207C43.8603 13.6916 44.0385 13.8164 44.2398 13.8953C44.4411 13.9741 44.6556 14.0136 44.8866 14.0136C45.2397 14.0136 45.5334 13.9249 45.7776 13.7507C46.0185 13.5766 46.1835 13.317 46.2726 12.9753H48.0546C48.0117 13.3597 47.9028 13.708 47.7312 14.0201C47.5596 14.3323 47.3385 14.5951 47.0646 14.8153C46.7907 15.0354 46.4739 15.203 46.1076 15.3213C45.7413 15.4396 45.3354 15.4987 44.8866 15.4987Z"></path>
                            <path fill="#222633" d="M49.2393 15.341V6.15086H50.9784V11.2306L53.5491 8.38186H55.5555L52.9584 11.2963L55.8327 15.341H53.7735L51.7704 12.4923L50.9784 13.3466V15.341H49.2393Z"></path>
                            <path fill="url(#paint0_linear_5944_78718)" d="M7.35859 15.6412C4.50264 12.7851 4.52986 10.1877 7.35859 7.35884C10.1873 4.53001 12.7847 4.50279 15.6407 7.35884C18.4966 10.2149 18.4694 12.8123 15.6407 15.6412C14.2234 17.0585 12.8625 17.772 11.4996 17.774C10.1426 17.774 8.78365 17.0663 7.35859 15.6412ZM3.90773 3.90786C1.31424 6.50144 0.00583244 8.99003 0 11.4825V11.5156C0.00583244 13.9983 1.30258 16.4869 3.90773 19.0921C6.51289 21.6974 9.0014 22.9942 11.4841 23H11.5171C14.0115 22.9942 16.498 21.6857 19.0915 19.0921C24.2785 13.905 24.3271 9.14168 19.0915 3.90786C16.4805 1.29679 13.9862 2.16024e-06 11.4977 2.16024e-06C8.99945 -0.00194205 6.50706 1.30845 3.90773 3.90786Z"></path>
                            <path fill="url(#paint1_linear_5944_78718)" d="M58.2087 8.40158C57.6906 8.40158 57.2979 8.25373 57.0339 7.9613C56.7666 7.66887 56.6346 7.21872 56.6346 6.61415V3.89687H57.7368V6.46301C57.7368 6.79487 57.8061 7.04458 57.9414 7.21544C58.0767 7.38301 58.2978 7.46844 58.6047 7.46844C58.773 7.46844 58.9149 7.44215 59.0337 7.38958C59.1525 7.33701 59.2548 7.26472 59.334 7.16944C59.4132 7.07415 59.4726 6.96572 59.5089 6.84415C59.5452 6.72258 59.5617 6.59444 59.5617 6.46301V3.89687H60.6639V8.29973H59.6475V7.62615C59.5419 7.83644 59.3703 8.01715 59.1294 8.17158C58.8852 8.32601 58.5816 8.40158 58.2087 8.40158Z"></path>
                            <path fill="url(#paint2_linear_5944_78718)" d="M61.6605 3.90015H62.6769V4.57373C62.799 4.35687 62.9838 4.17615 63.2313 4.02501C63.4788 3.87387 63.7758 3.80158 64.119 3.80158C64.7196 3.80158 65.1816 3.98558 65.5083 4.3503C65.835 4.71501 65.9967 5.25715 65.9967 5.98001V6.14758C65.9967 6.51887 65.9505 6.84744 65.8647 7.1333C65.7756 7.41915 65.6502 7.65244 65.4852 7.83973C65.3202 8.02373 65.1222 8.16501 64.8945 8.26358C64.6635 8.35887 64.4061 8.40815 64.1223 8.40815C63.8286 8.40815 63.5646 8.34244 63.3303 8.2143C63.096 8.08287 62.9079 7.90544 62.7627 7.67873V9.97215H61.6605V3.90015ZM63.855 7.48158C64.0002 7.48158 64.1355 7.45201 64.2609 7.39287C64.3863 7.33373 64.4952 7.25158 64.5876 7.13987C64.68 7.02815 64.7493 6.89015 64.7988 6.72587C64.8483 6.5583 64.8747 6.36444 64.8747 6.1443V5.97673C64.8747 5.76644 64.8516 5.58244 64.8054 5.42801C64.7592 5.27358 64.6899 5.14215 64.5975 5.03701C64.5051 4.93187 64.3962 4.85301 64.2708 4.80044C64.1454 4.74787 64.0068 4.72158 63.8583 4.72158C63.7032 4.72158 63.558 4.74458 63.426 4.79058C63.294 4.83658 63.1785 4.90887 63.0795 5.00744C62.9838 5.10273 62.9046 5.21773 62.8518 5.35244C62.7957 5.48387 62.7693 5.6383 62.7693 5.80915V6.30858C62.7693 6.49258 62.7957 6.65358 62.8518 6.79815C62.9079 6.94273 62.9838 7.0643 63.0795 7.16615C63.1752 7.26801 63.2907 7.34687 63.426 7.39944C63.5547 7.4553 63.6999 7.48158 63.855 7.48158Z"></path>
                            <defs>
                                <linearGradient id="paint0_linear_5944_78718" x1="10.6194" y1="19.2553" x2="20.2215" y2="-8.14151" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#0073FF"></stop>
                                    <stop offset="1" stopColor="#00DEFF"></stop>
                                </linearGradient>
                                <linearGradient id="paint1_linear_5944_78718" x1="5.6181" y1="22.2474" x2="18.9692" y2="-33.4895" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.1022" stopColor="#0073FF"></stop>
                                    <stop offset="1" stopColor="#00DEFF"></stop>
                                </linearGradient>
                                <linearGradient id="paint2_linear_5944_78718" x1="59.9534" y1="10.5401" x2="65.5302" y2="3.28268" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#0073FF"></stop>
                                    <stop offset="1" stopColor="#00DEFF"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                    </Button>
                </Col>
                <Col>
                    <Button style={{ background: "transparent", boxShadow: '0 0 0', border: 0, height: '43px' }} href="https://payme.uz/home/payment" target="_blank" rel="noopener noreferrer" >
                        <svg width="120" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.01 18.83">
                            <path fill='#0cbbbc' d="M3.67,0h48.02c.55,0,1.03.22,1.3.66l4.79,7.77c.37.61.3,1.35-.22,1.96l-6.75,7.77c-.37.42-.85.67-1.39.67H1.53c-.95,0-1.63-.74-1.52-1.67L1.79,1.67c.11-.95.93-1.67,1.87-1.67" />
                            <path fill='#fff' d="M32.32,13.32l.74-5.91c.5-.34,1.07-.61,1.55-.61.63,0,.94.47.84,1.28l-.65,5.25h2.28l.71-5.73.02-.16c.5-.34,1.08-.63,1.57-.63.63,0,.94.47.84,1.28l-.65,5.25h2.28l.71-5.73c.19-1.6-.63-2.57-2.1-2.57-1.02,0-2.1.58-2.82,1.11-.31-.71-.98-1.11-1.94-1.11s-1.87.47-2.6.95l.1-.73h-2.13l-1.02,8.07h2.28ZM45.78,8.54c.23-1.07,1-1.69,1.95-1.69s1.47.53,1.47,1.39c0,.1,0,.21-.01.31h-3.41ZM43.39,9.62c-.03,2.02,1.44,3.89,4.44,3.89,1.07,0,1.97-.21,2.78-.71l.16-1.9c-.66.4-1.66.68-2.53.68-1.15,0-2.18-.5-2.47-1.66h5.58c.08-.21.15-.71.15-1.19,0-2.13-1.18-3.68-3.6-3.68s-4.47,1.44-4.5,4.58M15.68,10.96c0-.87,1.02-1.2,2.07-1.2h1.07l-.16,1.31c-.45.36-1.26.73-1.94.73-.61,0-1.03-.34-1.03-.84M13.4,11.14c-.02,1.31,1.05,2.37,2.68,2.37,1.15,0,1.99-.49,2.47-1.02l-.1.82h2.2l.53-4.37c.26-2.16-.45-3.92-3.23-3.92-1.13,0-2.5.26-3.24.74l-.23,1.81c.6-.32,1.76-.72,2.82-.72,1.28,0,1.73.64,1.68,1.31h-1.36c-1.97,0-4.21.76-4.23,2.97M7.09,11.35l.5-3.97c.49-.39,1.03-.55,1.52-.53.97.01,1.5.79,1.5,2,0,1.57-.82,2.91-2.21,2.91-.44,0-.94-.14-1.31-.4M4.27,15.98h2.26l.37-3.05c.36.27,1.02.58,1.86.58,2.62,0,4.21-2.31,4.21-4.83,0-2.15-1.02-3.65-2.92-3.65-.9,0-1.73.29-2.36.81l.08-.6h-2.19l-1.31,10.73ZM28.02,5.25l-2.45,5.58-1.28-5.58h-2.52l2.24,8.09-1.31,2.65h2.48l5.28-10.73h-2.45Z" />
                        </svg>
                    </Button>
                </Col>
                <Col>
                    <Button style={{ background: "transparent", boxShadow: '0 0 0', border: 0, height: '43px' }} href="https://my.upay.uz/service/1963" target="_blank" rel="noopener noreferrer" >
                        <svg width="120" height="40" xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 204 93'>
                            <path fill='#FFDD2A' fillRule='evenodd' d='M53 0c-1.68 0-3 1.18-3 2.68v14.64c0 1.5 1.32 2.68 3 2.68s3-1.18 3-2.68V2.68C56 1.18 54.68 0 53 0zM23 8c-1.68 0-3 1.2-3 2.74v69.52c0 1.53 1.32 2.74 3 2.74s3-1.2 3-2.74V10.74C26 9.2 24.68 8 23 8zM10 38.8c0-1.57 1.32-2.8 3-2.8s3 1.23 3 2.8v28.4c0 1.57-1.32 2.8-3 2.8s-3-1.23-3-2.8V38.8zM3 48c-1.68 0-3 1.2-3 2.74v10.52C0 62.8 1.32 64 3 64s3-1.2 3-2.74V50.74C6 49.2 4.68 48 3 48zm30 45c1.68 0 3-1.18 3-2.68V75.68c0-1.5-1.32-2.68-3-2.68s-3 1.18-3 2.68v14.64c0 1.5 1.32 2.68 3 2.68zm-3-72.23c0-1.55 1.32-2.77 3-2.77s3 1.22 3 2.77v38.46c0 1.55-1.32 2.77-3 2.77s-3-1.22-3-2.77V20.77zm56 21.49C86 43.8 84.68 45 83 45s-3-1.2-3-2.74V31.74C80 30.2 81.32 29 83 29s3 1.2 3 2.74v10.52zM73 57c1.68 0 3-1.23 3-2.8V25.8c0-1.57-1.32-2.8-3-2.8s-3 1.23-3 2.8v28.4c0 1.57 1.32 2.8 3 2.8zm-7 25.26C66 83.8 64.68 85 63 85s-3-1.2-3-2.74V12.74C60 11.2 61.32 10 63 10s3 1.2 3 2.74v69.52zM53 75c1.68 0 3-1.22 3-2.77V33.77c0-1.55-1.32-2.77-3-2.77s-3 1.22-3 2.77v38.46c0 1.55 1.32 2.77 3 2.77zm-7 8.14A2.9 2.9 0 0143 86a2.9 2.9 0 01-3-2.86V58.86A2.9 2.9 0 0143 56c1.68 0 3 1.26 3 2.86v24.28zM43 10a2.9 2.9 0 00-3 2.86v24.28A2.9 2.9 0 0043 40c1.68 0 3-1.26 3-2.86V12.86A2.9 2.9 0 0043 10z' clipRule='evenodd' />
                            <path fill='#222633' d='M109 37.5a2.5 2.5 0 10-5 0V48a11 11 0 0022 0V37.5a2.5 2.5 0 10-5 0V48a6 6 0 11-12 0V37.5z' />
                            <path fill='#222633' fillRule='evenodd' d='M130 37.5a2.5 2.5 0 014.95-.52A10.3 10.3 0 01141 35c6.07 0 11 5.37 11 12s-4.93 12-11 12a10.3 10.3 0 01-6-1.94v7.44a2.5 2.5 0 11-5 0v-27zM141 54c-3.31 0-6-3.13-6-7s2.69-7 6-7 6 3.13 6 7-2.69 7-6 7zm37-16.5a2.5 2.5 0 00-4.95-.52A10.3 10.3 0 00167 35c-6.07 0-11 5.37-11 12s4.93 12 11 12c2.24 0 4.32-.73 6.05-1.98a2.5 2.5 0 004.95-.52v-19zM167 54c3.31 0 6-3.13 6-7s-2.69-7-6-7-6 3.13-6 7 2.69 7 6 7z' clipRule='evenodd' />
                            <path fill='#222633' d='M187 37.5a2.5 2.5 0 10-5 0V49a10 10 0 0010 10h2c1.82 0 3.53-.49 5-1.34V58c0 1.13-.38 2.02-1.13 2.67-.79.68-2.26 1.33-4.87 1.33-3.23 0-4.81-1.04-5.37-1.41l-.1-.06a2.5 2.5 0 00-3.1 3.92c.78.53 1.55 1 2.41 1.35 1.45.61 3.53 1.2 6.16 1.2 3.4 0 6.17-.85 8.13-2.55A8.35 8.35 0 00204 58V37.5a2.5 2.5 0 10-5 0v10.52a6 6 0 01-12-.02V37.5z' />
                        </svg>
                    </Button>
                </Col>
                <Col style={{ padding: "0 4px" }}>
                    <Button href={`${conf.server}static/click/click.pdf`} target="_blank" rel="noopener noreferrer" >
                        <div style={{ textTransform: 'uppercase' }}><Trans alias={'Instructions'} /></div>
                    </Button>
                </Col>
            </Row>
        </>
    )
};
export default PaymnentSystemList;