import React from "react";
import { Row, Col, Drawer } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMenu } from "../../../redux/slices/SiteSlice";
import { CloseOutlined } from "@ant-design/icons";
import { getLogo } from "../../../config";
import { userEntitlement } from "../../../redux/slices/AuthSlice";
const _ = require("underscore");

function HeaderFullNav({ visible, onClose }) {
  const menuList = useSelector(selectMenu);
  const entitlement = useSelector(userEntitlement);
  return (
    <Drawer
      closable={false}
      open={visible}
      placement="top"
      height="100%"
      bodyStyle={{ backgroundColor: "#f6f5e7" }}
      id="header-full-nav"
      getContainer={"#forHeaderFullNav"}
    >
      <Row justify="center" align="middle" className="header-full-nav-logo">
        <Col>
          <Link to="/" onClick={onClose}>
            <img src={getLogo()} alt="" />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Link to="#" onClick={onClose}>
            <CloseOutlined className="header-full-nav-close" />
          </Link>
        </Col>
        <Col>
          <Row>
            {menuList &&
              menuList.map((obj) => (
                <React.Fragment key={obj.key + "FullMenu"}>
                  {(_.isEmpty(obj.privileges) ||
                    !_.isEmpty(
                      _.intersection(entitlement, obj.privileges)
                    )) && (
                    <Col xxl={8} xl={8} lg={16} md={24} sm={24} xs={24} >
                      <Link
                        to={obj.url}
                        onClick={onClose}
                        className="header-full-nav-link1"
                      >
                        {obj.title}
                      </Link>
                      {obj.children &&
                        obj.children.map((child) => (
                          <React.Fragment key={child.key + "FullSubMenu"}>
                            {(_.isEmpty(child.privileges) ||
                              !_.isEmpty(
                                _.intersection(entitlement, child.privileges)
                              )) && (
                              <Row>
                                <Col>
                                  <Link
                                    to={child.url}
                                    onClick={onClose}
                                    className="header-full-nav-link2"
                                  >
                                    {child.title}
                                  </Link>
                                  {child.children &&
                                    child.children.map((subchild) => (
                                      <React.Fragment
                                        key={subchild.key + "FullSubChildMenu"}
                                      >
                                        {(_.isEmpty(subchild.privileges) ||
                                          !_.isEmpty(
                                            _.intersection(
                                              entitlement,
                                              subchild.privileges
                                            )
                                          )) && (
                                          <Row>
                                            <Col>
                                              <Link
                                                onClick={onClose}
                                                className="header-full-nav-link3"
                                                to={subchild.url}
                                              >
                                                {subchild.title}
                                              </Link>
                                            </Col>
                                          </Row>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Col>
                              </Row>
                            )}
                          </React.Fragment>
                        ))}
                    </Col>
                  )}
                </React.Fragment>
              ))}
          </Row>
        </Col>
      </Row>
    </Drawer>
  );
}

export default HeaderFullNav;
