import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Table, Space, Badge } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import Trans, { getTitle } from "../../../Trans";
import ModalComponent from "../../../templates/ModalComponent";

const StatSettingsForm = () => {

    const hookApi = ApiHooks();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState();
    const loading = useSelector(loadingState);
    const titleUpdate = getTitle('Update refers');

    useEffect(() => { getStatisticSettings() }, []);

    const modalOnOff = (record) => {
        setId(record.id)
        setModal(true);
    };

    const getStatisticSettings = async () => {
        const result = await hookApi("statistic/getStatisticSettings", {});
        if (result.data) {
            setData(result.data.data);
        }
    };

    const updateStatictics = async () => {
        const result = await hookApi("statistic/setStatistic", { id: id });
        if (result) {
            setModal(false);
            getStatisticSettings();
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: "id",
            key: 'id',
            align: "center",
            fixed: true,
        },
        {
            title: getTitle('name'),
            dataIndex: "name",
            key: 'name',
            fixed: true,
        },
        {
            title: getTitle('Update date'),
            dataIndex: 'call_date',
            align: 'center',
            key: 'call_date'
        },
        {
            title: getTitle('State'),
            dataIndex: 'state_name',
            align: 'center',
            render: (text, record) => (
                <Badge
                    count={record.state_name}
                    style={{ backgroundColor: `${record.state === 1 ? "#52c41a" : "#f5222d"}` }}
                />
            ),
            key: 'state_name'
        },
        {
            dataIndex: 'edit',
            align: "center",
            key: 'edit',
            fixed: 'right',
            render: (_, record) => (
                <Space>
                    <Button
                        title={titleUpdate}
                        disabled={loading}
                        type="primary"
                        shape="circle"
                        icon={<UndoOutlined />}
                        onClick={() => modalOnOff(record)}
                    />
                </Space>
            ),
        },
    ];


    return (
        <>
            <Form form={form} component={false}>
                <Table
                    loading={loading}
                    size="small"
                    bordered
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id}
                />
            </Form>
            <ModalComponent
                title={<Trans alias={"Update refers"} />}
                open={modal}
                data={getTitle('Are you sure you want to update this?')}
                onOk={() => updateStatictics()}
                onCancel={() => setModal(false)}
                loading={loading}
                cancelTitle={<Trans alias={"No"} />}
                okTitle={<Trans alias={"Yes"} />}
            />
        </>
    );
};

export default StatSettingsForm;
