import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, Divider, Form, Pagination, Space, Switch } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ControlOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import ApiHooks from '../../../../hooks/ApiHooks';
import Trans, { getTitle } from '../../../Trans';
import MonitoringTable from '../../../templates/MonitoringTable';
import { FilterForm } from '../components/FilterForm';
import { loadingState } from '../../../../redux/slices/LoadingSlice';
import { pattern } from '../../../templates/Patterns';
import { ViewDataClient } from './components/ViewDataClient';
import DrawerComponent from '../../../templates/DrawerComponent';
import ModalComponent from '../../../templates/ModalComponent';
import { ClientChangeRoleModal } from './components/ClientChangeRoleModal';

const ClientList = () => {
  const pageSize = 10;
  const hookApi = ApiHooks();
  const loading = useSelector(loadingState);

  const [clients, setClients] = useState([]);

  const [filter] = Form.useForm();

  const [dataDetail, setDataDetail] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); //modal
  const [modalEdit, setModalEdit] = useState(false); //modal
  const [confirmActive, setConfirmActive] = useState(false); //modal
  const [confirmCancel, setConfirmCancel] = useState(false); //modal
  const [confirmEnter, setConfirmEnter] = useState(false); //modal
  const [confirmPrint, setConfirmPrint] = useState(false); //modal
  const [roleModal, setRoleModal] = useState({
    open: false,
    client_code: null,
    client_role: null,
    client_name: null,
    data: null,
  }); //modal

  const [enterAndPrintState, setEnterAndPrintState] = useState();
  const [client_code, setClientCode] = useState();

  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageParam, setPageParam] = useState({
    username: '',
    inn: '',
    client_code: '',
    client_name: null,
    client_role: null,
    state: '',
    page: 1,
    page_size: pageSize,
  });

  const getBadgeColor = (state) => {
    if (state === 1) {
      return '#52c41a';
    } else if (state === 0) {
      return '#fa8c16';
    } else if (state === 3) {
      return '#f5222d';
    }
  };

  const getClientsList = async () => {
    const clients = await hookApi('user/getClientsList', { json: pageParam });

    setClients(clients?.data?.data);
    setDataCount(clients?.data?.count);
  };

  const handleChangePage = (value) => {
    setCurrentPage(value);
    setPageParam({ ...pageParam, page: value });
  };

  const handleFilterForm = (values) => {
    const { client_code, inn, state, client_role, client_name } = values;
    let data = {};
    data.page = 1;
    data.client_name = client_name ? client_name : null;
    data.client_role = client_role ? client_role : null;
    data.inn = inn ? inn : null;
    data.state = state ? state : null;
    setCurrentPage(1);
    setPageParam({
      ...pageParam,
      client_code,
      ...data,
    });
  };

  const getConfirm = (code, state) => {
    setClientCode(code);
    state === 2 && setConfirmActive(true);
    state === 1 && setConfirmCancel(true);
  };

  const confirmEditModal = (code) => {
    setClientCode(code);
    setModalEdit(true);
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  const handleViewData = async (client_id) => {
    const result = await hookApi('user/getClientDetailed', { client_id });
    const dataTable = <ViewDataClient data={result?.data.client} />;
    setDataDetail(dataTable);
    setModalOpen(true);
    // setIsHidden(true);
  };

  const getConfirmEnterPrint = (code, type, state) => {
    setClientCode(code);
    setEnterAndPrintState(state);
    type === 0 && setConfirmEnter(true);
    type === 1 && setConfirmPrint(true);
  };

  const getApiParam = (type, state) => {
    if (type === 0) {
      return { url: 'changeClientEnterable', json: { enterable: state } };
    } else {
      return { url: 'changeClientPrintable', json: { printable: state } };
    }
  };

  const onConfirmEnterPrint = async (code, type, state) => {
    const { url, json } = getApiParam(type, state);
    const client = await hookApi(`/user/${url}`, {
      json: { client_code: code, ...json },
    });
    if (client) {
      await getClientsList();
      type === 0 && setConfirmEnter(false);
      type === 1 && setConfirmPrint(false);
    }
  };

  const onConfirmState = async (id, state) => {
    const client = await hookApi('user/changeClientState', {
      json: { client_code: id, state },
    });
    if (client) {
      await getClientsList();
      state === 1 && setConfirmActive(false);
      state === 2 && setConfirmCancel(false);
    }
  };

  const changeRoleSubmit = async () => {
    const client = await hookApi(
      '/user/changeJuridicalClientRoles',
      {
        json: {
          client_code: roleModal.client_code,
          client_role: roleModal.client_role,
        },
      },
      true,
    );
    if (client) {
      await getClientsList();
      setRoleModal({ client_code: null, client_role: null, open: false });
    }
  };

  const changeClientRole = (client_code, client_role, client_name) => {
    setRoleModal({ open: true, client_code, client_role, client_name });
  };

  useEffect(() => {
    getClientsList();
  }, [pageParam]);

  const columns = [
    {
      title: getTitle('Client code'),
      dataIndex: 'client_code',
      key: 'client_code',
      align: 'center',
      width: '5%',
    },
    {
      title: getTitle('User'),
      dataIndex: 'client_name',
      key: 'client_name',
      width: '20%',
    },
    {
      title: getTitle('Date and time of registration'),
      dataIndex: 'opened',
      key: 'opened',
      width: '10%',
      align: 'center',
    },
    {
      title: getTitle('User type'),
      dataIndex: 'role_name',
      key: 'role_name',
      width: '10%',
      align: 'center',
    },
    {
      title: getTitle('Inn'),
      dataIndex: 'inn',
      key: 'inn',
      align: 'center',
      width: '5%',
    },
    {
      title: getTitle('Access for record'),
      dataIndex: 'enterable',
      key: 'enterable',
      align: 'center',
      width: '10%',
      render: (record, data) => (
        <Switch
          checkedChildren="Да"
          unCheckedChildren="Нет"
          disabled={loading}
          checked={!!record}
          onClick={() => getConfirmEnterPrint(data.client_code, 0, record)}
        />
      ),
    },
    {
      title: getTitle('Access for change record'),
      dataIndex: 'printable',
      key: 'printable',
      align: 'center',
      width: '10%',
      render: (record, data) => (
        <Switch
          checkedChildren="Да"
          unCheckedChildren="Нет"
          disabled={loading}
          checked={!!record}
          onClick={() => getConfirmEnterPrint(data.client_code, 1, record)}
        />
      ),
    },
    {
      title: getTitle('State'),
      width: '10%',
      dataIndex: 'state_name',
      key: 'state_name',
      align: 'center',
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{
            backgroundColor: getBadgeColor(record.client_state),
          }}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      fixed: 'right',
      width: '1%',
      render: (text, record) => (
        <Space>
          <Button
            disabled={loading}
            title="Просмотр"
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => handleViewData(record.client_code)}
          />

          {record.client_role === 'juridical' && (
            <Button
              icon={<ControlOutlined />}
              title="Редактировать роль"
              disabled={loading}
              shape="circle"
              type="info"
              onClick={() =>
                changeClientRole(
                  record.client_code,
                  record.client_role,
                  record.client_name,
                )
              }
            />
          )}
          {record.client_state === 1 && (
            <Button
              title="Редактировать"
              disabled={loading}
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => confirmEditModal(record.client_code)}
            />
          )}

          {record.status_code !== 0 && (
            <Button
              title="Активировать"
              disabled={loading || record.client_state === 1}
              type="success"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => {
                getConfirm(record.client_code, record.client_state);
              }}
            />
          )}

          <Button
            title="Заблокировать"
            disabled={loading || record.client_state === 2}
            type="danger"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => {
              getConfirm(record.client_code, record.client_state);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div style={{ paddingBottom: 20 }}>
      <FilterForm
        form={filter}
        inputs={[
          { name: 'inn', text: 'Inn', pattern: pattern.inn },
          { name: 'client_name', text: 'User' },
        ]}
        onFinish={handleFilterForm}
        disabled={loading}
        isClientList={true}
      />
      <Divider />
      <MonitoringTable
        size="small"
        columns={columns}
        dataSource={clients}
        loading={false}
        bordered={true}
        pagination={false}
        rowKey={(record) => record.key}
      />
      <ModalComponent
        title={<Trans alias={'Изменения статуса'} />}
        open={confirmActive}
        data={getTitle('Do you really want to change the entry')}
        onOk={() => onConfirmState(client_code, 1)}
        onCancel={() => setConfirmActive(false)}
        loading={loading}
        cancelTitle={<Trans alias={'No'} />}
        okTitle={<Trans alias={'Yes'} />}
      />
      <ModalComponent
        title={<Trans alias={'Изменения статуса'} />}
        open={confirmCancel}
        data={getTitle('Do you really want to change the entry')}
        onOk={() => onConfirmState(client_code, 2)}
        onCancel={() => setConfirmCancel(false)}
        loading={loading}
        cancelTitle={<Trans alias={'No'} />}
        okTitle={<Trans alias={'Yes'} />}
      />
      <ModalComponent
        title={<Trans alias={'Изменения статуса'} />}
        open={confirmEnter}
        data={getTitle('Do you really want to change the entry')}
        onOk={() =>
          onConfirmEnterPrint(
            client_code,
            0,
            (enterAndPrintState === 0 && 1) || 0,
          )
        }
        onCancel={() => setConfirmEnter(false)}
        loading={loading}
        cancelTitle={<Trans alias={'No'} />}
        okTitle={<Trans alias={'Yes'} />}
      />
      <ModalComponent
        title={<Trans alias={'Изменения статуса'} />}
        open={confirmPrint}
        data={getTitle('Do you really want to change the entry')}
        onOk={() =>
          onConfirmEnterPrint(
            client_code,
            1,
            (enterAndPrintState === 0 && 1) || 0,
          )
        }
        onCancel={() => setConfirmPrint(false)}
        loading={loading}
        cancelTitle={<Trans alias={'No'} />}
        okTitle={<Trans alias={'Yes'} />}
      />
      <ModalComponent
        title={<Trans alias={'Editing a post'} />}
        open={modalEdit}
        data={getTitle('Do you really want to change the entry')}
        onOk={() => {
          window.open(`/main/manage/client/update/${client_code}`, '_blank');
          setModalEdit(false);
        }}
        onCancel={() => setModalEdit(false)}
        loading={loading}
        cancelTitle={<Trans alias={'No'} />}
        okTitle={<Trans alias={'Yes'} />}
      />
      <ModalComponent
        title={<Trans alias={'Изменение роли клиента'} />}
        open={roleModal.open}
        data={
          <ClientChangeRoleModal
            client_code={roleModal.client_code}
            client_role={roleModal.client_role}
            setRoleModal={setRoleModal}
            client_name={roleModal.client_name}
          />
        }
        onOk={() => {
          changeRoleSubmit();
        }}
        width="750px"
        onCancel={() =>
          setRoleModal({ open: false, client_code: null, client_role: null })
        }
        loading={loading}
        cancelTitle={<Trans alias={'Cancel'} />}
        okTitle={<Trans alias={'Save'} />}
      />
      <DrawerComponent
        title={<Trans alias={'Show data'} />}
        open={modalOpen}
        data={dataDetail}
        onCancel={onCancel}
        width={'50%'}
      />
      {dataCount > pageSize && (
        <Pagination
          style={{ marginTop: 10 }}
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};

export default ClientList;
