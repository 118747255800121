import React from "react";
import { Form, Row, Col, DatePicker, Button } from "antd";
import Trans, { getTitle } from "../../../../Trans";
import { date_format } from "../../../../templates/Patterns";
import GetSelect from "../../../../templates/FormSelect";
import { REFERS } from "../../../../constants/refers";
import ExportExcelTable from "../../../../templates/ExportExcelTable";

const FilterForm = (props) => {

  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <GetSelect name={'internal_service'} refer={REFERS.conREF_INTERNAL_SERVICE} text={'Web service'} required={true} func={(value, options) => { props.selectLabel(options.label); props.resetTable([]); props.setExcel(false); props.setColumns([]) }} />
        </Col>
        <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
          <Form.Item label={getTitle("Begin date")} name="bdate">
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>
        <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
          <Form.Item label={getTitle("End date")} name="edate">
            <DatePicker format={date_format} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => {
              props.form.resetFields();
              props.form.submit();
              props.resetTable([]);
              props.setExcel(false);
              props.setColumns([]);
            }}
          >
            <Trans alias={"Reset"} />
          </Button>
          {props.excel && <ExportExcelTable disabled={props.disabled} columns={props.columns} dataSource={props.dataSource} fileName={props.fileName} />}
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
